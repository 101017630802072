import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiUpdateNonIcpJourneyRequest {
	encryptedQuoteJourneyId?: string;
	memberCount?: number;
	visaPeriodId?: number;
	quotaEffectiveDate?: string;
	baseTariffId?: number;
	agencyName?: string;
	cityId?: number;
	establishmentCardNumber?: string;
}
export const apiUpdateNonIcpJourney = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		{ encryptedCompanyData: string },
		IApiUpdateNonIcpJourneyRequest
	>({
		query: (body) => ({
			method: 'post',
			url: Endpoints.urls.Quota.updateNonIcpJourney,
			body,
		}),
	});
};
