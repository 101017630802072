import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
	IResponseError,
	IResponseErrorsArray,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import { Endpoints } from '@visit-visa/api-visit-visa';
import { DocumentTypes } from '../../common/enum';

interface IUploadFileRequest {
	file: File;
	config?: AxiosRequestConfig;
	id: DocumentTypes;
	refId?: string;
}

interface IUploadResponse {
	data: {
		fileUrl: string;
		thumbnailUrl: null;
		encryptedDocumentId: string;
	};
}
export type TLoadingState = Partial<{ [key in DocumentTypes]: boolean }>;

const useUploadFiles = (
	setIsUploading?: (loading: boolean, id: DocumentTypes) => void,
) => {
	const { getErrorMessages } = useErrorsResponseTransform();

	const requestHandler = async (
		request: IUploadFileRequest,
	): Promise<IUploadResponse> => {
		const { file, config, id, refId } = request;

		const requestObject = {
			Type: id,
			File: file,
			ref: refId,
		};
		const url = Endpoints.urls.document.upload;

		return (await axios.post(url, requestObject, config)).data;
	};

	const fileUpload = async (request: IUploadFileRequest) => {
		try {
			setIsUploading?.(true, request.id);
			const res = await requestHandler(request);
			setIsUploading?.(false, request.id);

			return res.data;
		} catch (error) {
			setIsUploading?.(false, request.id);
			const { response, code } = error as AxiosError<{
				errors?: IResponseError[];
			}>;

			const httpStatus = response?.status ?? 0;

			let resultErrors: IResponseErrorsArray = {
				status: httpStatus.toString(),
			};

			if (
				code &&
				[AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED].includes(code)
			) {
				resultErrors = { status: 'FETCH_ERROR' };
			}

			const mappedError = getErrorMessages(resultErrors);
			const responseResult = { mappedError, httpStatus };

			throw responseResult;
		}
	};

	return { fileUpload };
};

export { useUploadFiles };
