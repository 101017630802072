import { ReactNode } from 'react';
import * as yup from 'yup';
import {
	IFormSchema,
	IResponseError,
	IResponseErrors,
	getIsoDateTime,
	getTzTodayDate,
	useErrorsResponseTransform,
	useReactForm,
} from '@ninja/utilities';
import {
	initiateJourney,
	setCompanyData,
	setQuotaDetails,
} from '../../../redux-store/quota-purchase.slice';
import {
	useGetCompanyDetailsByEncryptedIdMutation,
	useGetCompanyDetailsMutation,
	useLazyGetAllQuotasQuery,
	usePostCreateNonIcpJourneyMutation,
	usePostUpdateCompanyMutation,
	usePostUpdateJourneyMutation,
	usePostUpdateNonIcpJourneyMutation,
	useUpdateGuidMutation,
} from '../../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import {
	selectCompanyDetails,
	selectCurrentStep,
	selectIsAgencyDetailExists,
	selectQuotaDetails,
	selectQuoteJourneyId,
} from '../../../selectors/quotaPurchase.selectors';
import useUserInfo from '../../useUserInfo';
import useFormFieldsAndValidation from './useFormFieldsAndValidation';

export interface IQuotaDetailsFormValue {
	memberCount?: number;
	visaType?: number;
	quotaEffectiveDate?: string;
	agencyName?: string;
	department?: number;
	establishmentCardNumber?: string;
}

const useQuotaDetails = ({
	onSuccess,
	onGeneralErrors,
	establishmentTooltip,
}: {
	onSuccess: () => void;
	onGeneralErrors: (errors: IResponseError[]) => void;
	establishmentTooltip: ReactNode;
}) => {
	const appDispatch = useAppDispatch();
	const encryptedQuoteJourneyId = useAppSelector(selectQuoteJourneyId);
	const companyDetailsData = useAppSelector(selectCompanyDetails);
	const establishmentNo = companyDetailsData?.establishmentNumber?.substring(
		companyDetailsData?.establishmentNumber.indexOf('/') + 1,
	);
	const isAgencyNonEditable = useAppSelector(selectIsAgencyDetailExists);
	const currentStep = useAppSelector(selectCurrentStep);
	const { userLoggedIn, agency } = useUserInfo();
	const { quotaDetailsValidation, agencyDetailsValidation, generateFields } =
		useFormFieldsAndValidation({
			isAgencyNonEditable,
			establishmentTooltip,
		});
	const [getCompanyDetailsAPI] = useGetCompanyDetailsByEncryptedIdMutation();
	const [onGetCompanyDetails] = useGetCompanyDetailsMutation();
	const [updateGuid] = useUpdateGuidMutation();
	const [getAllQuotas] = useLazyGetAllQuotasQuery();
	const [onUpdateCompany] = usePostUpdateCompanyMutation();

	const quotaFormDetails = useAppSelector(selectQuotaDetails);
	const [onPostUpdateJourney] = usePostUpdateJourneyMutation();
	const [onPostUpdateNonIcpJourney] = usePostUpdateNonIcpJourneyMutation();
	const [onCreateNonIcpJourney] = usePostCreateNonIcpJourneyMutation();
	const { getErrorMessages } = useErrorsResponseTransform();

	const schema: IFormSchema<IQuotaDetailsFormValue> = {
		fields: generateFields,
		validation: yup
			.object(quotaDetailsValidation)
			.shape(!isAgencyNonEditable ? agencyDetailsValidation : {}) as any,
		initialValues:
			{
				...quotaFormDetails,
				agencyName: companyDetailsData?.fullNameEn,
				establishmentCardNumber: establishmentNo,
				department: companyDetailsData?.cityId,
			} || {},
	};

	const handleJourney = async (values: IQuotaDetailsFormValue) => {
		try {
			let encryptedJourneyId = encryptedQuoteJourneyId ?? '';
			const quotaEffectiveDate = getIsoDateTime(getTzTodayDate(), true) ?? '';
			if (
				encryptedJourneyId &&
				establishmentNo === values.establishmentCardNumber
			) {
				if (isAgencyNonEditable)
					await onPostUpdateJourney({
						encryptedQuoteJourneyId: encryptedJourneyId,
						visaPeriodId: values.visaType ?? 1,
						memberCount: values.memberCount ?? 0,
						quotaEffectiveDate,
					}).unwrap();
				else {
					const data = await onPostUpdateNonIcpJourney({
						encryptedQuoteJourneyId: encryptedJourneyId,
						visaPeriodId: values.visaType ?? 1,
						memberCount: values.memberCount ?? 0,
						quotaEffectiveDate,
						agencyName: values.agencyName,
						cityId: values.department,
						establishmentCardNumber: values.establishmentCardNumber,
					}).unwrap();
					if (data?.encryptedCompanyData)
						await onUpdateCompany({
							companyJsonEncrypted: data.encryptedCompanyData,
						}).unwrap();
				}

				const company = await onGetCompanyDetails(encryptedJourneyId).unwrap();
				appDispatch(setCompanyData(company));
			} else {
				const { quotaJourneyId } = await onCreateNonIcpJourney({
					visaPeriodId: values.visaType ?? 1,
					memberCount: values.memberCount ?? 0,
					quotaEffectiveDate,
					agencyName: values.agencyName,
					cityId: values.department,
					establishmentCardNumber: values.establishmentCardNumber,
				}).unwrap();

				encryptedJourneyId = quotaJourneyId;

				const company = await onGetCompanyDetails(encryptedJourneyId).unwrap();
				const agencyEncryptedInfo = company.agencyInfoEncrypted;

				const { id } = await getCompanyDetailsAPI({
					companyJsonEncrypted: agencyEncryptedInfo,
				}).unwrap();

				await updateGuid({
					journeyId: encryptedJourneyId,
					companyGuid: id,
				}).unwrap();

				appDispatch(
					initiateJourney({
						quoteJourneyId: encryptedJourneyId,
						company,
						identityCompanyId: id,
						isICP: false,
						currentStep: currentStep ?? 1,
					}),
				);
			}

			const plans = await getAllQuotas(encryptedJourneyId).unwrap();

			if (plans.length === 1) {
				await onPostUpdateJourney({
					baseTariffId: plans[0]?.baseTarrifId,
					encryptedQuoteJourneyId: encryptedJourneyId,
					visaPeriodId: values.visaType ?? 1,
					memberCount: values.memberCount ?? 0,
					quotaEffectiveDate,
				}).unwrap();
			}

			appDispatch(
				setQuotaDetails({
					visaType: values.visaType ?? 1,
					memberCount: values.memberCount ?? 0,
					quotaEffectiveDate,
				}),
			);

			onSuccess();
		} catch (err) {
			const errors = err as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onGeneralErrors(mappedGeneralErrors);
		}
	};

	const form = useReactForm<IQuotaDetailsFormValue>({
		initialValues: schema.initialValues,
		validation: schema.validation,
		onFormSubmit: (values) => handleJourney(values),
	});

	return {
		form,
		schema,
		encryptedQuoteJourneyId,
		isAgencyNonEditable,
		userLoggedIn,
		agency,
		shouldRedirectToNonICP: userLoggedIn && !companyDetailsData,
	};
};

export default useQuotaDetails;
