import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiUpdateQuotaCancellationStatusRequest {
	quotaCancellationId: number;
	requestStatusTypeId: number;
	rejectionReason?: string;
}

export const apiUpdateQuotaCancellationStatus = (
	builder: ApiEndpointBuilder,
) => {
	return builder.mutation<void, IApiUpdateQuotaCancellationStatusRequest>({
		query: ({ quotaCancellationId, ...remain }) => ({
			url: Endpoints.urls.admin['quota-cancellation'].updateStatus(
				quotaCancellationId,
			),
			method: 'POST',
			body: remain,
		}),
	});
};
