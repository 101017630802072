import { UserPermissionsToCompany } from '../common/enum';
import { LibConfig } from '../config';
import { useAppSelector } from '../redux-store/store';
import {
	selectCurrentUserDetails,
	selectIsLoggedIn,
	selectJWTTokenData,
	selectPermissionsForCompany,
} from '../selectors/persisted-settings.selectors';
import {
	selectCompanyDetails,
	selectIdentityCompanyId,
} from '../selectors/quotaPurchase.selectors';

function useUserInfo() {
	const userLoggedIn = useAppSelector(selectIsLoggedIn);
	const userInfo = useAppSelector(selectCurrentUserDetails);
	const company = useAppSelector(selectCompanyDetails);
	const identityCompanyId = useAppSelector(selectIdentityCompanyId);
	const { isCompanyExist } = useAppSelector(selectJWTTokenData);
	const getPermissionsForAgency = useAppSelector(selectPermissionsForCompany);
	const userCompany = useAppSelector(selectCompanyDetails);
	const userPermissionsForCompany = getPermissionsForAgency(
		identityCompanyId ?? '',
	);

	const isAdmin = userPermissionsForCompany?.[+LibConfig.productId]?.includes(
		UserPermissionsToCompany.BusinessAdmin,
	);

	const userHasViewWalletPermission =
		(userPermissionsForCompany &&
			(isAdmin ||
				userPermissionsForCompany[+LibConfig.productId]?.includes(
					UserPermissionsToCompany.ViewWallet,
				))) ??
		false;

	const userHasWalletTopUpPermission =
		(userPermissionsForCompany &&
			(isAdmin ||
				userPermissionsForCompany[+LibConfig.productId]?.includes(
					UserPermissionsToCompany.WalletTopUp,
				))) ??
		false;

	const userHasQuotaPurchasePermission =
		(userPermissionsForCompany &&
			(isAdmin ||
				userPermissionsForCompany[+LibConfig.productId]?.includes(
					UserPermissionsToCompany.QuotaPurchase,
				))) ??
		false;

	return {
		userLoggedIn,
		user: userInfo?.user,
		agency: company?.agencyInfoEncrypted,
		isCompanyExist: isCompanyExist(identityCompanyId ?? ''),
		userHasQuotaPurchasePermission,
		userHasViewWalletPermission,
		userHasWalletTopUpPermission,
		userCurrentCompany: userInfo?.companies[0],
		userCompany,
	};
}

export default useUserInfo;
