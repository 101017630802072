import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingResponse } from '../../common/types';

export type ICustomerAgency = {
	agencyGUID: string;
	cityId: number;
	establishmentNumber: string;
	fullNameAr: string;
	fullNameEn: string;
	licenseExpiryDate: string;
	licenseIssueDate: string;
	licenseNo: string;
};
interface IApiGetAgenciesRequest extends IPagingAndSorting {
	searchText?: string;
}

type IApiGetAgenciesResponse = {
	agencies: ICustomerAgency[];
	paging: {
		totalRecords: number;
	};
};

export const apiGetAgencies = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<ICustomerAgency>,
		IApiGetAgenciesRequest
	>({
		query: (request) => {
			return {
				url: Endpoints.urls.agency.agencies,
				body: request,
				method: 'POST',
			};
		},
		transformResponse: (response: IApiGetAgenciesResponse) => {
			return {
				data: response.agencies,
				totalItemsCount: response.paging.totalRecords,
			};
		},
	});
};
