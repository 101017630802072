import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config/endpoints';

export type ApiGetIssuedPolicyByPaymentLinkDetailsResponse = {
	policyNumber: string;
	policyValidity: string;
	policyIssueDate: string;
	insuranceCompany: string;
	memberName: string;
	mobileNumber: string;
	email: string;
	policyPremium: number;
	policyCertificateUrl: string;
	policyReceiptUrl: string;
	planId: number;
};

export const apiGetIssuedPolicyByPaymentLinkDetails = (
	builder: ApiEndpointBuilder,
) => {
	return builder.query<ApiGetIssuedPolicyByPaymentLinkDetailsResponse, string>({
		query: (encryptedRequestedPolicyId: string) =>
			Endpoints.urls.paymentLink.getIssuedPolicyByPaymentLinkDetails(
				encryptedRequestedPolicyId,
			),
		transformResponse: (
			response: IBaseResponse<ApiGetIssuedPolicyByPaymentLinkDetailsResponse>,
		) => {
			return response.data;
		},
	});
};
