import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IInsuranceCompanyResponse {
	id: number;
	icCode: string;
	icNameEn: string;
	icNameAr: string;
	icAliasEn: string;
	icAliasAr: string;
	icLogoUrl: string;
	commercialRegistrationNumber: number;
	insuranceAuthorityNumber: number;
	vatLegalName: string;
	vatAddress: string;
	taxRegistrationNumber: number;
	emailAddress: string;
	customerCareNumber: number;
	websiteUrl: string;
	headquarterAddress: string;
	city: string;
	aboutCompanyEn: string;
	aboutCompanyAr: string;
	icNameCode: string;
	companySignature: string;
	isActive: boolean;
	createdOn: string;
	updatedOn: string;
	termAndConditionUrl: null;
	termAndConditionQrUrl: null;
	settings: {
		TermAndConditionUrl: string;
		TermAndConditionQrUrl: string;
		PolicyCertificateEmailAddress: string;
		PolicyCertificateMobileNo: string;
		IsSendingPolicyPurchaseNotification: string;
	};
	policyPurchaseNotification: string;
	dailyPurchaseReportNotification: string;
}

export interface IApiGetIssuedPolicyDetailsResponse {
	policy: {
		id: number;
		requestReferenceNo: string;
		quotaNumber: string;
		previousPolicyNumber: null;
		visaPeriod: string;
		visaApprovedDate: string;
		policyAmount: number;
		paymentReferenceNo: null;
		policyReferenceNo: string;
		policyNumber: string;
		policyIssueDate: string;
		retryCount: number;
		insuranceCompany: IInsuranceCompanyResponse;
		createdOn: string;
		policyType: number;
		partner: {
			id: number;
			fullNameEn: string;
			fullNameAr: string;
		};
		channelName: string;
		policyCertificateUrl: string;
		paymentReceiptUrl: string;
		customerTaxInvoiceUrl: string;
		customerTaxInvoiceNumber: string;
		policyStatus: string;
		receiptNumber: string;
		customerId: number;
		documentStatus: number;
	};
	memberDetails: {
		fullNameEn: string;
		emailAddress: string;
		mobileNumber: number;
		nationalityId: number;
		memberDOB: string;
		genderId: number;
		passportNo: string;
		passportIssueDate: string;
		passportExpiryDate: string;
		passportIssueCountryId: number;
	};
}
interface IApiGetIssuedPolicyDetailsRequest {
	policyId: number;
}

export const apiGetIssuedPolicyDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetIssuedPolicyDetailsResponse,
		IApiGetIssuedPolicyDetailsRequest
	>({
		query: (request) => ({
			method: 'GET',
			url: Endpoints.urls.admin.policy.details(request.policyId),
		}),
		transformResponse: (
			response: IBaseResponse<IApiGetIssuedPolicyDetailsResponse>,
		) => response.data,
	});
};
