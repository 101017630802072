import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetServicesDashboardRequest {
	searchTimeline?: number;
	searchDateFrom?: string;
	searchDateTo?: string;
}

export interface IServiceSystemApiCheckResponse {
	apiNameId: number;
	countOfSuccess: number;
	countOfFailed: number;
	apiInitiatorId: number;
}

interface IApiGetServicesDashboardResponse {
	serviceSystemApiCheckDetails: IServiceSystemApiCheckResponse[];
}

export const apiGetServicesDashboard = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IServiceSystemApiCheckResponse[],
		IApiGetServicesDashboardRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.dashboard.servicesSystemCheck,
			body: request,
		}),
		transformResponse: (response: IApiGetServicesDashboardResponse) => {
			return response.serviceSystemApiCheckDetails;
		},
	});
};
