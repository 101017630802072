import { useEffect } from 'react';
import { LibConfig } from '../../../config';
import {
	setSelectedQuotaAR,
	setSelectedQuotaEN,
} from '../../../redux-store/quota-purchase.slice';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import { ISelectedQuota } from '../../../redux-store/types';
import {
	selectAllQuotas,
	selectSelectedQuotaAR,
	selectSelectedQuotaEN,
} from '../../../selectors/quotaPurchase.selectors';
import { selectAppInitData } from '../../../selectors/ui.selectors';
import useTransformData from './useTransformData';

export interface ITransformedSelectedProductDetails
	extends Partial<ISelectedQuota> {
	exclusionsLocale?: string[];
}

const useTransformPlans = () => {
	const appDispatch = useAppDispatch();
	const { getTransformedData } = useTransformData();
	const quotas = useAppSelector(selectAllQuotas) ?? [];
	const quotaDetailsEN = useAppSelector(selectSelectedQuotaEN);
	const quotaDetailsAR = useAppSelector(selectSelectedQuotaAR);
	const appInit = useAppSelector(selectAppInitData);
	const locale = LibConfig.getLocale();
	const quotaDetails = locale === 'ar' ? quotaDetailsAR : quotaDetailsEN;

	useEffect(() => {
		if (!quotas.length || quotas[0].baseTarrifId === quotaDetails?.baseTarrifId)
			return;

		const transformedQuotas = getTransformedData(quotas);

		const selectedQuota = transformedQuotas?.find(
			(quota) => quota.baseTarrifId === quotas[0].baseTarrifId,
		);

		if (locale === 'en') appDispatch(setSelectedQuotaEN(selectedQuota));
		else appDispatch(setSelectedQuotaAR(selectedQuota));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quotas, appInit, quotaDetailsEN, quotaDetailsAR]);

	const selectedProductDetails: ITransformedSelectedProductDetails = {
		...quotaDetails,
		exclusionsLocale:
			locale === 'ar' ? quotaDetails?.arExclusions : quotaDetails?.exclusions,
		totalVatAmount: quotas[0]?.totalVatAmount,
		totalVatExclusiveAmount: quotas[0]?.totalVatExclusiveAmount,
	};

	return { selectedProductDetails };
};

export default useTransformPlans;
