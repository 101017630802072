import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetWalletRequestDetailRequest {
	walletId: number;
}
export interface IApiGetWalletRequestDetailResponse {
	walletDetails: {
		walletId: number;
		transactionNumber: string;
		transactionDate: string;
		amount: number;
		requestReferenceNo: string;
		requestDate: string;
		walletStatus: number;
		bankName: string;
		approvedAmount: number;
		rejectionReasonId: number;
		comment: string;
		requesterEmail: string;
		requesterMobileNumber: string;
	};
	documents: [
		{
			encryptedDocumentId: string;
			referenceNumber: string;
			documentType: number;
			cdnUrl: string;
			createdOn: string;
			updatedOn: string;
		},
	];
	companyDetails: {
		agencyId: string;
		fullNameEn: string;
		establishmentNumber: string;
		licenseNo: number;
		cityId: number;
		emailAddress: string;
		mobileNumber: string;
		editConfiguration: {
			fullNameEn: boolean;
			fullNameAr: boolean;
			emailAddress: boolean;
			mobileNumber: boolean;
			category: boolean;
			cityId: boolean;
			licenseIssuingAuthority: boolean;
			licenseNo: boolean;
			licenseIssueDate: boolean;
			licenseExpiryDate: boolean;
			establishmentNumber: boolean;
			gdrfaCardNo: boolean;
			address: boolean;
			vatRegistrationNumber: boolean;
			BackToICPUrl: boolean;
		};
	};
	walletBalance: number;
}

export const apiGetWalletRequestDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetWalletRequestDetailResponse,
		IApiGetWalletRequestDetailRequest
	>({
		query: ({ walletId }) => ({
			url: Endpoints.urls.admin.wallet.details(walletId),
		}),
		transformResponse: (
			response: IBaseResponse<IApiGetWalletRequestDetailResponse>,
		) => {
			return response.data;
		},
	});
};
