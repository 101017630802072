import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface apiGetDashboardQuotaDetailsResponse {
	quotaNumber: string;
	planId: number;
	insuranceCompanyId: number;
	agencyName: string;
	visaPeriodId: number;
	visaTypeId: number;
	usedQuotaCount: number;
	totalQuotaCount: number;
	issueDate: string;
	effectiveDate: string;
	expiryDate: string;
	amount: number;
	quotaCertificateUrl: string;
	receiptUrl: string;
	memberPriceInclVAT: string;
}

export const apiGetDashboardQuotaDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		apiGetDashboardQuotaDetailsResponse,
		{ quotaNumber: string }
	>({
		query: (request) => ({
			url: Endpoints.urls.customerPortal.quotaDetails(request.quotaNumber),
			method: 'POST',
		}),
	});
};
