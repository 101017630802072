import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect } from 'react';
import {
	ArrowDownIcon,
	ChatIcon,
	HomeIcon,
	LogoutIcon,
	SettingsIcon,
	TelephoneIcon,
	UserIcon,
	AgCarIcon,
	NjPlaneIcon,
	OutlinedInfoCircleIcon,
	GlobalIcon,
	BankIcon,
} from '@ninja/icons/web';
import {
	TUseLiveChatWidgetProps,
	useLiveChatWidget,
	useLocale,
} from '@ninja/nextjs';
import {
	INinjaThemeProvider,
	LayoutHeaderMenu,
	useResponsiveView,
} from '@ninja/web';
import {
	STEPPER_STEPS,
	useStepperBasedOnData,
	useUserInfo,
} from '@visit-visa/lib-customer';
import { AppConfig } from '../config/app-config';
import { useWebNavigation } from '../contexts/navigation';
import useDeviceInfo from './useDeviceInfo';

const useLayoutSetting = () => {
	const { locale, setLocaleLang, isRtlLocale } = useLocale();
	const { userLoggedIn, user } = useUserInfo();
	const { t } = useTranslation();
	const { navigationPush, getPathForRoute } = useWebNavigation();
	const { getCurrentStep } = useStepperBasedOnData();
	const { max_md, max_lg, md } = useResponsiveView();

	const liveChatConfig: TUseLiveChatWidgetProps = {
		userInfo: {
			isUserLoggedIn: userLoggedIn,
			user: {
				email: user?.email ? user?.email : '',
				mobileNumber:
					user?.mobileCountryCode && user.mobileNumber
						? `${user?.mobileCountryCode}${user?.mobileNumber}`
						: '',
			},
		},
		appConfig: AppConfig.omniChannelConfig(locale),
		productName: 'Shory Visit Visa Insurance',
	};

	const { startChat, clearChatWidget, initializeChatWidget } =
		useLiveChatWidget(liveChatConfig);
	const { isSafari } = useDeviceInfo();

	useEffect(() => {
		initializeChatWidget();
		return () => clearChatWidget();
	}, []);

	const clickAnchor = (link: string) => {
		const element = document.createElement('a');
		element.target = !isSafari ? '_blank' : '';
		element.href = link;
		element.click();
	};

	const currentUser: INinjaThemeProvider['currentUser'] = {
		displayName: user?.email,
		isLoggedIn: userLoggedIn,
		noDrawerForUserMenu: 'responsiveHide',
		menuMinWidth: 300,
		userMenuItems:
			userLoggedIn || max_md
				? [
						{
							label: t('shared.user-setting-menu.dashboard'),
							onClick: () => {
								window.open(getPathForRoute('Dashboard'), '_blank');
							},
							icon: HomeIcon,
							link: {
								href: getPathForRoute('Dashboard') ?? '',
							},
						},
						{
							label: t('shared.user-setting-menu.account-settings'),
							onClick: () => {
								window.open(getPathForRoute('AccountSettings'), '_blank');
							},
							icon: UserIcon,
							link: {
								href: getPathForRoute('AccountSettings') ?? '',
							},
						},
				  ]
				: undefined,
		userMenuFooterItems: userLoggedIn
			? [
					{
						label: t('shared.user-setting-menu.logout'),
						onClick: () => {
							//TODO: in case of non icp, add redirect url to quota-purchase/non-icp
							navigationPush('SSO_Logout');
						},
					},
			  ]
			: undefined,
	};

	const prepareIcon = (title: string, isOpen?: boolean) => (
		<div className="flex items-center gap-2 hover:cursor-pointer hover:text-brand-600">
			<span className="text-body">{title}</span>
			<ArrowDownIcon className={`w-3 h-2 ${isOpen ? 'rotate-180' : ''}`} />
		</div>
	);

	const renderInsurance = () => (
		<div className="flex flex-col gap-4 text-black text-paragraph">
			<div>
				<div className="font-bold text-body">
					{t('shared.user-setting-menu.car-insurance')}
				</div>
				<div
					className="my-1 ms-2 hover:text-brand-600 hover:cursor-pointer"
					onClick={() => window.open('https://www.shory.com', '_blank')}
				>
					{t('shared.user-setting-menu.uae-vehicles')}
				</div>
				<div
					className="ms-2 hover:text-brand-600 hover:cursor-pointer"
					onClick={() => {
						if (!isRtlLocale) {
							window.open('https://aber.shory.com/en', '_blank');
						} else window.open('https://aber.shory.com', '_blank');
					}}
				>
					{t('shared.user-setting-menu.non-uae-vehicles')}
				</div>
			</div>
			<div>
				<div className="font-bold text-body">
					{t('shared.user-setting-menu.travel-insurance')}
				</div>
				<div
					className="my-1 ms-2 hover:text-brand-600 hover:cursor-pointer"
					onClick={() => navigationPush('QuotaPurchaseLanding')}
				>
					{t('shared.user-setting-menu.inbound-visit-visa')}
				</div>
			</div>
		</div>
	);

	const renderSupport = () => (
		<div className="flex flex-col gap-4 text-black text-paragraph">
			<div
				className="hover:text-brand-600 hover:cursor-pointer"
				onClick={() =>
					(window.location.href = 'mailto:visitvisasupport@shory.com')
				}
			>
				{t('shared.contact-email')}
			</div>
			<div
				className="flex flex-col hover:text-brand-600 hover:cursor-pointer"
				onClick={() => (window.location.href = 'tel:80074679')}
			>
				<span
					className={`text-paragraph ${isRtlLocale ? 'text-end' : ''}`}
					dir={isRtlLocale ? 'ltr' : ''}
				>
					{t('shared.contact-number')}
				</span>
				<span className="text-caption2">{t('shared.contact-time')}</span>
			</div>
		</div>
	);

	const renderCompany = () => (
		<div className="flex flex-col gap-4 text-black text-paragraph">
			<div
				className="hover:text-brand-600 hover:cursor-pointer"
				onClick={() => window.open('https://www.shory.com/about-us', '_blank')}
			>
				{t('shared.user-setting-menu.about-us')}
			</div>
			<div
				className="hover:text-brand-600 hover:cursor-pointer"
				onClick={() => navigationPush('TermsAndConditions')}
			>
				{t('shared.user-setting-menu.terms-conditions')}
			</div>
			<div
				className="hover:text-brand-600 hover:cursor-pointer"
				onClick={() => navigationPush('PrivacyPolicy')}
			>
				{t('shared.user-setting-menu.privacy')}
			</div>
		</div>
	);

	const settings: INinjaThemeProvider = {
		locale,
		onLanguageSwitchClick: (locale) => {
			setLocaleLang(locale);
		},
		logoRender: ({ isDisabled, logoColorMode }) => (
			<img
				className="cursor-pointer"
				onClick={(e) => {
					if (!isDisabled) {
						getCurrentStep() === STEPPER_STEPS.SUCCESSFUL
							? navigationPush('NonICP')
							: navigationPush('QuotaPurchaseLanding');
					} else {
						e.stopPropagation();
						e.preventDefault();
					}
				}}
				style={{ height: '30px', width: '97px' }}
				src={
					logoColorMode === 'white'
						? 'images/logo_white.svg'
						: 'images/logo.svg'
				}
				alt="Shory logo"
			/>
		),
		renderLink: ({ href, children, title, ...rest }) => (
			<Link href={href ?? '#'} {...rest}>
				{title ?? children}
			</Link>
		),
		currentUser,
		toastDefaults: {
			position: 'top-center',
			duration: 4000,
			isClosable: true,
		},
		headerOptions: {
			onChatIconClick: max_md ? undefined : startChat,
			headerMenuRender() {
				return max_md ? (
					<></>
				) : (
					<div className="flex items-center justify-center gap-6 text-body">
						{!md && (
							<>
								<LayoutHeaderMenu
									showBehavior="hover"
									triggerComponent={({ isOpen }) =>
										prepareIcon(t('shared.user-setting-menu.insurance'), isOpen)
									}
									menuItems={renderInsurance()}
								/>

								<LayoutHeaderMenu
									showBehavior="hover"
									triggerComponent={({ isOpen }) =>
										prepareIcon(t('shared.user-setting-menu.company'), isOpen)
									}
									menuItems={renderCompany()}
								/>

								<LayoutHeaderMenu
									showBehavior="hover"
									triggerComponent={({ isOpen }) =>
										prepareIcon(t('shared.support'), isOpen)
									}
									menuItems={renderSupport()}
								/>
							</>
						)}

						{!userLoggedIn && (
							<div
								onClick={() => navigationPush('Dashboard')}
								className="hover:text-brand-600"
							>
								{t('shared.user-setting-menu.login')}
							</div>
						)}
					</div>
				);
			},
		},
		...(max_lg
			? {
					drawer: {
						sections: [
							{
								items: [
									{
										label: t('shared.user-setting-menu.dashboard'),
										onClick: () => {
											navigationPush('Dashboard');
										},
										icon: HomeIcon,
										isHidden: !userLoggedIn,
									},
								],
							},
							{
								sectionTitle: t('shared.user-setting-menu.car-insurance'),
								items: [
									{
										icon: AgCarIcon,
										label: t('shared.user-setting-menu.uae-vehicles'),
										onClick: () => {
											clickAnchor('https://www.shory.com');
										},
									},
									{
										icon: AgCarIcon,
										label: t('shared.user-setting-menu.non-uae-vehicles'),
										onClick: () => {
											if (isRtlLocale) {
												clickAnchor('https://aber.shory.com');
											} else {
												clickAnchor('https://aber.shory.com/en');
											}
										},
									},
								],
							},
							{
								sectionTitle: t('shared.user-setting-menu.travel-insurance'),
								items: [
									{
										icon: NjPlaneIcon,
										label: t('shared.user-setting-menu.inbound-visit-visa'),
										onClick: () => navigationPush('QuotaPurchaseLanding'),
									},
								],
							},
							{
								sectionTitle: t('shared.user-setting-menu.help'),
								items: [
									{
										label: t('shared.live-chat'),
										icon: ChatIcon,
										onClick: startChat,
									},
								],
							},
							{
								sectionTitle: t('shared.user-setting-menu.company'),
								items: [
									{
										icon: OutlinedInfoCircleIcon,
										label: t('shared.user-setting-menu.about-us'),
										onClick: () => {
											clickAnchor('https://www.shory.com/about-us');
										},
									},
									{
										icon: BankIcon,
										label: t('shared.user-setting-menu.terms-conditions'),
										onClick: () => {
											navigationPush('TermsAndConditions');
										},
									},
									{
										icon: GlobalIcon,
										label: t('shared.user-setting-menu.privacy'),
										onClick: () => {
											navigationPush('PrivacyPolicy');
										},
									},
								],
							},
							{
								items: [
									{
										label: t('shared.user-setting-menu.account-settings'),
										onClick: () => {
											navigationPush('AccountSettings');
										},
										icon: SettingsIcon,
										isHidden: !userLoggedIn,
									},
									{
										label: t('shared.contact-number'),
										icon: TelephoneIcon,
										onClick: () => (window.location.href = 'tel:80074679'),
										dir: 'ltr',
									},
									{
										type: 'languageSwitch',
									},
								],
							},
							{
								items: [
									{
										label: t('shared.user-setting-menu.login'),
										icon: LogoutIcon,
										className: 'text-brand-600',
										onClick: () => {
											navigationPush('Dashboard');
										},
										isHidden: userLoggedIn,
									},
									{
										label: t('shared.user-setting-menu.logout'),
										icon: LogoutIcon,
										className: 'text-red-500',
										onClick: () => {
											navigationPush('SSO_Logout');
										},
										isHidden: !userLoggedIn,
									},
								],
							},
						],
					},
			  }
			: {}),
	};

	return settings;
};

export default useLayoutSetting;
