import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

type ApiPaymentLinkResponse = {
	baseTariffAmount: number;
	VatConfigurationId: number;
	paymentExpiresOn: string;
	requestedPolicyStatusId: number;
};

export const apiPaymentLink = (builder: ApiEndpointBuilder) => {
	return builder.query<ApiPaymentLinkResponse, string>({
		query: (encryptedRequestedPolicyId: string) =>
			Endpoints.urls.payment.paymentLink(encryptedRequestedPolicyId),
		transformResponse: (response: IBaseResponse<ApiPaymentLinkResponse>) =>
			response.data,
	});
};
