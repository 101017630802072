import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingResponse } from '../../common/types';

interface IApiGetDashboardQuotasRequest extends IPagingAndSorting {
	tradeLicenseNumber?: string;
	agencyId?: string;
	searchText?: string;
	visaPeriodId?: number;
	quotaIssueDate?: string;
	quotaEffectiveDate?: string;
	quotaExpiryDate?: string;
}

export interface IDashboardQuota {
	quotaNumber: string;
	planId: number;
	agencyName: string;
	visaPeriodId: number;
	visaTypeId: number;
	usedQuotaCount: number;
	totalQuotaCount: number;
	issueDate: string;
	effectiveDate: string;
	expiryDate: string;
	amount: number;
	memberPriceInclVAT: string;
}

export type IApiGetDashboardQuotasResponse = {
	quotas: IDashboardQuota[];
	paging: {
		totalRecords: number;
	};
};

export const apiGetDashboardQuotas = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IDashboardQuota>,
		IApiGetDashboardQuotasRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.customerPortal.quotas,
			method: 'POST',
			body: request,
		}),
		transformResponse: (response: IApiGetDashboardQuotasResponse) => {
			return {
				data: response.quotas,
				totalItemsCount: response.paging.totalRecords,
			};
		},
	});
};
