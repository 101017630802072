import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiSubmitNewPolicyRequest {
	insuranceCompanyId?: number | null;
	memberFullNameEn?: string;
	memberFullNameAr?: string;
	memberEmailAddress?: string;
	memberMobileNumber?: string;
	memberNationalityId?: number;
	memberDateOfBirth?: string;
	memberGenderId?: number;
	memberPassportNo?: string;
	passportIssueDate?: string;
	passportExpiryDate?: string;
	passportIssueCountryId?: number;
	baseTariffId?: number;
	requestedPaymentMethodId?: number;
	paymentReferenceNo?: string;
	comment?: string;
}

interface IApiSubmitNewPolicyResponse {
	data: {
		paymentLink: string;
	};
}
export const apiSubmitNewPolicy = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiSubmitNewPolicyResponse,
		IApiSubmitNewPolicyRequest
	>({
		query: (body) => ({
			url: Endpoints.urls.admin['requested-policy'].submit,
			body,
			method: 'POST',
		}),
	});
};
