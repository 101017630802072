import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useCurrencyList = () => {
	const currencyList = useAppSelector(
		(state) => state.ui.appInitData?.currencyList ?? [],
	);

	const getCurrencyById = useCallback(
		(currencyId: number) => {
			return currencyList.find((e) => e.id === currencyId);
		},
		[currencyList],
	);

	return { currencyList, getCurrencyById };
};
