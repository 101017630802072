import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IGetUpdatePolicyRequest {
	policyNumber?: string;
	insuranceCompanyId?: number;
	policyIssueDate?: string;
}

export interface IGetUpdatePolicyResponse {
	policyId: number;
}

export const apiGetUpdatePolicy = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IGetUpdatePolicyResponse, IGetUpdatePolicyRequest>({
		query: (body) => ({
			method: 'POST',
			url: Endpoints.urls.admin.policy['search-update'],
			body,
		}),
	});
};
