import { useCallback, useEffect } from 'react';
import {
	IResponseError,
	IResponseErrors,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import { initiateJourney } from '../../../redux-store/quota-purchase.slice';
import {
	useGetCompanyDetailsByEncryptedIdMutation,
	useGetCompanyDetailsMutation,
	useUpdateGuidMutation,
} from '../../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch } from '../../../redux-store/store';

interface IProps {
	navigateToQuotaPurchase: () => void;
	navigateToLanding: () => void;
	navigateToQuotaDetail: () => void;
	quoteJourneyId?: string;
	isNewQuota?: boolean;
	onGeneralErrors: (errors: IResponseError[]) => void;
}

function useInitICPQuotaPurchase(props: IProps) {
	const {
		navigateToQuotaPurchase,
		navigateToLanding,
		onGeneralErrors,
		quoteJourneyId,
		navigateToQuotaDetail,
		isNewQuota,
	} = props;
	const appDispatch = useAppDispatch();
	const [getCompanyDetailsAPI] = useGetCompanyDetailsByEncryptedIdMutation();

	const [onGetCompanyDetails] = useGetCompanyDetailsMutation();
	const [updateGuid] = useUpdateGuidMutation();
	const { getErrorMessages } = useErrorsResponseTransform(
		'company.errors',
		true,
	);

	const onGetCompanyDetailsHandler = useCallback(async () => {
		try {
			if (!quoteJourneyId) {
				navigateToLanding();
				return;
			}

			const company = await onGetCompanyDetails(quoteJourneyId).unwrap();
			const agencyEncryptedInfo = company.agencyInfoEncrypted;

			const { id } = await getCompanyDetailsAPI({
				companyJsonEncrypted: agencyEncryptedInfo,
			}).unwrap();

			await updateGuid({
				journeyId: quoteJourneyId,
				companyGuid: id,
			}).unwrap();

			appDispatch(
				initiateJourney({
					quoteJourneyId,
					company,
					identityCompanyId: id,
					isAgencyNonEditable: true,
					isICP: true,
				}),
			);

			if (isNewQuota) {
				navigateToQuotaDetail();
				return;
			}
			navigateToQuotaPurchase();
		} catch (e) {
			const errors = e as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onGeneralErrors(mappedGeneralErrors);
			navigateToLanding();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		appDispatch,
		navigateToQuotaPurchase,
		navigateToLanding,
		onGetCompanyDetails,
		quoteJourneyId,
	]);

	useEffect(() => {
		onGetCompanyDetailsHandler();
	}, [onGetCompanyDetailsHandler]);
}

export default useInitICPQuotaPurchase;
