import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiUpdateKycRequestDetailRequest {
	kycRequestId: number;
	companyDetail: {
		agencyId?: string;
		fullNameEn?: string;
		licenseNo?: string;
		establishmentNumber?: string;
		cityId?: number;
		emailAddress?: string;
		mobileNumber?: string;
	};
	regulatedDetails: {
		questionId?: number;
		isFirmRegulated?: boolean;
	};
	pepDetails: {
		questionId?: number;
		hasPep?: boolean;
		peps?: {
			name?: string;
			jobTitle?: string;
			pepTypeId?: number;
		}[];
	};
	encryptedDocumentIds: string[];
	documentReference: string;
}

export const apiUpdateKycRequestDetail = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiUpdateKycRequestDetailRequest>({
		query: ({
			kycRequestId,
			companyDetail,
			encryptedDocumentIds,
			documentReference,
			regulatedDetails,
			pepDetails,
		}) => ({
			method: 'POST',
			url: Endpoints.urls.admin.kyc.updateCompany(kycRequestId),
			body: {
				companyDetail,
				encryptedDocumentIds,
				documentReference,
				regulatedDetails,
				pepDetails,
			},
		}),
	});
};
