import { useCallback } from 'react';
import { setCurrentStep } from '../../../redux-store/quota-purchase.slice';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import { selectIsLoggedIn } from '../../../selectors/persisted-settings.selectors';
import { selectCurrentStep } from '../../../selectors/quotaPurchase.selectors';

export enum STEPPER_STEPS {
	INDEX = 0,
	QUOTA = 1,
	SUMMARY = 2,
	PAYMENT = 3,
	SUCCESSFUL = 4,
}

export const useStepperBasedOnData = () => {
	const currentStep = useAppSelector(selectCurrentStep);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const dispatch = useAppDispatch();

	const setStep = (step: STEPPER_STEPS) => {
		dispatch(setCurrentStep(step));
	};

	const getCurrentStep = useCallback(() => {
		let pageNumber: STEPPER_STEPS = currentStep;

		if (currentStep > STEPPER_STEPS.SUMMARY && !isLoggedIn) {
			pageNumber = STEPPER_STEPS.SUMMARY;
		}

		return pageNumber;
	}, [currentStep, isLoggedIn]);

	return { getCurrentStep, setStep };
};
