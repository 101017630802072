import axios, {
	AxiosError,
	AxiosHeaders,
	AxiosRequestConfig,
	AxiosRequestHeaders,
} from 'axios';
import {
	IResponseError,
	IResponseErrorsArray,
	logger,
	useErrorsResponseTransform,
} from '@ninja/utilities';

export enum HttpMethod {
	GET = 'get',
	POST = 'post',
}
type NativeBlob = {
	uri: string;
	name: string;
	type: string;
	size?: number;
	extension?: string;
};

export interface IFileImportRequest<T> {
	fileName: string;
	url: string;
	data?: T;
	method: HttpMethod;
	target?: boolean;
}

export interface IFileImportResponse {
	status?: string;
	data?: NativeBlob;
	error?: IResponseError[];
}

const headers = AxiosHeaders.from({
	'Content-Type': 'application/json',
}) as AxiosRequestHeaders;

const useFileImport = () => {
	const { getErrorMessages } = useErrorsResponseTransform();

	const getResponse = async <T>(request: IFileImportRequest<T>) => {
		const { url, data, method } = request;
		if (!request.url) {
			throw new Error();
		}

		const config: AxiosRequestConfig = {
			headers: headers,
			responseType: 'blob',
		};

		switch (method) {
			case HttpMethod.GET:
				return axios.get(url, config);
			case HttpMethod.POST:
				return axios.post(url, data, config);
			default:
		}
	};

	const internalImportDocument = async <T>(
		request: Readonly<IFileImportRequest<T>>,
	): Promise<IFileImportResponse> => {
		try {
			const response = await getResponse<T>(request);
			const blob = response?.data;
			const browserURL = URL?.createObjectURL(blob);
			const nativeBlob: NativeBlob = {
				name: request.fileName,
				type: blob.type,
				size: blob.size,
				uri: browserURL,
			};

			return { status: '200', data: nativeBlob };
		} catch (error) {
			logger.info('[importDocument][error]', error);
			const { response, code } = error as AxiosError;
			const httpStatus = response?.status ?? 0;
			let resultErrors: IResponseErrorsArray = {
				status: httpStatus.toString(),
			};

			if (
				code &&
				[AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED].includes(code)
			) {
				resultErrors = {
					status: 'FETCH_ERROR',
				};
			} else if (httpStatus === 400 || httpStatus === 422) {
				const errors = response?.data as IResponseError[] | undefined;

				resultErrors = {
					status: httpStatus.toString(),
					data: errors,
				};
			} else {
				resultErrors = {
					status: 'Server500',
				};
			}

			const mappedError = getErrorMessages(resultErrors);

			return { error: mappedError };
		}
	};

	const importDocument = async <T>(
		request: Readonly<IFileImportRequest<T>>,
	) => {
		const response = await internalImportDocument(request);

		return response;
	};

	return { importDocument };
};

const useFileExport = () => {
	const { importDocument } = useFileImport();
	const { getErrorMessages } = useErrorsResponseTransform();

	const exportDocument = async <T>(
		request: Readonly<IFileImportRequest<T>>,
	) => {
		try {
			const response = await importDocument(request);

			if (response?.data) {
				const link = document.createElement('a');

				link.href = response?.data.uri;
				request.target
					? link.setAttribute('target', '_blank')
					: link.setAttribute('download', request.fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
			} else if (response?.error) {
				return {
					error: response?.error,
				};
			}

			return response;
		} catch (error) {
			logger.info('[exportDocument][error]', error);
			const resultErrors = {
				status: 'general-error',
			};

			const mappedError = getErrorMessages(resultErrors);

			return {
				error: mappedError,
			};
		}
	};

	return { exportDocument };
};

export default useFileExport;
