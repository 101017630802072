import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistStore,
} from 'redux-persist';
import { IPersistStorage, persistReducerConfig } from '@ninja/utilities';
import { configureStore } from '@reduxjs/toolkit';
import { LibConfig } from '../config';
import { persistedSettings_PersistConfig } from './persisted-settings.slice';
import { quotaPurchase_PersistConfig } from './quota-purchase.slice';
import { apiConfig } from './rtkq-slice/api-config';
import { ui_PersistConfig } from './ui.slice';

export const StorageConfig: { storage?: IPersistStorage } = {};

const reducers = (storage: IPersistStorage) =>
	combineReducers({
		persistedSettings: persistReducerConfig(
			storage,
			persistedSettings_PersistConfig,
		),
		ui: persistReducerConfig(storage, ui_PersistConfig),
		[apiConfig.reducerPath]: apiConfig.reducer,
		quotaPurchase: persistReducerConfig(storage, quotaPurchase_PersistConfig),
	});

export const makeStore = (storage: IPersistStorage) => {
	StorageConfig.storage = storage;
	const store = configureStore({
		reducer: reducers(storage),
		middleware: (getDefaultMiddleware) => {
			const defaultMiddleWare = getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}).concat(apiConfig.middleware);

			return LibConfig.isProduction
				? defaultMiddleWare
				: defaultMiddleWare.concat(logger as any);
		},
		devTools: !LibConfig.isProduction,
	});

	const persistor = persistStore(store);

	return { store, persistor };
};

export type AppStoreRootState = ReturnType<typeof reducers>;

export type AppStore = ReturnType<typeof makeStore>['store'];

export type AppState = ReturnType<AppStore['getState']>;

export type AppDispatch = ReturnType<typeof makeStore>['store']['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
