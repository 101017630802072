import { useCallback, useEffect } from 'react';
import { logger } from '@ninja/utilities';
import {
	STEPPER_STEPS,
	useSSO,
	useStepperBasedOnData,
	useUserInfo,
} from '@visit-visa/lib-customer';
import { AppConfig } from '../config/app-config';
import { useWebNavigation } from '../contexts/navigation';

interface MessageData {
	source?: string;
	payload?: {
		type?: string;
		code?: string;
		uid?: string;
	};
}

function AuthorizationIFrame() {
	const { navigationPush, getPathForRoute } = useWebNavigation();
	const { userLoggedIn, user } = useUserInfo();
	const { resetUserDetails, processToken } = useSSO();
	const { getCurrentStep } = useStepperBasedOnData();

	const eventHandler = useCallback(
		(event: MessageEvent<MessageData>) => {
			const { source, payload } = event.data;

			if (source !== 'shory-accounts' || !payload) return;

			logger.info(
				'[AuthorizationIFrame][eventHandler] Message received from the child: ',
				event.data,
			);
			const { type, code, uid } = payload || {};

			if (userLoggedIn && type === 'LOGOUT') {
				logger.info('[AuthorizationIFrame][eventHandler] Logout');
				const currentStep = getCurrentStep();

				// Logout and ignore the current step if its after Summary step
				if (currentStep > STEPPER_STEPS.SUMMARY) {
					navigationPush('SSO_Logout');
				} else {
					// Otherwise logout silently
					resetUserDetails();
				}
			} else if (
				type === 'LOGIN' &&
				typeof code === 'string' &&
				((userLoggedIn && user?.id !== uid) || !userLoggedIn)
			) {
				logger.info('[AuthorizationIFrame][eventHandler] Login: ');
				processToken(code);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[navigationPush, user, userLoggedIn],
	);

	useEffect(() => {
		window.addEventListener('message', eventHandler);

		return () => {
			window.removeEventListener('message', eventHandler);
		};
	}, [eventHandler]);

	return (
		<iframe
			src={`${getPathForRoute('IframeAuthorize')}?client_id=${
				AppConfig.productId
			}`}
			className="hidden"
		/>
	);
}

export default AuthorizationIFrame;
