import { useCallback } from 'react';
import { useGetUserDetailsMutation } from '../redux-store/rtkq-slice/endpoints';
import { useAppSelector } from '../redux-store/store';
import { selectRequireRefresh } from '../selectors/persisted-settings.selectors';

export const useRefreshToken = () => {
	const isRequiredRefresh = useAppSelector(selectRequireRefresh);
	const [getUserDetails] = useGetUserDetailsMutation();

	const refreshIfRequired = useCallback(() => {
		if (isRequiredRefresh) {
			getUserDetails();
		}
	}, [isRequiredRefresh, getUserDetails]);

	return { refreshIfRequired };
};
