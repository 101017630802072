import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetCustomerWalletRequest {
	customerId: number;
}
export interface IApiGetCustomerWalletResponse {
	walletRequestId: number;
	requestReferenceNo: string;
	requestDate: string;
	approvedDate: string;
	customerAttachmentLink: string;
	receiptFilePath: string;
	receiptNumber: string;
	transactionType: number;
	transferReferenceNumber: string;
	agencyName: string;
	requesterEmail: string;
	requesterId: number;
	approverEmail: string;
	bankName: string;
	requestAmount: number;
	approvedAmount: number;
	balance: number;
	statusId: number;
	walletGuid: number;
	availableBalance: number;
}

export const apiGetCustomerWallets = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetCustomerWalletResponse>,
		IApiGetCustomerWalletRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.customer.wallet(request.customerId),
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetCustomerWalletResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
