import { useEffect, useState } from 'react';
import {
	IResponseError,
	IResponseErrors,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import { useLazyGetIssuedPolicyByPaymentLinkDetailsQuery } from '../../redux-store/rtkq-slice/endpoints';
import { usePlansData } from '../AppInitData';
import useCurrencyAndVat from '../useCurrencyAndVat';
import useFileImport, { HttpMethod } from '../useFileExport';

type UsePolicyIssuanceProps = {
	encryptedRequestedPolicyId: string;
	onApiErrors: (errors: IResponseError[]) => void;
	onDocumentNotReady?: () => void;
};

const usePolicyIssuance = ({
	encryptedRequestedPolicyId,
	onApiErrors,
	onDocumentNotReady,
}: UsePolicyIssuanceProps) => {
	const { exportDocument } = useFileImport();
	const [isAttachmentLoading, setIsAttachmentLoading] = useState<
		'receipt' | 'certificate'
	>();
	const { getErrorMessages } = useErrorsResponseTransform();
	const [getIssuedPolicyDetails, { isLoading, data: policyDetails }] =
		useLazyGetIssuedPolicyByPaymentLinkDetailsQuery();
	const { getCurrencyAndVat } = useCurrencyAndVat();
	const currencyAndVat = getCurrencyAndVat();
	const { getPlanById } = usePlansData();
	const planName = getPlanById(policyDetails?.planId ?? 0)?.name;

	useEffect(() => {
		onGetPolicyIssuance();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onGetPolicyIssuance = async () => {
		try {
			const res = await getIssuedPolicyDetails(
				encryptedRequestedPolicyId ?? '',
			).unwrap();
			return res;
		} catch (e) {
			const errors = e as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onApiErrors(mappedGeneralErrors);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const onDownloadFileHandler = async (
		buttonSource: 'receipt' | 'certificate',
	) => {
		setIsAttachmentLoading(buttonSource);

		let link: string | undefined = undefined;
		if (buttonSource === 'receipt') link = policyDetails?.policyReceiptUrl;
		else link = policyDetails?.policyCertificateUrl;

		if (!link) {
			const res = await onGetPolicyIssuance();

			if (buttonSource === 'receipt') link = res?.policyReceiptUrl;
			else link = res?.policyCertificateUrl;
		}

		// If link is ready then download else show message document is not ready.
		if (link) {
			const fileRes = await exportDocument({
				url: link ?? '',
				fileName: link?.split('/')?.pop() || '',
				method: HttpMethod.GET,
				target: false,
			});

			setIsAttachmentLoading(undefined);

			if (fileRes.error) {
				onDocumentNotReady?.();
				setIsAttachmentLoading(undefined);
			}
		} else {
			onDocumentNotReady?.();
			setIsAttachmentLoading(undefined);
		}
	};

	return {
		policyDetails,
		onDownloadFileHandler,
		isLoading,
		isAttachmentLoading: isAttachmentLoading,
		vatRate: currencyAndVat?.rate,
		planName,
	};
};

export default usePolicyIssuance;
