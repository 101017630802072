import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useUaeBank = () => {
	const banks = useAppSelector((state) => state.ui.appInitData?.uaeBanks ?? []);

	const getUaeBankById = useCallback(
		(bankId: number) => {
			return banks.find((e) => e.id === bankId);
		},
		[banks],
	);

	const getAscendingSortedBanks = () => {
		return [...banks].sort((a, b) =>
			a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
		);
	};

	return { banks, getUaeBankById, getAscendingSortedBanks };
};
