import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetApiErrorResponse {
	apiLogLinkingId: string;
	apiName: string;
	errorCode: string;
	errorDate: string;
	requestReferenceNo?: string;
}

export interface IApiGetApiErrorsRequest extends IPagingAndSorting {
	apiNameIds?: number[];
	errorDateFrom?: string;
	errorDateTo?: string;
	initiatorIds?: number[];
	requestReferenceNo?: string;
}

export const apiGetApiErrors = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetApiErrorResponse>,
		IApiGetApiErrorsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.apiError.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetApiErrorResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
