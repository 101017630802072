import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiQuotaCancellationEditRefundRequest {
	receiverName?: string;
	accountNumber?: string;
	iban?: string;
	bankId?: number;
	quotaCancellationId: number;
}

export const apiQuotaCancellationEditRefund = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiQuotaCancellationEditRefundRequest>({
		query: ({ quotaCancellationId, ...remain }) => ({
			url: Endpoints.urls.admin['quota-cancellation'].editRefund(
				quotaCancellationId,
			),
			method: 'POST',
			body: remain,
		}),
	});
};
