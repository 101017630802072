import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetSystemHealthChecksRequest {
	searchTimeline?: number;
	searchDateFrom?: string;
	searchDateTo?: string;
}

interface ISystemHealthCheck {
	isUp: boolean;
	apiName: string;
	lastAccess: string;
	lastDownAlert: string;
	downSince: string;
	successfulResponseCount: number;
	failedResponseCount: number;
}

interface IApiGetSystemHealthChecksResponse {
	healthApiCheckResponse: ISystemHealthCheck[];
}

export const apiGetSystemHealthChecks = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiGetSystemHealthChecksResponse,
		IApiGetSystemHealthChecksRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.dashboard.healthSystemCheck,
			body: request,
		}),
	});
};
