import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useKycQuestions = () => {
	const kycQuestions = useAppSelector(
		(state) => state.ui.appInitData?.kycQuestions ?? [],
	);

	const getKycById = useCallback(
		(kycId: number) => {
			return kycQuestions.find((e) => e.id === kycId);
		},
		[kycQuestions],
	);

	return { kycQuestions, getKycById };
};
