import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../config/endpoints';
import { IAdminAppInitResponse, IAppInitResponse } from '../types';

export const appInitQuery = (builder: ApiEndpointBuilder) => {
	return builder.query<IAppInitResponse, void>({
		query: () => {
			return Endpoints.urls.app.init;
		},
		keepUnusedDataFor: 600,
		extraOptions: { maxRetries: 15 },
		transformResponse: (data: IAppInitResponse) => data,
	});
};

export const adminAppInitQuery = (builder: ApiEndpointBuilder) => {
	return builder.query<IAdminAppInitResponse, void>({
		query: () => {
			return Endpoints.urls.admin.app.init;
		},
		keepUnusedDataFor: 600,
		extraOptions: { maxRetries: 15 },
		transformResponse: (data: IAdminAppInitResponse) => data,
	});
};
