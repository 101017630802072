import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config/endpoints';

export interface IPolicy {
	memberName: string;
	passportNo: string;
	quotaNumber: string;
	policyNumber: string;
	email: string;
	mobileNumber: string;
	policyIssueDate: string;
	dateOfBirth: string;
	policyStatusId: number;
}
interface IApiGetPoliciesResponse {
	policies: IPolicy[];
	paging: {
		totalRecords: number;
	};
}
interface IApiGetPoliciesRequest extends IPagingAndSorting {
	quotaNumber?: string;
	agencyId?: string;
	userId?: number;
	memberName?: string;
	email?: string;
	mobileNumber?: string;
	policyReferenceNo?: string;
	planId?: number;
	visaPeriodId?: number;
	policyIssueDate?: string;
	searchText?: string;
}
export const apiGetPolicies = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IApiGetPoliciesResponse, IApiGetPoliciesRequest>({
		query: (body) => ({
			url: Endpoints.urls.customerPortal.policies,
			method: 'POST',
			body: body,
		}),
	});
};
