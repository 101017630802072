import { useEffect, useRef, useState } from 'react';
import {
	IRTKQuerySerializedError,
	IResponseErrorsArray,
	logger,
} from '@ninja/utilities';
import { IResponseErrors, useErrorsResponseTransform } from '@ninja/utilities';
import {
	setJourneyPaymentErrors,
	setPaymentMethod,
} from '../../../redux-store/quota-purchase.slice';
import {
	useGetWalletBalanceMutation,
	usePostAuthorizePaymentMutation,
	usePostCapturePaymentMutation,
	usePostWalletPaymentMutation,
} from '../../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import {
	selectCompanyDetails,
	selectPaymentErrors,
	selectQuotaDetails,
	selectQuoteJourneyId,
} from '../../../selectors/quotaPurchase.selectors';
import { selectQuotaPriceLimitForCardPayment } from '../../../selectors/ui.selectors';
import { usePaymentPublicKey } from '../../AppInitData/usePaymentPublicKey';
import useCurrencyAndVat from '../../useCurrencyAndVat';
import { useTransformSelectedQuota } from '../select-product';

const usePaymentOptions = ({
	onPaymentSuccess,
}: {
	onPaymentSuccess: () => void;
}) => {
	const appDispatch = useAppDispatch();
	const { selectedProductDetails } = useTransformSelectedQuota();
	const { agencyId } = useAppSelector(selectCompanyDetails) ?? {};
	const quotaDetails = useAppSelector(selectQuotaDetails);
	const { paymentPublicKey } = usePaymentPublicKey();
	const paymentErrors = useAppSelector(selectPaymentErrors);
	const quotaPriceLimitForCardPayment = useAppSelector(
		selectQuotaPriceLimitForCardPayment,
	);
	const encryptedQuoteJourneyId = useAppSelector(selectQuoteJourneyId) ?? '';
	const { getErrorMessages } = useErrorsResponseTransform();
	const [postAuthorizePayment] = usePostAuthorizePaymentMutation();
	const [postCapturePayment] = usePostCapturePaymentMutation();
	const [getWalletBalance, walletBalanceResponse] =
		useGetWalletBalanceMutation();
	const [postWalletPayment] = usePostWalletPaymentMutation();
	const [isProcessing, setIsProcessing] = useState(false);
	const paymentIdRef = useRef('');
	const { getCurrencyAndVat } = useCurrencyAndVat();
	const currencyAndVat = getCurrencyAndVat();
	const AED = 'AED';
	const amountWithoutVat = selectedProductDetails?.totalVatExclusiveAmount ?? 0;
	const vatAmount = selectedProductDetails?.totalVatAmount ?? 0;

	useEffect(() => {
		resetPaymentState();
		if (agencyId) {
			const getWalletBalanceHandler = async () => {
				await getWalletBalance()?.unwrap();
			};
			getWalletBalanceHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getWalletBalance, agencyId]);

	const isCardPaymentEnabled =
		amountWithoutVat + vatAmount < quotaPriceLimitForCardPayment;

	const authorizePayment = async (cardToken: string) => {
		setIsProcessing(true);
		const authorizePaymentData = {
			journeyId: encryptedQuoteJourneyId,
			cardToken: cardToken,
			paymentProviderId: 1,
			payment: {
				amountWithoutVat: selectedProductDetails?.totalVatExclusiveAmount ?? 0,
				vatAmount: selectedProductDetails?.totalVatAmount ?? 0,
				currency: currencyAndVat?.currencyName ?? AED,
			},
		};
		try {
			const { paymentId, url3Ds } = await postAuthorizePayment(
				authorizePaymentData,
			).unwrap();
			paymentIdRef.current = paymentId;
			return url3Ds;
		} catch (error) {
			const errors = getErrorMessages(
				error as
					| IResponseErrors
					| IResponseErrorsArray
					| IRTKQuerySerializedError,
			);
			appDispatch(
				setJourneyPaymentErrors(
					errors.map((error) => ({
						errorCode: error.code,
						errorMessage: error.message ?? '',
					})),
				),
			);
			setIsProcessing(false);
		}
	};

	const on3dsModalClose = () => {
		setIsProcessing(false);
	};

	const capturePayment = async (cardType: string | undefined) => {
		const capturePaymentRequest = {
			journeyId: encryptedQuoteJourneyId,
			paymentId: paymentIdRef.current,
		};

		logger.log('[usePaymentOptions][capturePayment]', capturePaymentRequest);

		try {
			await postCapturePayment(capturePaymentRequest).unwrap();
			switch (cardType?.toLowerCase()) {
				case 'credit':
					appDispatch(setPaymentMethod('Credit'));
					break;
				case 'debit':
					appDispatch(setPaymentMethod('Debit'));
					break;
				default:
					appDispatch(setPaymentMethod('Debit_OR_Credit'));
			}

			onPaymentSuccess();
		} catch (error) {
			const errors = getErrorMessages(
				error as
					| IResponseErrors
					| IResponseErrorsArray
					| IRTKQuerySerializedError,
			);
			appDispatch(
				setJourneyPaymentErrors(
					errors.map((error) => ({
						errorCode: error.code,
						errorMessage: error.message ?? '',
					})),
				),
			);
			setIsProcessing(false);
		}
	};

	const resetPaymentState = () => {
		appDispatch(setJourneyPaymentErrors(undefined));
	};

	const walletPayment = async () => {
		setIsProcessing(true);
		const walletPaymentRequest = {
			quoteJourneyId: encryptedQuoteJourneyId,
			payment: {
				amountWithoutVat: selectedProductDetails?.totalVatExclusiveAmount ?? 0,
				vatAmount: selectedProductDetails?.totalVatAmount ?? 0,
				currency: currencyAndVat?.currencyName ?? AED,
			},
		};

		logger.log(
			'[usePaymentOptions][walletPaymentRequest]',
			walletPaymentRequest,
		);

		try {
			await postWalletPayment(walletPaymentRequest).unwrap();
			appDispatch(setPaymentMethod('Shory_Wallet'));
			onPaymentSuccess();
		} catch (error) {
			const errors = getErrorMessages(
				error as
					| IResponseErrors
					| IResponseErrorsArray
					| IRTKQuerySerializedError,
			);
			appDispatch(
				setJourneyPaymentErrors(
					errors.map((error) => ({
						errorCode: error.code,
						errorMessage: error.message ?? '',
					})),
				),
			);
			setIsProcessing(false);
		}
	};

	return {
		quotaDetails: {
			...selectedProductDetails,
			...quotaDetails,
		},
		paymentPublicKey,
		authorizePayment,
		capturePayment,
		paymentErrors,
		isProcessingPayment: isProcessing,
		isPaymentFailed: !!paymentErrors,
		resetPaymentState,
		walletPayment,
		walletBalance: walletBalanceResponse?.data?.balance ?? 0,
		isWalletLoading:
			walletBalanceResponse?.isLoading ||
			walletBalanceResponse?.isUninitialized,
		isCardPaymentEnabled,
		on3dsModalClose,
	};
};

export default usePaymentOptions;
