import { useCallback } from 'react';
import { IApiGetIssuedQuotaDetailsResponse } from '@visit-visa/api-visit-visa';
import { useAppSelector } from '../../../redux-store/store';
import { selectQuotaDetailsConfigurableFields } from '../../../selectors/ui.selectors';
import { useInsuranceCompanies } from '../../AppInitData';

interface IssuedQuotaDetails extends IApiGetIssuedQuotaDetailsResponse {
	insuranceCompanyName: string;
}

interface IVisaTypes {
	id: number;
	name: string;
	days: number;
	years: number;
}

const getVisaTypeById = (id: number, visaTypes: IVisaTypes[]) =>
	visaTypes?.find((type: { id: number }) => type.id === id)?.name ?? '';

const useProductIssuanceTransformer = () => {
	const { visaTypes } = useAppSelector(selectQuotaDetailsConfigurableFields);
	const { insuranceCompanies } = useInsuranceCompanies();

	const transformProductIssuance = useCallback(
		(quotaIssuanceDetails?: IApiGetIssuedQuotaDetailsResponse) => {
			if (!quotaIssuanceDetails) return {} as Partial<IssuedQuotaDetails>;

			return {
				...quotaIssuanceDetails,
				insuranceCompanyName: insuranceCompanies?.find(
					(el) => el.id === quotaIssuanceDetails?.insuranceCompanyId,
				)?.icNameEn,
				paidAmount: quotaIssuanceDetails.paidAmount,
				visaPeriodId: getVisaTypeById(
					quotaIssuanceDetails.visaPeriodId,
					visaTypes,
				),
				effectiveDate: quotaIssuanceDetails.effectiveDate ?? '',
				expiryDate: quotaIssuanceDetails.expiryDate ?? '',
				issueDate: quotaIssuanceDetails.issueDate ?? '',
			};
		},
		[visaTypes, insuranceCompanies],
	);

	return { transformProductIssuance };
};

export default useProductIssuanceTransformer;
