import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiGetWalletBalanceResponse {
	id: number;
	walletNumber: string;
	balance: number;
}

export const apiGetWalletBalance = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IApiGetWalletBalanceResponse, void>({
		query: () => ({
			url: Endpoints.urls.wallet.getBalance,
			method: 'GET',
		}),
	});
};
