import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetIcReconciliationsReportsResponse {
	id: string;
	rowNum: number;
	policyId: number;
	requestReferenceNo: string;
	policyNumber: string;
	policyType: string;
	memberNameEn: string;
	passportNo: number;
	visaApprovedDate: string;
	policyIssueDate: string;
	policyPeriod: number;
	policyStatus: number;
	insuranceCompanyName: string;
	policyAmountExcludingVAT: number;
	vatPercentage: number;
	vatAmount: number;
	policyAmountIncludingVAT: number;
	shoryCommissionExcludingVAT: number;
	shoryCommissionIncludingVAT: number;
	icpPaymentReferenceNumber: number;
	settlementDueDate: string;
	settlementStatus: string;
	settlementDate: string;
	settlementReferenceNumber: number;
	customerInvoiceNumber: string;
	customerInvoiceUrl: string;
	icInvoiceNumber: string;
	icInvoiceUrl: string;
	dueToIcExcludeVAT: number;
	dueToIcIncludeVAT: number;
	vatOnDueToIc: number;
	isCanceled: boolean;
	cancelationDate: string;
	cancelationReasonId: number;
	cancelationReasonDescription?: string;
	recordDate: string;
}

interface ISearchCriteria extends Partial<IPagingAndSorting> {
	policyIds?: number[];
	policyNumbers?: string[];
	icpPaymentReferenceNumber?: string;
	insuranceCompanyIds?: number[];
	memberName?: string;
	passportNumber?: string;
	policyIssueDateFrom?: string;
	policyIssueDateTo?: string;
	cancellationDateFrom?: string;
	cancellationDateTo?: string;
	policyNumber?: string;
	policyPeriodIds?: number[];
	policyStatusIds?: number[];
	policyTypeId?: number;
	requestReferenceNo?: string;
	settlementDateFrom?: string;
	settlementDateTo?: string;
	settlementReferenceNumber?: string;
	settlementStatusIds?: number[];
	customerInvoiceNumber?: string;
	icInvoiceNumber?: string;
	recordIds?: string[];
}
export interface IApiGetIcReconciliationsReportsRequest
	extends IPagingAndSorting {
	policyIds?: number[];
	policyNumbers?: string[];
	icpPaymentReferenceNumber?: string;
	insuranceCompanyIds?: number[];
	memberName?: string;
	passportNumber?: string;
	policyIssueDateFrom?: string;
	policyIssueDateTo?: string;
	cancellationDateFrom?: string;
	cancellationDateTo?: string;
	policyNumber?: string;
	policyPeriodIds?: number[];
	policyStatusIds?: number[];
	policyTypeId?: number;
	requestReferenceNo?: string;
	settlementDateFrom?: string;
	settlementDateTo?: string;
	settlementReferenceNumber?: string;
	settlementStatusIds?: number[];
	customerInvoiceNumber?: string;
	icInvoiceNumber?: string;
	recordIds?: string[];
	invoiceTypeId?: number;
	searchCriteria?: ISearchCriteria;
	recordDateFrom?: string;
	recordDateTo?: string;
}
export const apiGetIcReconciliations = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		{
			data: IListingResponse<IApiGetIcReconciliationsReportsResponse>;
			totalUnSettledPolicies: number;
		},
		IApiGetIcReconciliationsReportsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.IcReconciliation.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetIcReconciliationsReportsResponse[];
				totalUnSettledPolicies: number;
			}>,
		) => {
			return {
				data: {
					data: response.data.report,
					totalItemsCount: response.data.paging.totalRecords,
				},
				totalUnSettledPolicies: response.data.totalUnSettledPolicies,
			};
		},
	});
};
