import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

type ApiUpdateCompanyRequest = {
	companyJsonEncrypted: string;
};

export const apiUpdateCompany = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, ApiUpdateCompanyRequest>({
		query: ({ companyJsonEncrypted }) => ({
			url: Endpoints.urls.company.update(companyJsonEncrypted),
			method: 'POST',
		}),
	});
};
