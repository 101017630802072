import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiUpdateMemberDetailsRequest {
	policyId?: string;
	fullNameEn?: string;
	emailAddress?: string;
	mobileNumber?: number;
	nationalityId?: number;
	memberDOB?: string;
	genderId?: number;
	passportNo?: string;
	passportIssueDate?: string;
	passportExpiryDate?: string;
	passportIssueCountryId?: number;
}

export const apiUpdateMemberDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiUpdateMemberDetailsRequest>({
		query: ({ policyId, ...body }) => ({
			method: 'POST',
			url: Endpoints.urls.admin.policy['update-member-details'](policyId ?? ''),
			body,
		}),
	});
};
