import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiAddTopupRequest {
	sourceBankId: number;
	transferDate: string;
	transferReferenceNumber: string;
	amount: number;
	documentReference: string;
	comment: string;
	shoryAccountId: number;
	encryptedDocumentIds: string[];
}

export const apiAddTopup = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiAddTopupRequest>({
		query: (request) => ({
			url: Endpoints.urls.wallet.topup,
			method: 'POST',
			body: request,
		}),
	});
};
