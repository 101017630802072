import { useCallback } from 'react';
import { RequestedPolicyStatuses } from '../../common/enum';
import { useAppSelector } from '../../redux-store/store';

export const useRequestedPolicyStatuses = () => {
	const requestedPolicyStatuses = useAppSelector(
		(state) => state.ui.appInitData?.requestedPolicyStatuses,
	);

	const getIsPolicyIssuedOrExpired = useCallback(
		(requestedPolicyStatusId: number) => {
			const status = requestedPolicyStatuses?.find((requestedPolicyStatus) => {
				return requestedPolicyStatus.id === requestedPolicyStatusId;
			})?.status;

			return (
				status === RequestedPolicyStatuses.Issued ||
				status === RequestedPolicyStatuses.Expired
			);
		},
		[requestedPolicyStatuses],
	);

	return { getIsPolicyIssuedOrExpired };
};
