import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiApproveRejectKycRequest {
	kycRequestId: number;
	status: number;
	actionComment?: string;
}
export const apiApproveRejectKyc = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiApproveRejectKycRequest>({
		query: ({ kycRequestId, status, actionComment }) => ({
			method: 'POST',
			url: Endpoints.urls.admin.kyc.approveReject(kycRequestId),
			body: { status, actionComment },
		}),
	});
};
