import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IKycDetails {
	encryptedAgencyId: string;
	userId: string;
	agencyName: string;
	documentReference: string;
	requestReferenceNo: string;
	statusId: number;
	actionComment?: string;
	channelTypeId: number;
	details: {
		regulatedInfo: {
			questionId: number;
			isFirmRegulated: boolean;
		};
		pepInfo: {
			questionId: number;
			hasPep: boolean;
			peps: {
				name: string;
				jobTitle: string;
				pepTypeId: number;
			}[];
		};
	};
	isActive: boolean;
	effectiveFrom: string;
	effectiveTo: string;
	createdOn: string;
	updatedOn: string;
	uploadedDocuments: IUploadedDocument[];
}

export interface IApiGetKycDetailsResponse {
	agencyName: string;
	kycDetails?: IKycDetails;
}

export interface IUploadedDocument {
	encryptedDocumentId: string;
	referenceNumber: string;
	documentType: number;
	cdnUrl: string;
	createdOn: string;
	updatedOn: string;
}

interface IApiGetKycDetailsRequest {
	agencyId: string;
}

export const apiGetKycDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<IApiGetKycDetailsResponse, IApiGetKycDetailsRequest>({
		query: ({ agencyId }: IApiGetKycDetailsRequest) => {
			const headers = new Headers();
			headers.set('companyId', agencyId.toUpperCase());

			return {
				url: Endpoints.urls.customerPortal.kyc,
				method: 'GET',
				headers,
			};
		},
	});
};
