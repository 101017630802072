import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useChannelTypes = () => {
	const channelTypes = useAppSelector(
		(state) => state.ui.appInitData?.channelTypes ?? [],
	);

	const getChannelById = useCallback(
		(channelId: number) => {
			return channelTypes.find((e) => e.id === channelId);
		},
		[channelTypes],
	);

	return { channelTypes, getChannelById };
};
