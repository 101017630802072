import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetWalletRequestLogRequest {
	agencyId: string;
}
export interface IApiGetWalletRequestLogResponse {
	requestDate: string;
	refNo: string;
	channel: string;
	agencyName: string;
	bank: string;
	transactionDate: string;
	transactionRefNo: string;
	approvedAmount: number;
	requestAmount: number;
	action: string;
	actionDate: string;
	actionBy: string;
	agencyId: string;
	comment?: string;
	rejectionReasonId: number;
}
export const apiGetWalletRequestsLogs = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetWalletRequestLogResponse[],
		IApiGetWalletRequestLogRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.admin.wallet.history(request.agencyId),
		}),
		transformResponse: (response: {
			records: IApiGetWalletRequestLogResponse[];
		}) => {
			return response.records;
		},
	});
};
