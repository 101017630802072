import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export interface IApiCreateJourneyResponse {
	redirectUrl: string;
}
export const apiCreateJourney = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiCreateJourneyResponse,
		{ companyId: string } | undefined
	>({
		query: (request) => ({
			headers: {
				companyId: request?.companyId,
			},
			url: Endpoints.urls.Quota.createJourney,
		}),
	});
};
