import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetPurchasedQuotaReportsResponse {
	quotaId: number;
	quotaNumber: string;
	partnerEmail: string;
	partnerName: string;
	partnerMobileNo: string;
	channelName: string;
	policyPeriod: string;
	memberCount: number;
	premiumPerMember: number;
	quotaPriceExclVAT: number;
	vatOnQuotaPrice: number;
	quotaPriceInclVAT: number;
	paymentMethod: string;
	quotaIssueDate: string;
	quotaEffectiveDate: string;
	quotaExpiryDate: string;
	quotaStatus: string;
	customerId: number;
	paymentReferenceNo: string;
	consumedPolicies: number;
	unConsumedPolicies: number;
}

export interface IApiGetPurchasedQuotaReportsRequest extends IPagingAndSorting {
	quotaNumber?: string;
	partnerName?: string;
	partnerEmail?: string;
	partnerMobile?: string;
	policyPeriodIds?: number[];
	paymentMethodIds?: number[];
	quotaIssueDateFrom?: string;
	quotaIssueDateTo?: string;
	quotaEffectiveDateFrom?: string;
	quotaEffectiveDateTo?: string;
	quotaExpiryDateFrom?: string;
	quotaExpiryDateTo?: string;
	quotaIds?: number[];
	documentType?: string;
	paymentReferenceNo?: string;
}
export const apiGetPurchasedQuota = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetPurchasedQuotaReportsResponse>,
		IApiGetPurchasedQuotaReportsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.issuedQuotas.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetPurchasedQuotaReportsResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
