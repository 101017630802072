import { useCallback, useState } from 'react';
import {
	IResponseError,
	IResponseErrors,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import { IApiGetAllPlansResponse } from '@visit-visa/api-visit-visa';
import { useTransformSelectedQuota } from '.';
import { setSelectedQuotaEN } from '../../../redux-store/quota-purchase.slice';
import { usePostUpdateJourneyMutation } from '../../../redux-store/rtkq-slice/endpoints';
import { useLazyGetAllQuotasQuery } from '../../../redux-store/rtkq-slice/endpoints';
import {
	selectAllQuotas,
	selectQuotaDetails,
	selectQuoteJourneyId,
} from '../../../selectors/quotaPurchase.selectors';
import { useAppDispatch, useAppSelector } from './../../../redux-store/store';
import useTransformData from './useTransformData';

const useProducts = () => {
	const appDispatch = useAppDispatch();
	const { selectedProductDetails } = useTransformSelectedQuota();
	const quotaFormDetails = useAppSelector(selectQuotaDetails);
	const journeyId = useAppSelector(selectQuoteJourneyId);
	const [selectedProductToView, setSelectedProductToView] =
		useState<IApiGetAllPlansResponse>();
	const [onPostUpdateJourney] = usePostUpdateJourneyMutation();
	const encryptedQuoteJourneyId = useAppSelector(selectQuoteJourneyId);
	const [getAllQuotas] = useLazyGetAllQuotasQuery();
	const { getErrorMessages } = useErrorsResponseTransform('quota.errors', true);
	const quotaDetails = useAppSelector(selectQuotaDetails);
	const quotas = useAppSelector(selectAllQuotas);
	const { getTransformedData } = useTransformData();
	const transformedQuotas = getTransformedData();

	const onSelectProduct = async (
		baseTarrifId: number,
		navigate: () => void,
		onGeneralErrors: (errors: IResponseError[]) => void,
	) => {
		const selectedQuota = transformedQuotas?.find(
			(quota: IApiGetAllPlansResponse) => quota.baseTarrifId === baseTarrifId,
		);

		if (selectedQuota) {
			try {
				await onPostUpdateJourney({
					encryptedQuoteJourneyId,
					baseTariffId: selectedQuota.baseTarrifId,
					visaPeriodId: quotaDetails?.visaType ?? 1,
					memberCount: quotaDetails?.memberCount ?? 0,
					quotaEffectiveDate: quotaDetails?.quotaEffectiveDate,
				}).unwrap();

				appDispatch(setSelectedQuotaEN(selectedQuota));
				navigate();
			} catch (e) {
				const errors = e as IResponseErrors;
				const mappedGeneralErrors = getErrorMessages(errors).filter(
					(e) => !e.field,
				);

				onGeneralErrors(mappedGeneralErrors);
			}
		}
	};

	const showProductModalHandler = (baseTarrifId: number) => {
		const selectedProduct = quotas?.find(
			(quota) => quota.baseTarrifId === baseTarrifId,
		);

		setSelectedProductToView(selectedProduct);
	};

	const onGetAllQuotas = useCallback(
		async (onGeneralErrors: (errors: IResponseError[]) => void) => {
			try {
				if (journeyId) await getAllQuotas(journeyId).unwrap();
			} catch (e) {
				const errors = e as IResponseErrors;
				const mappedGeneralErrors = getErrorMessages(errors).filter(
					(e) => !e.field,
				);

				onGeneralErrors(mappedGeneralErrors);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[journeyId],
	);

	return {
		quotas: transformedQuotas,
		selectedProductDetails,
		selectedProductToView,
		quotaDetails: {
			...selectedProductDetails,
			...quotaFormDetails,
		},
		showProductModalHandler,
		hideProductModalHandler: () => setSelectedProductToView(undefined),
		onSelectProduct,
		onGetAllQuotas,
	};
};

export default useProducts;
