import { Component, ErrorInfo, ReactNode } from 'react';
import { logger } from '@ninja/utilities';
import { Button } from '@ninja/web';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(): State {
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		logger.error(
			'[ErrorBoundary][componentDidCatch] Uncaught error',
			error,
			errorInfo,
		);
	}

	public render() {
		if (this.state.hasError) {
			return (
				<div className="flex h-full flex-col items-center justify-center gap-10">
					<h3 className="text-red-500">
						The request could not be completed at this moment, please try again
					</h3>
					<Button
						onClick={() => {
							window.location.href = '/';
						}}
					>
						Go To Home
					</Button>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
