import { PersistSliceConfig } from '@ninja/utilities';
import { createSlice } from '@reduxjs/toolkit';
import { IAppInitResponse } from '@visit-visa/api-visit-visa';
import { apisEndpoints } from './rtkq-slice/endpoints';

const initialState: {
	appInitData?: IAppInitResponse;
} = {};

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			apisEndpoints.getAppInit.matchFulfilled,
			(state, action) => {
				state.appInitData = {
					...action.payload,
				};
			},
		);
	},
});

export const ui_PersistConfig: PersistSliceConfig<typeof initialState> = {
	key: uiSlice.name,
	storageType: 'Session',
	baseReducer: uiSlice.reducer,
};
