import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetCustomerLogsRequest {
	customerId: number;
}

interface IApiGetCustomerLogsResponse {
	id: number;
	action: string;
	pageName: string;
	actionDate: string;
	actionFrom: string;
}

export const apiGetCustomerLogs = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetCustomerLogsResponse[],
		IApiGetCustomerLogsRequest
	>({
		//TODO: fix endpoint when it's ready
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		query: (request) => ({
			url: Endpoints.urls.admin.wallet.history('4rdoCmHO3Y3Fo6sY-uCOfQ'),
		}),
		//TODO: fix endpoint when it's ready
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		transformResponse: (response: {
			records: IBaseResponse<IApiGetCustomerLogsResponse[]>;
		}) => {
			//remove mock response and uncomment row 21 when api is ready
			// return response.records;
			return [
				{
					id: 1,
					action: 'Action 1',
					pageName: 'Page Name 1',
					actionDate: '2021-01-01T16:40:30.08Z',
					actionFrom: 'Action Performed From 1',
				},
				{
					id: 2,
					action: 'Action 2',
					pageName: 'Page Name 2',
					actionDate: '2022-02-02T16:40:30.08Z',
					actionFrom: 'Action Performed From 2',
				},
			];
		},
	});
};
