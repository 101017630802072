import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetQuotaCancellationRequestsRequest
	extends IPagingAndSorting {
	companyName?: string;
	requestReferenceNo?: string;
	quotaNumber?: string;
	customerEmail?: string;
	customerMobile?: string;
	requestStatustypeIds?: number[];
	requestDateFrom?: string;
	requestDateTo?: string;
	quotaCancellationIds?: number[];
}

export interface IApiGetQuotaCancellationRequestsResponse {
	id: number;
	requestReferenceNo: string;
	quotaNumber: string;
	requestDate: string;
	requesterEmail: string;
	requesterMobileNumber: string;
	companyName: string;
	quotaCount: number;
	consumedPolicies: number;
	isCancelledByICP: boolean;
	cancellationReason: string;
	requestStatus: string;
	bank: string;
	refundMethod: string;
	refundAmount: string;
	refundDate: string;
	rejectionReason: string;
	comment: string;
}

export const apiGetQuotaCancellationRequests = (
	builder: ApiEndpointBuilder,
) => {
	return builder.mutation<
		IListingResponse<IApiGetQuotaCancellationRequestsResponse>,
		IApiGetQuotaCancellationRequestsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin['quota-cancellation'].search,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetQuotaCancellationRequestsResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
