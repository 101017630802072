import { useMemo } from 'react';
import { DocumentTypes, KycQuestions } from '../../../common/enum';
import {
	useDocumentTypes,
	useKycQuestions,
	usePepTypesData,
} from '../../AppInitData';
import { FileData, TQuestionsName } from './types';

const useStaticData = () => {
	const { documentTypes } = useDocumentTypes();
	const { pepTypes } = usePepTypesData();
	const { kycQuestions } = useKycQuestions();

	const filesData: FileData[] = useMemo(() => {
		return [
			{
				label: `${
					documentTypes?.find((el) => el.id === DocumentTypes.Trade)?.label
				}`,
				id: DocumentTypes.Trade,
			},
			{
				label: `${
					documentTypes?.find((el) => el.id === DocumentTypes.Tax)?.label
				}`,
				id: DocumentTypes.Tax,
			},
			{
				label: `${
					documentTypes?.find((el) => el.id === DocumentTypes.Authorized)?.label
				}`,
				id: DocumentTypes.Authorized,
			},
		];
	}, [documentTypes]);

	const personTableOptions = useMemo(() => {
		return pepTypes?.map((el) => ({ id: el.id, name: el.pepType })) ?? [];
	}, [pepTypes]);

	const questionLabels: Record<TQuestionsName, string> = {
		firm: kycQuestions?.find((el) => el.id === KycQuestions.Firm)?.name ?? '',
		stakeholders:
			kycQuestions?.find((el) => el.id === KycQuestions.Stakeholders)?.name ??
			'',
	};

	const regulatedFileLabel = useMemo(() => {
		return (
			documentTypes?.find((el) => el.id === DocumentTypes.Regulatory)?.label ??
			''
		);
	}, [documentTypes]);

	return { filesData, personTableOptions, questionLabels, regulatedFileLabel };
};
export default useStaticData;
