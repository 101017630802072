import { ReactNode } from 'react';
import * as yup from 'yup';
import {
	CommonRegex,
	FormFieldsSchema,
	mapDropDown,
	mapNumberField,
	mapTextField,
} from '@ninja/utilities';
import { useAppPlatformContextOptions } from '../../../contexts/app';
import { useAppSelector } from '../../../redux-store/store';
import { selectQuotaDetailsConfigurableFields } from '../../../selectors/ui.selectors';
import { IQuotaDetailsFormValue } from './useQuotaDetails';

const useFormFieldsAndValidation = ({
	isAgencyNonEditable,
	establishmentTooltip,
}: {
	isAgencyNonEditable: boolean;
	establishmentTooltip?: ReactNode;
}) => {
	const { translate } = useAppPlatformContextOptions();
	const { memberCount, department, visaTypes } = useAppSelector(
		selectQuotaDetailsConfigurableFields,
	);
	const memberCountField = mapNumberField({
		field: 'memberCount',
		label: translate('quota-purchase.quota-details.member-count-label', {
			count: memberCount.min,
		}),
		placeholder: translate('quota-purchase.quota-details.member-count'),
		maxLength: memberCount.max.toString().length,
	});
	const visaTypeField = mapDropDown({
		field: 'visaType',
		label: translate('quota-purchase.quota-details.visa-type'),
		options: visaTypes,
		placeholder: translate('quota-purchase.quota-details.visa-type'),
		valueKey: 'id',
		labelKey: 'name',
	});

	const generateFields = isAgencyNonEditable
		? ([
				memberCountField,
				visaTypeField,
		  ] as FormFieldsSchema<IQuotaDetailsFormValue>)
		: ([
				mapTextField({
					field: 'agencyName',
					label: translate('quota-purchase.quota-details.agency-name'),
					placeholder: translate('quota-purchase.quota-details.agency-name'),
					maxLength: 300,
					regex: CommonRegex.alphaNumericEnglishWithSpecialCharacter,
				}),
				memberCountField,
				mapTextField({
					field: 'establishmentCardNumber',
					label: translate(
						'quota-purchase.quota-details.establishment-card-number',
					),
					placeholder: translate(
						'quota-purchase.quota-details.establishment-card-number',
					),
					popover: establishmentTooltip,
				}),
				visaTypeField,
				mapDropDown({
					field: 'department',
					label: translate('quota-purchase.quota-details.department'),
					options: department,
					placeholder: translate('quota-purchase.quota-details.department'),
					valueKey: 'id',
					labelKey: 'name',
				}),
		  ] as FormFieldsSchema<IQuotaDetailsFormValue>);

	const quotaDetailsValidation = {
		memberCount: yup
			.number()
			.min(
				memberCount.min,
				translate('quota-purchase.quota-details.min-memberCount-validation', {
					count: memberCount.min,
				}),
			)
			.max(
				memberCount.max,
				translate('quota-purchase.quota-details.max-memberCount-validation', {
					count: memberCount.max,
				}),
			)
			.required(
				translate('quota-purchase.quota-details.member-count-validation'),
			),
		visaType: yup
			.number()
			.required(translate('quota-purchase.quota-details.visa-type-validation')),
	};

	const agencyDetailsValidation = {
		agencyName: yup
			.string()
			.max(
				300,
				translate('quota-purchase.quota-details.agency-name-length-validation'),
			)
			.required(
				translate('quota-purchase.quota-details.agency-name-validation'),
			),
		establishmentCardNumber: yup
			.string()
			.required(
				translate('quota-purchase.quota-details.establishment-validation'),
			)
			.test(
				'establishment number format',
				translate(
					'quota-purchase.quota-details.establishment-format-validation',
				),
				(value) => (value ? /^\d{4,}\/\d{1,2}$/.test(value) : true),
			),
		department: yup
			.number()
			.required(
				translate('quota-purchase.quota-details.department-validation'),
			),
	};

	return {
		generateFields,
		quotaDetailsValidation,
		agencyDetailsValidation,
	};
};

export default useFormFieldsAndValidation;
