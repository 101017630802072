import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetCustomersResponse {
	email: string;
	customerName: string;
	mobileNumber: string;
	companiesCount: number;
	quotasCount: number;
	policiesCount: number;
	userType: string;
	channelName: string;
	registrationDate: string;
	accountStatus: string;
	customerId: number;
}

export interface IApiGetCustomersRequest extends IPagingAndSorting {
	customerName?: string;
	mobileNumber?: string;
	customerRoleId?: number;
	companyName?: string;
	channelId?: number;
	customerEmail?: string;
	registerDateFrom?: string;
	registerDateTo?: string;
	quotaNumber?: string;
	policyNumber?: string;
	customerIds?: number[];
	requestReferenceNos?: string[];
	companyId?: number;
}

export const apiGetCustomers = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetCustomersResponse>,
		IApiGetCustomersRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.customer.search,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{ report: IApiGetCustomersResponse[] }>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
