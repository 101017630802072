export enum ChannelTypes {
	Icp = 1,
	Website = 2,
	App = 3,
}
export enum DocumentTypes {
	Regulatory = 1,
	Trade = 2,
	Tax = 3,
	Authorized = 4,
	Wallet = 5,
}
export enum KycQuestions {
	Firm = 1,
	Stakeholders = 2,
}

export enum UserPermissionsToCompany {
	QuotaPurchase = 'QuotaPurchase',
	WalletTopUp = 'WalletTopUp',
	ViewWallet = 'ViewWallet',
	BusinessAdmin = 'BusinessAdmin',
}

export enum RequestedPolicyStatuses {
	PaymentPending = 'Payment Pending',
	Issued = 'Issued',
	PaymentFailed = 'Payment Failed',
	Expired = 'Expired',
}
