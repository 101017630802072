import { logger } from '@ninja/utilities';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { Endpoints } from '@visit-visa/api-customer-login';
import { resetUserData, setAccessToken } from '../persisted-settings.slice';

interface IWindow extends Window {
	refreshTokenRequest: Promise<
		| undefined
		| {
				accessToken: string;
				refreshToken: string;
		  }
	> | null;
	pendingCallsForRefreshToken: number;
}

declare let window: IWindow & typeof globalThis;

export const refreshAccessToken = async ({
	currentAccessToken,
	currentRefreshToken,
	dispatch,
}: {
	currentAccessToken: string;
	currentRefreshToken: string;
	dispatch: ThunkDispatch<
		typeof setAccessToken | typeof resetUserData,
		unknown,
		Action<unknown>
	>;
}) => {
	if (typeof window !== typeof undefined) {
		logger.info('[api.config][fetchBaseQueryWithRefreshToken] Unauthorized');

		if (currentAccessToken && currentRefreshToken) {
			window.pendingCallsForRefreshToken =
				(window.pendingCallsForRefreshToken ?? 0) + 1;

			if (!window.refreshTokenRequest) {
				logger.info(
					'[api.config][fetchBaseQueryWithRefreshToken] refreshing token in progress',
				);

				// define refresh token method
				const refreshTokenCall = async () => {
					const refreshTokenResponse = await fetch(
						Endpoints.urls.user.refreshToken,
						{
							method: 'POST',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								refreshToken: currentRefreshToken,
								token: currentAccessToken,
							}),
						},
					);

					if (refreshTokenResponse.ok) {
						logger.info(
							'[api.config][fetchBaseQueryWithRefreshToken] refreshTokenResponse.ok',
						);
						const refreshTokenResult = await refreshTokenResponse.json();

						dispatch(
							setAccessToken({
								accessToken: refreshTokenResult.data.token,
								refreshToken: refreshTokenResult.data.refreshToken,
							}),
						);

						return {
							accessToken: refreshTokenResult.data.token,
							refreshToken: refreshTokenResult.data.refreshToken,
						};
					}

					dispatch(resetUserData());

					return undefined;
				};

				// save define refresh token method promise
				window.refreshTokenRequest = refreshTokenCall();
			}

			const isRefreshed = await Promise.resolve(window.refreshTokenRequest);

			window.pendingCallsForRefreshToken--;

			if (window.pendingCallsForRefreshToken === 0) {
				window.refreshTokenRequest = null;
			}

			return isRefreshed;
		}
	}
};
