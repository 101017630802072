import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { IResponseError, useReactForm } from '@ninja/utilities';
import { DocumentTypes } from '../../../common/enum';
import { useAppPlatformContextOptions } from '../../../contexts/app';
import { setKycDetailsData } from '../../../redux-store/quota-purchase.slice';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import { selectKycDetails } from '../../../selectors/quotaPurchase.selectors';
import { IKycForm, TQuestions } from './types';
import useSubmitKycData from './useSubmitKycData';

export const useKycForm = (
	onGeneralErrors?: (errors: IResponseError[]) => void,
	onSuccess?: () => void,
) => {
	const KycDetails = useAppSelector(selectKycDetails);
	const { submitKycDataHandler } = useSubmitKycData();

	const initialValues: IKycForm = KycDetails ?? {
		persons: [],
		questions: {},
		declaration: false,
		files: {},
		refId: uuid(),
	};

	const { translate } = useAppPlatformContextOptions();
	const dispatch = useAppDispatch();
	const form = useReactForm<IKycForm>({
		initialValues,
		validation: yup.object({
			persons: yup
				.array()
				.when('questions', (questions: TQuestions[], schema) => {
					if (questions[0].stakeholders)
						return schema
							.of(
								yup.object({
									PEPName: yup
										.string()
										.required(
											translate(
												'quota-purchase.kyc.persons-table.errors.name',
											) ?? '',
										),
									JobTitle: yup
										.string()
										.required(
											translate(
												'quota-purchase.kyc.persons-table.errors.job',
											) ?? '',
										),
									PEPType: yup
										.number()
										.required(
											translate(
												'quota-purchase.kyc.persons-table.errors.type',
											) ?? '',
										),
								}),
							)
							.min(1);
					return schema;
				}),
			questions: yup.object({
				firm: yup.boolean().required(),
				stakeholders: yup.boolean().required(),
			}),
			declaration: yup
				.boolean()
				.required()
				.test('checkboxes', '', (values) => {
					if (!values) return false;
					return true;
				}),
			files: yup
				.object({
					[DocumentTypes.Regulatory]: yup.object({
						encryptedDocumentId: yup.string(),
						name: yup.string(),
					}),
					[DocumentTypes.Trade]: yup.object({
						encryptedDocumentId: yup.string(),
						name: yup.string(),
					}),
					[DocumentTypes.Tax]: yup.object({
						encryptedDocumentId: yup.string(),
						name: yup.string(),
					}),
					[DocumentTypes.Authorized]: yup.object({
						encryptedDocumentId: yup.string(),
						name: yup.string(),
					}),
				})
				.when('questions', (questions: TQuestions[], schema) => {
					if (questions[0].firm) {
						return schema.test('uploaded-files', '', (values) => {
							if (values && !values[DocumentTypes.Regulatory].name)
								return false;
							return true;
						});
					}
					return schema;
				}),
			refId: yup.string(),
		}) as any,
		async onFormSubmit(values) {
			const copiedValues = { ...values };
			const { questions, files, persons } = copiedValues;

			if (!questions.firm) files[DocumentTypes.Regulatory] = {};
			if (!questions.stakeholders) persons.length = 0;

			await submitKycDataHandler(copiedValues, onGeneralErrors);
			dispatch(setKycDetailsData(cloneDeep(copiedValues)));
			onSuccess?.();
		},
	});

	return { form };
};
