import { useAppSelector } from '../../redux-store/store';

export const usePaymentPublicKey = () => {
	const paymentPublicKeys = useAppSelector(
		(state) => state.ui.appInitData?.paymentConfig ?? [],
	);

	const paymentPublicKey = paymentPublicKeys.filter((payment) => {
		return payment.key === 'Payment:Checkout:PublicKey';
	})[0]?.value;

	return { paymentPublicKeys, paymentPublicKey };
};
