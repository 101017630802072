import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiApproveRejectWalletRequest {
	status?: number;
	comment?: string;
	receivedAmount?: number;
	rejectionReasonId?: number;
	walletId: number;
}

export const apiApproveRejectWallet = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiApproveRejectWalletRequest>({
		query: ({ walletId, ...remain }) => ({
			url: Endpoints.urls.admin.wallet.approveReject(walletId),
			method: 'POST',
			body: remain,
		}),
	});
};
