import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetCustomerUsersRequest {
	customerId: number;
}

export interface ICustomerUser {
	id: number;
	email: string;
	mobileNumber: string;
	customerName: string;
	accountStatus: string;
	companyId: number;
}

export interface ICustomerCompany {
	id: number;
	fullNameEn: string;
	emailAddress: string;
	mobileNumber?: string;
	cityId: number;
	licenseNo: string;
	establishmentNumber?: string;
}

export interface IApiGetCustomerUsersResponse {
	customers: ICustomerUser[];
	companies: ICustomerCompany[];
}

export const apiGetCustomerUsers = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiGetCustomerUsersResponse,
		IApiGetCustomerUsersRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.customer.users(request.customerId),
			body: request,
		}),
		transformResponse: (
			response: IBaseResponse<IApiGetCustomerUsersResponse>,
		) => response.data,
	});
};
