import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface apiGetDashboardStatisticsResponse {
	totalQuotas: number;
	totalIssuedPolicies: number;
	totalPolicyAmount: number;
}
export const apiGetDashboardStatistics = (builder: ApiEndpointBuilder) => {
	return builder.query<apiGetDashboardStatisticsResponse, void>({
		query: () => ({
			url: Endpoints.urls.customerPortal.getStatistics,
			method: 'GET',
		}),
	});
};
