import { IBaseResponse } from '@ninja/utilities';
import {
	ApiEndpointBuilder,
	convertNullToUndefined,
} from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IKycRequestLogFieldValues {
	id: number;
	requestReferenceNo: string;
	requestDate: string;
	channelName: string;
	agencyName: string;
	customerEmail: string;
	customerMobileNumber: number;
	amount: number;
	action: string;
	actionDate: string;
	actionBy: string;
	actionComment?: string;
}
export interface IAdminRegulatoryInformation {
	businessActivities?: string[];
	establishmentTypeId?: number;
	gdrfaCardNo?: string;
	licenseExpiryDate?: string;
	licenseIssueDate?: string;
	mohreCardNo?: string;
	regulatoryLicenseIssueDate?: string;
	regulatoryLicenseNumber?: string;
	taxTrnNumber?: string;
	yearsOfIncorporation?: number;
}
export interface IAgencyContactDetail {
	building?: string;
	designation?: string;
	email?: string;
	floorOffice?: string;
	mobileNumber?: string;
	poBox?: string;
	representativeName?: string;
	vatAdress?: string;
}
export interface IEditConfiguration {
	fullNameEn: boolean;
	fullNameAr: boolean;
	emailAddress: boolean;
	mobileNumber: boolean;
	category: boolean;
	cityId: boolean;
	licenseIssuingAuthority: boolean;
	licenseNo: boolean;
	establishmentNumber: boolean;
	licenseIssueDate: boolean;
	licenseExpiryDate: boolean;
	mohreCardNo: boolean;
	gdrfaCardNo: boolean;
	address: boolean;
	vatRegistrationNumber: boolean;
	companyName: boolean;
	tradeLicenseNumber: boolean;
	isRegulated: boolean;
	establishmentType: boolean;
	yearsOfIncorporation: boolean;
	regulatoryLicenseIssueDate: boolean;
	taxTrnNumber: boolean;
	representativeName: boolean;
	designation: boolean;
	building: boolean;
	floorOffice: boolean;
	poBox: boolean;
	vatAdress: boolean;
	businessActivities: boolean;
	BackToICPUrl: boolean;
}
export interface IUploadDocument {
	encryptedDocumentId: string;
	referenceNumber: string;
	documentType: number;
	cdnUrl: string;
	updatedOn?: string;
}
export interface IApiGetKycRequestDetailsResponse {
	kycRequestId: number;
	documentReference: string;
	kycRequestStatus: number;
	activityLogs?: IKycRequestLogFieldValues[];
	adminRegulatoryInformation?: IAdminRegulatoryInformation;
	agencyContactDetail?: IAgencyContactDetail;
	companyDetail?: {
		agencyId: string;
		emailAddress: string;
		cityId: number;
		fullNameEn: string;
		licenseNo: string;
		establishmentNumber: string;
		mobileNumber: number;
		editConfiguration: IEditConfiguration;
	};
	kycRequestDetail?: {
		pepInfo?: {
			questionId?: number;
			hasPep?: boolean;
			peps?: {
				name: string;
				jobTitle: string;
				pepTypeId: number;
			}[];
		};
		regulatedInfo?: {
			isFirmRegulated?: boolean;
			questionId?: number;
		};
	};
	uploadedDocuments?: IUploadDocument[];
}
interface IApiGetKycRequestDetailsRequest {
	kycRequestId: number;
}
export const apiGetKycRequestDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetKycRequestDetailsResponse,
		IApiGetKycRequestDetailsRequest
	>({
		query: (request) => Endpoints.urls.admin.kyc.details(request.kycRequestId),
		transformResponse: (
			response: IBaseResponse<IApiGetKycRequestDetailsResponse>,
		) => {
			return convertNullToUndefined(response.data);
		},
	});
};
