import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export interface IAuthorizeTokenRequest {
	code: string;
}

export interface IAuthorizeTokenResponse {
	token: string;
	refreshToken: string;
}

export const apiAuthorizeToken = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IAuthorizeTokenResponse, IAuthorizeTokenRequest>({
		query: ({ code }) => {
			return {
				url: Endpoints.urls.user.authorizeToken,
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: `"${code}"`,
			};
		},
		transformResponse: (response: IBaseResponse<IAuthorizeTokenResponse>) =>
			response.data,
	});
};
