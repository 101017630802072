import { getCookie } from 'cookies-next';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { createWrapper } from 'next-redux-wrapper';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import { useEffect } from 'react';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import {
	NinjaPersistorProvider,
	PersistorSuspense,
	SystemLocale,
	logger,
} from '@ninja/utilities';
import {
	setDateUtilsConfig,
	setDebugEnvironment,
	updateDateUtilsConfig,
} from '@ninja/config';
import { NinjaThemeProvider } from '@ninja/web';
import '@ninja/web/dist/tailwind/main.css';
import '@ninja/web/dist/tailwind/theme/default.css';
import {
	AppStore,
	makeStore,
	setCustomerLibConfig,
	useAppInitialization,
	useRefreshToken,
	AppPlatformContextProvider,
} from '@visit-visa/lib-customer';
import AuthorizationIFrame from '../components/AuthorizationIFrame';
import ErrorBoundary from '../components/ErrorBoundary';
import { AppConfig } from '../config/app-config';
import {
	ShoryNextNavigationProvider,
	ShoryPagePermissionGate,
	useWebNavigation,
} from '../contexts/navigation';
import useDeviceInfo from '../hooks/useDeviceInfo';
import useLayoutSetting from '../hooks/useLayoutSetting';

if (
	AppConfig.environment === 'production' ||
	AppConfig.environment === 'uat' ||
	AppConfig.environment === 'staging'
) {
	setDebugEnvironment('production');
} else if (AppConfig.environment === 'local') {
	setDebugEnvironment('local');
}

setDateUtilsConfig({
	isoFormat: 'utc',
	timezone: 'Asia/Dubai',
});

setCustomerLibConfig({
	isProduction: AppConfig.isProduction,
	accountsAPIUrl: AppConfig.accountsAPIUrl,
	visitVisaAPIUrl: AppConfig.visitVisaAPIUrl,
	productId: AppConfig.productId || '',
	getLocale: () => (getCookie('NEXT_LOCALE') ?? 'en') as SystemLocale,
});

const { store, persistor } = makeStore({
	local: localStorage,
	session: sessionStorage,
});

const wrapper = createWrapper<AppStore>(() => store);

const App = ({ Component, pageProps }: AppProps) => {
	const { getCurrentPage } = useWebNavigation();
	const { refreshIfRequired } = useRefreshToken();

	useEffect(() => {
		logger.log('[APP][useEffect][getCurrentPage, refreshIfRequired]');
		refreshIfRequired();
	}, [getCurrentPage, refreshIfRequired]);

	if (pageProps.isLanding) {
		return <Component {...pageProps} />;
	}

	return (
		<PersistorSuspense>
			<Component {...pageProps} />
		</PersistorSuspense>
	);
};

const AppRoot = (props: AppProps) => {
	const layoutSetting = useLayoutSetting();
	const [t, i18n] = useTranslation();

	useEffect(() => {
		updateDateUtilsConfig({ locale: (i18n.language as SystemLocale) ?? 'en' });
		const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

		document.querySelector('html')?.setAttribute('dir', dir);
	}, [i18n.language]);

	useAppInitialization(props.router.locale as SystemLocale);

	return (
		<>
			<Head>
				<title>{t('shared.product-name')}</title>
				<meta name="title" content={t('meta.title') ?? ''} />
				<meta name="description" content={t('meta.description') ?? ''} />

				<meta property="og:title" content={t('meta.title') ?? ''} />
				<meta property="og:description" content={t('meta.description') ?? ''} />
			</Head>
			<ErrorBoundary>
				<NextNProgress options={{ showSpinner: false }} />
				<AppPlatformContextProvider
					locale={props.router.locale as SystemLocale}
					translate={t}
					platform="web"
				>
					<NinjaPersistorProvider persistor={persistor}>
						<NinjaThemeProvider {...layoutSetting}>
							<ShoryNextNavigationProvider>
								<ShoryPagePermissionGate>
									<App {...props} />
								</ShoryPagePermissionGate>
							</ShoryNextNavigationProvider>
						</NinjaThemeProvider>
					</NinjaPersistorProvider>
				</AppPlatformContextProvider>
			</ErrorBoundary>

			{/* Handle Implicitly Authorization ... couple the website to the accounts website */}
			<AuthorizationIFrame />
		</>
	);
};

export default wrapper.withRedux(appWithTranslation(AppRoot));
