import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetIssuedPoliciesReportsResponse {
	policyId: number;
	requestReferenceNo: string;
	quotaNumber: string;
	policyReferenceNo: string;
	policyNumber: string;
	previousPolicyNumber: string;
	policyType: number;
	partnerName: string;
	groupPolicyNumber: string;
	channelType: string;
	memberNameEn: string;
	memberEmail: string;
	memberMobile: number;
	nationality: string;
	dateOfBirth: string;
	gender: number;
	passportNo: string;
	passportIssueDate: string;
	passportExpiryDate: string;
	issueCountry: string;
	visaPeriod: number;
	visaApprovalDate: string;
	policyIssueDate: string;
	insuranceCompanyName: string;
	policyPeriod: number;
	policyStatus: number;
	quotaStatus: string;
	policyAmountIncludingVAT: number;
	policyAmountExcludingVAT: number;
	vatOnPolicyAmount: number;
	isCanceled: string;
	cancelationDate: string;
	customerId: number;
	recordDate: string;
}

export interface IApiGetIssuedPoliciesReportsRequest extends IPagingAndSorting {
	policyIds?: number[];
	policyNumbers?: string[];
	requestReferenceNo?: string;
	policyNumber?: string;
	passportNumber?: string;
	partnerName?: string;
	memberName?: string;
	memberEmail?: string;
	memberMobile?: string;
	dateOfBirth?: string;
	nationalityId?: number;
	insuranceCompanyIds?: number[];
	channelId?: number;
	policyIssueDateFrom?: string;
	policyIssueDateTo?: string;
	passportIssueDateFrom?: string;
	passportIssueDateTo?: string;
	passportExpiryDateFrom?: string;
	passportExpiryDateTo?: string;
	policyPeriodIds?: number[];
	policyStatusIds?: number[];
	policyTypeId?: number;
	recordDateFrom?: string;
	recordDateTo?: string;
}
export const apiGetIssuedPolicies = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetIssuedPoliciesReportsResponse>,
		IApiGetIssuedPoliciesReportsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.issuedPolicies.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetIssuedPoliciesReportsResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
