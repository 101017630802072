import { groupBy, orderBy } from 'lodash';
import { IApiGetAllPlansResponse } from '@visit-visa/api-visit-visa';
import { LibConfig } from '../../../config';
import { useAppSelector } from '../../../redux-store/store';
import {
	BENEFIT_TYPE,
	IBenefit,
	IBenefitWithExtraData,
} from '../../../redux-store/types';
import { selectAllQuotas } from '../../../selectors/quotaPurchase.selectors';
import {
	useBenefitClasses,
	useBenefits,
	usePlansData,
} from '../../AppInitData';

interface groupedData {
	[key: number]: IBenefitWithExtraData[];
}

const useTransformData = () => {
	const allQuotas = useAppSelector(selectAllQuotas);
	const { benefits: appInitBenefits } = useBenefits();
	const { benefitClasses: appInitBenefitsClasses } = useBenefitClasses();
	const { plans: appInitPlans } = usePlansData();
	const locale = LibConfig.getLocale();
	const languageId = locale === 'ar' ? 2 : 1;
	const getPlanNameAndType = (planId: number) => {
		return appInitPlans?.find((el) => el.id === planId);
	};

	const onBenefitClassification = (benefits: IBenefit[]) => {
		const keyBenefits: IBenefitWithExtraData[] = [];
		const otherBenefits: IBenefitWithExtraData[] = [];
		const detailsBenefits: IBenefitWithExtraData[] = [];

		for (let i = 0; i < benefits.length; i++) {
			const el = benefits[i];
			if (el.languageType !== languageId) {
				continue;
			}
			const summaryLabel = appInitBenefits?.find(
				(item) => item.id === el.benefitId,
			)?.summaryLabel;

			const currentClass = appInitBenefitsClasses?.find(
				(item) => item.id === el.benefitClassId,
			);

			const item = {
				...el,
				summaryLabel,
				detailedViewLabel: currentClass?.detailedViewLabel,
				benefitClassSequence: currentClass?.detailedViewSequence,
			};

			if (item.detailedViewSequence !== 0) {
				detailsBenefits.push(item);
			}

			if (el.summarySequence !== 0) {
				if (el.benefitType === BENEFIT_TYPE.KEY_BENEFITS) {
					keyBenefits.push(item);
				} else if (el.benefitType === BENEFIT_TYPE.OTHER_BENEFITS) {
					otherBenefits.push(item);
				}
			}
		}

		return { keyBenefits, otherBenefits, detailsBenefits };
	};

	const getTransformedData = (quotas?: IApiGetAllPlansResponse[]) => {
		const quotasDetails = quotas ?? allQuotas;
		return quotasDetails?.map((el) => {
			const planNameAndType = getPlanNameAndType(el.planId);
			const allBenefits = onBenefitClassification(el.benefits);

			const keyBenefits = orderBy(
				allBenefits.keyBenefits,
				['summarySequence'],
				['asc'],
			);
			const otherBenefits = orderBy(
				allBenefits.otherBenefits,
				['summarySequence'],
				['asc'],
			);
			const groupedData = groupBy(
				allBenefits.detailsBenefits,
				'benefitClassId',
			);

			const sortBasedOnBenefitDetailedViewSequence: groupedData = Object.keys(
				groupedData ?? {},
			).reduce((acc, item) => {
				return {
					...acc,
					[item]: orderBy(groupedData[item], ['detailedViewSequence'], ['asc']),
				};
			}, {});

			const sortBasedOnBenefitClassSequence = Object.values(
				sortBasedOnBenefitDetailedViewSequence,
			).sort((a, b) =>
				(a[0]?.benefitClassSequence ?? 0) > (b[0]?.benefitClassSequence ?? 0)
					? 1
					: -1,
			);

			return {
				...el,
				planName: planNameAndType?.name ?? '',
				planType: planNameAndType?.logoTitle ?? '',
				planTypeColor: planNameAndType?.logoTitleColor ?? '',
				planeTypeBgColor: planNameAndType?.logoBgColor ?? '',
				keyBenefits,
				otherBenefits,
				groupedBasedOnBenefitClassId: sortBasedOnBenefitClassSequence,
			};
		});
	};

	return { getTransformedData };
};

export default useTransformData;
