var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4LgxmawSPhwEUetldzg44"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/visit-visa-insurance";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
const Sentry = require('@sentry/nextjs');
const { environment, isProd, sentryDSN } = require('./sentry.env');

Sentry.init({
	dsn: sentryDSN,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: isProd ? 0.1 : 1.0,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		// To enable logs from the source file not instruments.js
		new Sentry.Integrations.Breadcrumbs({ console: false }),
	],

	environment: environment,

	// Initial data to populate scope.
	initialScope: {
		website: 'agency-portal',
	},

	// Any data beyond this depth will be trimmed and marked using its type instead ([Object] or [Array]),
	// without walking the tree any further. By default, walking is performed three levels deep.
	normalizeDepth: 5,
});
