import { useMemo } from 'react';
import { setIsClearable } from '../../../redux-store/quota-purchase.slice';
import { useAppDispatch, useAppSelector } from '../../../redux-store/store';
import {
	selectCompanyDetails,
	selectIsAgencyDetailExists,
	selectIsClearable,
	selectIsICP,
} from '../../../selectors/quotaPurchase.selectors';
import { useCityData } from '../../AppInitData';

export const useQuotaPurchase = () => {
	const companyDetails = useAppSelector(selectCompanyDetails);
	const isAgencyNonEditable = useAppSelector(selectIsAgencyDetailExists);
	const isICP = useAppSelector(selectIsICP);
	const isClearable = useAppSelector(selectIsClearable);
	const { getCityById } = useCityData();
	const appDispatch = useAppDispatch();

	const companyDetailsData = useMemo(() => {
		if (!companyDetails) return companyDetails;

		const cityName = getCityById(companyDetails.cityId)?.name ?? '';

		return {
			cityName,
			establishmentNumber: companyDetails.establishmentNumber ?? '',
			companyName: companyDetails.fullNameEn,
			kycRequired: companyDetails.kycRequired,
		};
	}, [companyDetails, getCityById]);

	const onSetIsClearable = () => {
		appDispatch(setIsClearable(true));
	};

	return {
		companyDetailsData,
		isAgencyNonEditable,
		isICP,
		isClearable,
		onSetIsClearable,
	};
};
