import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export interface IAddCompany {
	companyJsonEncrypted: string;
}

export interface IAddCompanyResponse {
	id: string;
	name?: string;
	emiratesId: number;
	tradeLicense: string;
}

export const apiAddCompany = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IAddCompanyResponse, IAddCompany>({
		query: ({ companyJsonEncrypted }) => {
			return {
				url: Endpoints.urls.company.add(companyJsonEncrypted),
				body: {},
				method: 'POST',
			};
		},
	});
};
