import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

type IApiUpdateGuidRequest = {
	journeyId: string;
	companyGuid: string;
};
export const apiUpdateGuid = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiUpdateGuidRequest>({
		query: ({ journeyId, companyGuid }) => {
			return {
				url: Endpoints.urls.agency.updateGuid(journeyId, companyGuid),
				method: 'POST',
			};
		},
	});
};
