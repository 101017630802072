import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useVatConfigurations = () => {
	const vatConfigurations = useAppSelector(
		(state) => state.ui.appInitData?.vatConfigurations ?? [],
	);

	const getVatConfigById = useCallback(
		(vatId: number) => {
			return vatConfigurations.find((e) => e.id === vatId);
		},
		[vatConfigurations],
	);

	return { vatConfigurations, getVatConfigById };
};
