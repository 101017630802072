import { PersistSliceConfig } from '@ninja/utilities';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserDetailsResponse } from '@visit-visa/api-customer-login';
import { readTokenExpiry } from '@visit-visa/shared-utilities';

const initialState: {
	accessToken?: string;
	refreshToken?: string;
	accessTokenExpiry?: string;
	userDetails?: IUserDetailsResponse;
} = {};

export const persistedSettings = createSlice({
	name: 'persisted-settings',
	initialState,
	reducers: {
		setAccessToken: (
			state,
			action: PayloadAction<{ accessToken: string; refreshToken: string }>,
		) => {
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.accessTokenExpiry = readTokenExpiry(action.payload.accessToken);
		},
		resetUserData: (state) => {
			state.accessToken = undefined;
			state.refreshToken = undefined;
			state.accessTokenExpiry = undefined;
			state.userDetails = undefined;
		},
		setUserInfo: (state, action: PayloadAction<IUserDetailsResponse>) => {
			state.userDetails = action.payload;
		},
	},
});

export const { setAccessToken, resetUserData, setUserInfo } =
	persistedSettings.actions;

export const persistedSettings_PersistConfig: PersistSliceConfig<
	typeof initialState
> = {
	key: persistedSettings.name,
	storageType: 'Local',
	baseReducer: persistedSettings.reducer,
};
