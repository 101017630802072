import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiWalletPaymentRequest {
	quoteJourneyId: string;
	payment: {
		amountWithoutVat: number;
		vatAmount: number;
		currency: string;
	};
}

interface IApiWalletPaymentResponse {
	amount: number;
}

export const apiWalletPayment = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IApiWalletPaymentResponse, IApiWalletPaymentRequest>({
		query: (request) => ({
			url: Endpoints.urls.wallet.payment,
			method: 'POST',
			body: request,
		}),
		transformResponse: (response: IBaseResponse<IApiWalletPaymentResponse>) => {
			return response.data;
		},
	});
};
