import jwtDecode from 'jwt-decode';
import { isBeforeNow, parseIsoDateTime } from '@ninja/utilities';
import { AppState } from '../redux-store/store';
import { ICompany, IJWTToken } from '../redux-store/types';

export const selectIsLoggedIn = (state: AppState) => {
	return (
		!!state.persistedSettings.userDetails &&
		!!state.persistedSettings.accessToken
	);
};

export const selectJWTTokenData = (state: AppState) => {
	let data: IJWTToken | undefined = undefined;
	let companies: ICompany[] | undefined = undefined;

	if (state.persistedSettings?.accessToken) {
		data = jwtDecode(state.persistedSettings.accessToken);
		companies = JSON.parse(data?.Companies ?? '');
	}

	const isCompanyExist = (id: string) =>
		companies?.some((company) => company.Id === id) ?? false;

	return { data, companies, isCompanyExist };
};

export const selectCurrentUserDetails = (state: AppState) =>
	state.persistedSettings.userDetails;

export const selectRequireRefresh = (state: AppState) => {
	const expiryDate = parseIsoDateTime(
		state.persistedSettings.accessTokenExpiry,
	);

	return (
		expiryDate &&
		state.persistedSettings.accessToken &&
		state.persistedSettings.refreshToken &&
		isBeforeNow(expiryDate)
	);
};

export const selectPermissionsForCompany =
	(state: AppState) => (agencyId: string) => {
		return state.persistedSettings.userDetails?.companies.find(
			(el) => el.id.toLowerCase() === agencyId.toLowerCase(),
		)?.permissions;
	};
