import { formatDate, parseIsoDateTime } from '@ninja/utilities';
import { useAppPlatformContextOptions } from '../../../contexts/app';
import { selectIsLoggedIn } from '../../../selectors/persisted-settings.selectors';
import { selectQuotaDetails } from '../../../selectors/quotaPurchase.selectors';
import { selectQuotaDetailsConfigurableFields } from '../../../selectors/ui.selectors';
import { useTransformSelectedQuota } from '../select-product';
import { useAppSelector } from './../../../redux-store/store';

const useSummary = () => {
	const { translate } = useAppPlatformContextOptions();
	const { selectedProductDetails } = useTransformSelectedQuota();
	const quotaFormDetails = useAppSelector(selectQuotaDetails);
	const userLoggedIn = useAppSelector(selectIsLoggedIn);
	const { visaTypes } = useAppSelector(selectQuotaDetailsConfigurableFields);

	return {
		selectedProductDetails,
		quotaSummary: [
			{
				id: 1,
				title: translate(
					'quota-purchase.select-product.quota-classes.premium-per-amount',
				),
				description: `${translate('shared.currency.AED', {
					price: selectedProductDetails?.perMemberVatExclusiveAmount ?? '0',
				})} `,
			},
			{
				id: 2,
				title: translate(
					'quota-purchase.select-product.quota-classes.memberCount',
				),
				description: quotaFormDetails?.memberCount.toString() ?? '0',
			},
			{
				id: 3,
				title: translate(
					'quota-purchase.select-product.quota-classes.visaType',
				),
				description:
					visaTypes?.find((type) => type.id === quotaFormDetails?.visaType)
						?.name ?? '',
			},
			{
				id: 4,
				title: translate(
					'quota-purchase.select-product.quota-classes.quotaEffectiveDate',
				),
				description: formatDate(
					parseIsoDateTime(quotaFormDetails?.quotaEffectiveDate),
				),
			},
		],
		userLoggedIn,
	};
};

export default useSummary;
