import {
	resetUserData,
	setAccessToken,
	setUserInfo,
} from '../../redux-store/persisted-settings.slice';
import {
	useGetTokenMutation,
	useGetUserDetailsMutation,
} from '../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch } from '../../redux-store/store';

export const useSSO = () => {
	const appDispatch = useAppDispatch();
	const [callGetToken] = useGetTokenMutation();
	const [callGetUserDetails] = useGetUserDetailsMutation();

	const resetUserDetails = () => {
		appDispatch(resetUserData());
	};

	const processToken = async (code: string) => {
		const { token, refreshToken } = await callGetToken({ code }).unwrap();

		appDispatch(
			setAccessToken({
				accessToken: token,
				refreshToken,
			}),
		);

		const userDetails = await callGetUserDetails().unwrap();

		appDispatch(setUserInfo(userDetails));
	};

	return { resetUserDetails, processToken };
};
