import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useInsuranceCompanies = () => {
	const insuranceCompanies = useAppSelector(
		(state) => state.ui.appInitData?.insuranceCompanies ?? [],
	);

	const getInsuranceCompanyById = useCallback(
		(companyId: number) => {
			return insuranceCompanies.find((e) => e.id === companyId);
		},
		[insuranceCompanies],
	);

	return { insuranceCompanies, getInsuranceCompanyById };
};
