import { IBaseResponse } from '@ninja/utilities';
import {
	ApiEndpointBuilder,
	convertNullToUndefined,
} from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiGetCustomerDetailsResponse {
	basicDetails: {
		id?: number;
		name?: string;
		email?: string;
		mobileNumber?: string;
		countryCode?: number;
		accountStatus?: number;
		accountCode?: number;
		preferredLanguage?: number;
		userTypeId: number;
	};
	loginDetails: {
		registeredOn?: string;
		lastLogin?: string;
		registeredThrough?: string;
		lastPurchasedQuota?: string;
		lastIssuedPolicy?: string;
	};
	businessDetails: {
		companiesCount?: number;
		issuedPoliciesCount?: number;
		purchasedQuotaCount?: number;
		lastQuotaPurchasedOn?: string;
		lastPolicyIssuedOn?: string;
	};
	activityLogs: {
		id?: number;
		customerId?: number;
		actionName?: string;
		actionOn?: string;
		actionDate?: string;
		pageName?: string;
		ipAddress?: string;
		browser?: string;
		deviceId?: string;
		platform?: string;
		osVersion?: string;
		createdOn?: string;
	}[];
}

export interface IApiGetCustomerDetailsRequest {
	customerId: number;
}

export const apiGetCustomerDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<
		IApiGetCustomerDetailsResponse,
		IApiGetCustomerDetailsRequest
	>({
		query: (request) =>
			Endpoints.urls.admin.customer.details(request.customerId),
		transformResponse: (
			response: IBaseResponse<IApiGetCustomerDetailsResponse>,
		) => {
			return convertNullToUndefined(response.data);
		},
	});
};
