import { AppConfig } from './app-config';
import { AppPermissionLevels } from './app-permission-levels';

export interface IRoutesParamsTypes {
	Landing: undefined;
	QuotaPurchaseLanding: undefined;
	404: undefined;
	SSO_Login: { redirect_uri: string; code: string };
	SSO_Logout: undefined;
	QuotaPurchase: undefined;
	ICP: undefined;
	NonICP: undefined;
	QuotaDetails: undefined;
	PaymentOptions: undefined;
	QuotaIssuance: undefined;
	QuotaSummary: undefined;
	MockUps: undefined;
	QuotaPurchaseMockup: undefined;
	PolicyPurchaseMockUps: undefined;
	WalletTopUp: undefined;
	TermsAndConditions: undefined;
	PrivacyPolicy: undefined;
	Pay: { encryptedRequestedPolicyId: string };
	PolicyIssuance: { encryptedRequestedPolicyId: string };
	Dashboard: undefined;
	AccountSettings: undefined;
	Authorize: undefined;
	IframeAuthorize: { client_id: string };
	AccountLogoutUser: undefined;
}

type TRoutesPermissions = {
	[key in keyof IRoutesParamsTypes]: {
		path: string;
		permissions: AppPermissionLevels[];
		parentRoute?: keyof IRoutesParamsTypes;
	};
};

export const routesPermissions: TRoutesPermissions = {
	Landing: {
		path: '/',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	404: {
		path: '/404',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	SSO_Login: {
		path: '/sso/login',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	SSO_Logout: {
		path: '/sso/logout',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	QuotaPurchaseLanding: {
		path: '/visit-visa-insurance',
		permissions: [AppPermissionLevels.AnonymousUser],
		parentRoute: 'QuotaPurchase',
	},
	QuotaPurchase: {
		path: '/visit-visa-insurance/quota-purchase',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	ICP: {
		path: '/visit-visa-insurance/quota-purchase/icp',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	NonICP: {
		path: '/visit-visa-insurance/quota-purchase/non-icp',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	PaymentOptions: {
		path: '/visit-visa-insurance/quota-purchase/payment-options',
		permissions: [AppPermissionLevels.AllLoggedInUser],
		parentRoute: 'QuotaPurchase',
	},
	QuotaDetails: {
		path: '/visit-visa-insurance/quota-purchase/quota-details',
		permissions: [AppPermissionLevels.AnonymousUser],
		parentRoute: 'QuotaPurchase',
	},
	QuotaIssuance: {
		path: '/visit-visa-insurance/quota-purchase/quota-issuance',
		permissions: [AppPermissionLevels.AnonymousUser],
		parentRoute: 'QuotaPurchase',
	},
	MockUps: {
		path: '/visit-visa-insurance/mockups',
		permissions: AppConfig.isProduction
			? []
			: [AppPermissionLevels.AnonymousUser],
	},
	QuotaPurchaseMockup: {
		path: '/visit-visa-insurance/mockups/quota-purchase',
		permissions: AppConfig.isProduction
			? []
			: [AppPermissionLevels.AnonymousUser],
	},
	PolicyPurchaseMockUps: {
		path: '/visit-visa-insurance/mockups/purchase-policy',
		permissions: AppConfig.isProduction
			? []
			: [AppPermissionLevels.AnonymousUser],
	},
	QuotaSummary: {
		path: '/visit-visa-insurance/quota-purchase/summary',
		permissions: [AppPermissionLevels.AnonymousUser],
		parentRoute: 'QuotaPurchase',
	},
	WalletTopUp: {
		path: '/visit-visa-insurance/wallet/top-up',
		permissions: [AppPermissionLevels.AllLoggedInUser],
	},
	TermsAndConditions: {
		path: '/visit-visa-insurance/terms-and-conditions',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	PrivacyPolicy: {
		path: '/visit-visa-insurance/privacy-policy',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	Pay: {
		path: '/visit-visa-insurance/pay/[encryptedRequestedPolicyId]',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	PolicyIssuance: {
		path: '/visit-visa-insurance/pay/policy-issuance',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	Dashboard: {
		path: '/account',
		permissions: [AppPermissionLevels.AllLoggedInUser],
	},
	AccountSettings: {
		path: '/account/account-settings',
		permissions: [AppPermissionLevels.AllLoggedInUser],
	},
	Authorize: {
		path: '/account/sso/authorize',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	IframeAuthorize: {
		path: '/account/sso/iframe-authorize',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
	AccountLogoutUser: {
		path: '/account/sso/logout',
		permissions: [AppPermissionLevels.AnonymousUser],
	},
};
