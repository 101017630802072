import {
	apiAddCompany,
	apiAuthorizeToken,
	apiUpdateCompany,
	apiUserDetails,
} from '@visit-visa/api-customer-login';
import {
	apiAddTopup,
	apiAuthorizePayment,
	apiAuthorizePaymentLink,
	apiCapturePayment,
	apiCapturePaymentLink,
	apiCreateJourney,
	apiGetAllPlans,
	apiGetCompanyDetails,
	apiGetIssuedPolicyByPaymentLinkDetails,
	apiGetIssuedQuotaDetails,
	apiGetWalletBalance,
	apiNonIcpCreateJourney,
	apiPaymentLink,
	apiSubmitKycData,
	apiUpdateGuid,
	apiUpdateJourney,
	apiUpdateNonIcpJourney,
	apiWalletPayment,
	appInitQuery,
} from '@visit-visa/api-visit-visa';
import { apiConfig } from './api-config';

export const endpointsConfig = apiConfig.injectEndpoints({
	endpoints: (builder) => ({
		getAppInit: appInitQuery(builder),
		getCompanyDetails: apiGetCompanyDetails(builder),
		getAllQuotas: apiGetAllPlans(builder),
		postUpdateJourney: apiUpdateJourney(builder),
		postCreateNonIcpJourney: apiNonIcpCreateJourney(builder),
		postUpdateNonIcpJourney: apiUpdateNonIcpJourney(builder),
		postSubmitKycData: apiSubmitKycData(builder),
		getIssuedQuotaDetails: apiGetIssuedQuotaDetails(builder),
		postAuthorizePayment: apiAuthorizePayment(builder),
		postCapturePayment: apiCapturePayment(builder),
		getWalletBalance: apiGetWalletBalance(builder),
		postWalletPayment: apiWalletPayment(builder),
		postWalletTopup: apiAddTopup(builder),
		getToken: apiAuthorizeToken(builder),
		getUserDetails: apiUserDetails(builder),
		getCompanyDetailsByEncryptedId: apiAddCompany(builder),
		updateGuid: apiUpdateGuid(builder),
		getCreateJourney: apiCreateJourney(builder),
		getPaymentLinkDetails: apiPaymentLink(builder),
		postAuthorizePaymentLink: apiAuthorizePaymentLink(builder),
		postCapturePaymentLink: apiCapturePaymentLink(builder),
		getIssuedPolicyByPaymentLinkDetails:
			apiGetIssuedPolicyByPaymentLinkDetails(builder),
		postUpdateCompany: apiUpdateCompany(builder),
	}),
	overrideExisting: false,
});

export const {
	useLazyGetAppInitQuery,
	endpoints: apisEndpoints,
	useGetCompanyDetailsMutation,
	useLazyGetAllQuotasQuery,
	usePostSubmitKycDataMutation,
	usePostUpdateJourneyMutation,
	usePostUpdateNonIcpJourneyMutation,
	usePostCreateNonIcpJourneyMutation,
	usePostAuthorizePaymentMutation,
	usePostCapturePaymentMutation,
	useLazyGetIssuedQuotaDetailsQuery,
	useGetWalletBalanceMutation,
	usePostWalletPaymentMutation,
	usePostWalletTopupMutation,
	useGetTokenMutation,
	useGetUserDetailsMutation,
	useGetCompanyDetailsByEncryptedIdMutation,
	useUpdateGuidMutation,
	useGetCreateJourneyMutation,
	useLazyGetPaymentLinkDetailsQuery,
	usePostAuthorizePaymentLinkMutation,
	usePostCapturePaymentLinkMutation,
	useLazyGetIssuedPolicyByPaymentLinkDetailsQuery,
	usePostUpdateCompanyMutation,
} = endpointsConfig;
