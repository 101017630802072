import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useBenefitClasses = () => {
	const benefitClasses = useAppSelector(
		(state) => state.ui.appInitData?.benefitClasses ?? [],
	);

	const getBenefitClassById = useCallback(
		(benefitId: number) => {
			return benefitClasses.find((e) => e.id === benefitId);
		},
		[benefitClasses],
	);

	return { benefitClasses, getBenefitClassById };
};
