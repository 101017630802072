export const AppConfig = {
	environment: process.env.ENVIRONMENT ?? 'local',
	isProduction: process.env.ENVIRONMENT === 'production',
	productId: process.env.PUBLIC_PRODUCT_ID,
	visitVisaAPIUrl: process.env.PUBLIC_API_URL ?? '',
	accountsAPIUrl: process.env.PUBLIC_ACCOUNTS_API_URL ?? '',

	maxFileSize: 8,
	acceptFiles: {
		'image/png': ['.png'],
		'image/jpg': ['.jpg'],
		'image/jpeg': ['.jpeg'],
		'text/pdf': ['.pdf'],
	},
	paymentTokenizeTimeoutInMilliseconds: 20000,
	omniChannelConfig: (lang: 'ar' | 'en') => ({
		appId:
			(lang === 'en'
				? process.env.PUBLIC_OMNICHANNEL_ID_EN
				: process.env.PUBLIC_OMNICHANNEL_ID_AR) ?? '',
		orgId: process.env.PUBLIC_OMNICHANNEL_ORG_ID ?? '',
		orgUrl: process.env.PUBLIC_OMNICHANNEL_ORG_URL ?? '',
	}),
};
