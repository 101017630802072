import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export interface IApiGetCompanyDetailsResponse {
	agencyId: string;
	agencyInfoEncrypted: string;
	cityId: number;
	establishmentNumber: string;
	fullNameAr: string;
	fullNameEn: string;
	kycRequired: boolean;
	tradeLicenseNumber: string;
}
export const apiGetCompanyDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IApiGetCompanyDetailsResponse, string>({
		query: (journeyId: string) =>
			Endpoints.urls.agency.getCompanyByJourneyId(journeyId),
	});
};
