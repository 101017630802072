import { NinjaNextNavigationFactory } from '@ninja/nextjs';
import { useUserInfo } from '@visit-visa/lib-customer';
import PageError from '../components/PageError';
import { AppPermissionLevels } from '../config/app-permission-levels';
import {
	IRoutesParamsTypes,
	routesPermissions,
} from '../config/routes-and-permissions';
import useRedirectToLogin from '../hooks/useRedirectToLogin';
import NextLink from 'next/link';

const {
	useWebNavigation,
	Provider,
	PagePermissionGate: ShoryPagePermissionGate,
	useCheckForPermission,
} = new NinjaNextNavigationFactory<AppPermissionLevels, IRoutesParamsTypes>({
	NextLink,
	routesPermissions,
	basePermissionLevels: {
		allLoggedInUser: AppPermissionLevels.AllLoggedInUser,
		anonymousUser: AppPermissionLevels.AnonymousUser,
		superAdmin: AppPermissionLevels.NotSupported,
		notLoggedInUser: AppPermissionLevels.NotLoggedInUser,
	},
});

const ShoryNextNavigationProvider = ({
	children,
}: {
	children: JSX.Element;
}) => {
	const { userLoggedIn, isCompanyExist } = useUserInfo();
	const { onRedirectToLogin } = useRedirectToLogin();

	return (
		<Provider
			currentUserActions={[]}
			isLoggedInUser={userLoggedIn && isCompanyExist}
			noAccessComponent={<PageError />}
			onRedirectToLogin={onRedirectToLogin}
		>
			{children}
		</Provider>
	);
};

export {
	useWebNavigation,
	ShoryPagePermissionGate,
	useCheckForPermission,
	ShoryNextNavigationProvider,
};
