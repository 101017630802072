import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetServiceDetailsRequest {
	apiNameId: string;
	searchTimeline?: string;
	searchDateFrom?: string;
	searchDateTo?: string;
	apiInitiatorId: string;
}
export interface IApiGetServiceDetailsResponse {
	errorCode: string;
	errorMessage: string;
	frequency: number;
}
export const apiGetServiceDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiGetServiceDetailsResponse[],
		IApiGetServiceDetailsRequest
	>({
		query: ({ apiNameId, ...body }) => ({
			method: 'POST',
			url: Endpoints.urls.admin.dashboard.servicesSystemCheckDetails(apiNameId),
			body: body,
		}),
		transformResponse: (response: {
			serviceSystemErrorDetails: IApiGetServiceDetailsResponse[];
		}) => {
			return response.serviceSystemErrorDetails;
		},
	});
};
