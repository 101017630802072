import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface ApiGetPaymentGatewayRequest extends IPagingAndSorting {
	transactionDateFrom?: string;
	transactionDateTo?: string;
	shoryTransactionRefNo?: string;
	paymentGatewayRefNo?: string;
	paymentGatewayId?: number;
	acquirerID?: string;
	policyNumber?: string;
	quotaNumber?: string;
	cardTypeId?: number;
	paymentPricingTypeId?: number;
	transactionTypeIds?: number[];
	customerInvoiceNumber?: string;
	actualPaidAmountInclVATFrom?: number;
	actualPaidAmountInclVATTo?: number;
	refundedAmountInclVATFrom?: number;
	refundedAmountInclVATTo?: number;
	isPaid?: boolean;
	paymentIds?: number[];
}

export type ApiGetPaymentGatewayResponse = {
	acquirerID: string;
	actualPaidAmountExclVAT: number;
	actualPaidAmountInclVAT: number;
	authorizationFeesExclVAT: number;
	authorizationFeesInclVAT: number;
	cardFeesAmountExclVAT: number;
	cardFeesAmountInclVAT: number;
	cardFeesPercentage: number;
	cardType: string;
	customerInvoiceNum: string;
	gatewayName: string;
	invoiceUrl: string;
	isPaidOut: boolean;
	paymentGatewayRefNo: string;
	paymentId: number;
	paymentMethod: string;
	paymentPricingType: string;
	payoutDate: string;
	payoutReferenceNumber: string;
	policyId: number;
	policyNumber: string;
	quotaId: number;
	quotaNumber: string;
	refundFeesExclVAT: number;
	refundFeesInclVAT: number;
	refundedAmountExclVAT: number;
	refundedAmountInclVAT: number;
	shoryTransactionNo: string;
	totalFeesAmountExclVAT: number;
	totalFeesAmountInclVAT: number;
	transactionDate: string;
	transactionType: string;
	vatAmountOnActualPaidAmount: number;
	vatAmountOnAuthorizationFees: number;
	vatAmountOnCardFeesAmount: number;
	vatAmountOnRefundFees: number;
	vatAmountOnRefundedAmount: number;
	vatAmountOnTotalFeesAmount: number;
	vatAmountOnVoidFees: number;
	vatPercentageOnActualPaidAmount: number;
	vatPercentageOnAuthorizationFees: number;
	vatPercentageOnCardFeesAmount: number;
	vatPercentageOnRefundFees: number;
	vatPercentageOnRefundedAmount: number;
	vatPercentageOnTotalFeesAmount: number;
	vatPercentageOnVoidFees: number;
	voidFeesExclVAT: number;
	voidFeesInclVAT: number;
};
export const apiGetPaymentGateway = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		{
			data: IListingResponse<ApiGetPaymentGatewayResponse>;
			totalUnPaidPayment: number;
		},
		ApiGetPaymentGatewayRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.paymentGateway.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: ApiGetPaymentGatewayResponse[];
				totalUnPaidPayment: number;
			}>,
		) => {
			return {
				data: {
					data: response.data.report,
					totalItemsCount: response.data.paging.totalRecords,
				},
				totalUnPaidPayment: response.data.totalUnPaidPayment,
			};
		},
	});
};
