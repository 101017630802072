const getEndpoints = (apiUrl: string) => ({
	app: {
		init: `${apiUrl}/app/init`,
	},
	report: {
		issuedPolicies: {
			export: `${apiUrl}/report/issued-policies/export`,
			list: `${apiUrl}/report/issued-policies`,
			downloadBulkCertificate: `${apiUrl}/report/issued-policies/downloadBulkCertificate`,
		},
		IcReconciliation: {
			list: `${apiUrl}/report/ic-recon`,
			export: `${apiUrl}/report/ic-recon/export`,
			downloadBulkInvoice: `${apiUrl}/report/ic-recon/downloadBulkInvoices`,
			markAsSettled: `${apiUrl}/report/ic-recon/markAsSettled`,
		},
		IcpReconciliation: {
			list: `${apiUrl}/report/icp-recon`,
			export: `${apiUrl}/report/icp-recon/export`,
			downloadBulkInvoice: `${apiUrl}/report/icp-recon/downloadBulkInvoices`,
			markAsSettled: `${apiUrl}/report/icp-recon/markAsSettled`,
		},
		apiError: {
			list: `${apiUrl}/report/api-error`,
			details: `${apiUrl}/report/api-error/details`,
			export: `${apiUrl}/report/api-error/export`,
		},
		issuedQuotas: {
			list: `${apiUrl}/report/issued-quotas`,
			export: `${apiUrl}/report/issued-quotas/export`,
			downloadDocuments: `${apiUrl}/report/issued-quotas/downloadDocuments`,
		},
		walletUtilization: {
			list: `${apiUrl}/report/wallet-utilization`,
			export: `${apiUrl}/report/wallet-utilization/export`,
		},
		paymentGateway: {
			list: `${apiUrl}/report/payment-gateway-recon`,
			export: `${apiUrl}/report/payment-gateway-recon/export`,
			markAsPaid: `${apiUrl}/report/payment-gateway-recon/mark-as-paid`,
		},
	},
	admin: {
		customer: {
			search: `${apiUrl}/admin/customer/search`,
			details: (customerId: number) => `${apiUrl}/admin/customer/${customerId}`,
			export: `${apiUrl}/admin/customer/export`,
			quotas: (customerId: number) =>
				`${apiUrl}/admin/customer/${customerId}/quotas`,
			companies: (customerId: number) =>
				`${apiUrl}/admin/customer/${customerId}/companies`,
			users: (customerId: number) =>
				`${apiUrl}/admin/customer/${customerId}/users`,
			wallet: (customerId: number) =>
				`${apiUrl}/admin/customer/${customerId}/wallet`,
			downloadWalletReceipts: (customerId: number) =>
				`${apiUrl}/admin/customer/${customerId}/wallet/downloadReceipts`,
		},
		app: {
			init: `${apiUrl}/admin/app/init`,
		},
		policy: {
			details: (policyId: number) => `${apiUrl}/admin/policy/${policyId} `,
			'update-member-details': (policyId: string) =>
				`${apiUrl}/admin/policy/${policyId}/update`,
			'search-update': `${apiUrl}/admin/policy/search-update`,
			sendPolicyEmail: (policyId: number) =>
				`${apiUrl}/admin/policy/${policyId}/send-policy-email`,
		},
		'requested-policy': {
			search: `${apiUrl}/admin/requested-policy/search`,
			export: `${apiUrl}/admin/requested-policy/export`,
			submit: `${apiUrl}/admin/requested-policy/submit`,
		},
		kyc: {
			search: `${apiUrl}/admin/kyc/search`,
			details: (requestId: number) => `${apiUrl}/admin/kyc/${requestId} `,
			approveReject: (requestId: number) =>
				`${apiUrl}/admin/kyc/${requestId}/approveRejectKyc`,
			requestNewAttachment: (kycRequestId: number) =>
				`${apiUrl}/admin/kyc/${kycRequestId}/requestNewAttachment`,
			upload: `${apiUrl}/file/upload`,
			updateRegulatory: (kycRequestId: number) =>
				`${apiUrl}/admin/kyc/${kycRequestId}/updateRegulatory`,
			updateCompany: (kycRequestId: number) =>
				`${apiUrl}/admin/kyc/${kycRequestId}/updateCompany`,
		},
		wallet: {
			search: `${apiUrl}/admin/wallet/search`,
			details: (walletId: number) => `${apiUrl}/admin/wallet/${walletId}`,
			approveReject: (walletId: number) =>
				`${apiUrl}/admin/wallet/${walletId}/approveRejectWallet`,
			history: (agencyId: string) =>
				`${apiUrl}/admin/wallethistory/${agencyId}`,
		},
		dashboard: {
			healthSystemCheck: `${apiUrl}/admin/dashboard/healthSystemCheck`,
			servicesSystemCheck: `${apiUrl}/admin/dashboard/servicesSystemCheck`,
			servicesSystemCheckDetails: (apiNameId: string) =>
				`${apiUrl}/admin/dashboard/servicesSystemCheck/${apiNameId}`,
		},
		quota: {
			details: (quotaNumber: string) => `${apiUrl}/admin/quota/${quotaNumber}`,
			policies: `${apiUrl}/admin/quota/policies`,
			downloadPolicyDocument: `${apiUrl}/admin/quota/downloadPolicyDocument`,
			exportPolicies: `${apiUrl}/admin/quota/exportPolicies`,
			cancelQuota: `${apiUrl}/admin/quota/cancel`,
		},
		'quota-cancellation': {
			search: `${apiUrl}/admin/quota-cancellation/search`,
			details: (quotaCancellationId: number) =>
				`${apiUrl}/admin/quota-cancellation/${quotaCancellationId}`,
			updateStatus: (quotaCancellationId: number) =>
				`${apiUrl}/admin/quota-cancellation/${quotaCancellationId}/updateStatus`,
			logs: (quotaCancellationId: number) =>
				`${apiUrl}/admin/quota-cancellation/${quotaCancellationId}/logs`,
			export: `${apiUrl}/admin/quota-cancellation/export`,
			editRefund: (quotaCancellationId: number) =>
				`${apiUrl}/admin/quota-cancellation/${quotaCancellationId}/edit/refund`,
		},
	},
	agency: {
		getCompanyByJourneyId: (journeyId: string) =>
			`${apiUrl}/agency/getByQuoteJourneyId?encryptedQuoteJourneyId=${journeyId}`,
		agencies: `${apiUrl}/customer-portal/dashboard/agencies`,
		updateGuid: (journeyId: string, guid: string) =>
			`${apiUrl}/agency/updateGuid?encryptedQuoteJourneyId=${journeyId}&guid=${guid}`,
	},
	document: {
		upload: `${apiUrl}/file/upload`,
	},
	Quota: {
		updateJourney: `${apiUrl}/Quota/updateJourney`,
		getPlans: (journeyId: string) =>
			`${apiUrl}/Quota/getPlans?encryptedQuoteJourneyId=${journeyId}`,
		getIssuedQuotaDetails: (quotaJourneyId: string) =>
			`${apiUrl}/Quota/getIssuedQuotaDetails?encryptedQuoteJourneyId=${quotaJourneyId}`,
		createJourney: `${apiUrl}/Quota/createJourney`,
		nonIcpCreateJourney: `${apiUrl}/nonIcp/createJourney`,
		updateNonIcpJourney: `${apiUrl}/nonIcp/updateJourney`,
	},
	kyc: {
		submit: `${apiUrl}/kyc/submit`,
	},
	payment: {
		authorize: `${apiUrl}/payment/authorize`,
		capture: `${apiUrl}/payment/capture`,
		paymentLink: (encryptedRequestedPolicyId: string) =>
			`${apiUrl}/payment-link/policy/${encryptedRequestedPolicyId}`,
		authorizePaymentLink: `${apiUrl}/payment/authorize/payment-link`,
		capturePaymentLink: `${apiUrl}/payment/capture/payment-link`,
	},
	wallet: {
		topup: `${apiUrl}/wallet/topup`,
		getBalance: `${apiUrl}/wallet`,
		payment: `${apiUrl}/payment/wallet`,
	},
	customerPortal: {
		quotas: `${apiUrl}/customer-portal/dashboard/quotas`,
		quotaDetails: (quotaNumber: string) =>
			`${apiUrl}/customer-portal/dashboard/quotaDetails?quotaNumber=${quotaNumber}`,
		kyc: `${apiUrl}/customer-portal/dashboard/kyc`,
		getStatistics: `${apiUrl}/customer-portal/dashboard/getStatistics`,
		policies: `${apiUrl}/customer-portal/dashboard/policies`,
		walletRecentTopUp: `${apiUrl}/customer-portal/dashboard/recentWalletTopUp`,
		walletRecentTransactions: `${apiUrl}/customer-portal/dashboard/recentWalletTransaction`,
		downloadKycDocs: `${apiUrl}/file/download`,
		downloadPolicyCertificates: `${apiUrl}/customer-portal/dashboard/downloadPolicyCertificates`,
		downloadWalletTransactions: `${apiUrl}/customer-portal/dashboard/downloadTransactions`,
	},
	paymentLink: {
		getIssuedPolicyByPaymentLinkDetails: (encryptedRequestedPolicyId: string) =>
			`${apiUrl}/payment-link/policy/requested-policy/${encryptedRequestedPolicyId}`,
	},
});

export const Endpoints = { urls: getEndpoints('') };

/**
 * configuration utility meant to be set once for different frameworks like Website and Mobile App
 */
export const setVisitVisaAPIsConfig = (identityAPIUrl: string) => {
	Endpoints.urls = getEndpoints(identityAPIUrl ?? '');
};
