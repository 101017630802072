import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export type IApiGetAllPlansResponse = {
	planId: number;
	baseTarrifId: number;
	perMemberVatExclusiveAmount: number;
	perMemberVatAmount: number;
	totalVatExclusiveAmount: number;
	totalVatAmount: number;
	benefits: {
		benefitId: number;
		summaryDescription: string;
		summaryToolTipText: string;
		summarySequence: number;
		detailedViewSequence: number;
		detailedViewDescription: string;
		benefitClassId: number;
		benefitType: number;
		languageType?: number;
	}[];
	exclusions: string[];
	arExclusions: string[];
};
export const apiGetAllPlans = (builder: ApiEndpointBuilder) => {
	return builder.query<IApiGetAllPlansResponse[], string | undefined>({
		query: (journeyId: string) => Endpoints.urls.Quota.getPlans(journeyId),
	});
};
