import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useBenefits = () => {
	const benefits = useAppSelector(
		(state) => state.ui.appInitData?.benefits ?? [],
	);

	const getBenefitById = useCallback(
		(benefitId: number) => {
			return benefits.find((e) => e.id === benefitId);
		},
		[benefits],
	);

	return { benefits, getBenefitById };
};
