const getEndpoints = (apiUrl: string) => ({
	app: {
		init: `${apiUrl}/app/init`,
		permissions: (productId: number) =>
			`${apiUrl}/app/permissions?productId=${productId}`,
	},
	user: {
		signUp: `${apiUrl}/user/signup`,
		signUpConfirm: `${apiUrl}/user/signup/confirm`,
		signUpWithInvitationToken: (invitationToken: string) =>
			`${apiUrl}/user/Invitation-detail/${invitationToken}`,
		login: `${apiUrl}/user/login`,
		loginGenerateOtp: `${apiUrl}/user/generate-login-otp`,
		loginWithOtp: `${apiUrl}/user/login-by-Otp`,
		details: `${apiUrl}/user/detail`,
		status: (
			userId: string,
			companyId: string,
			productId: string,
		) => `${apiUrl}/user/${userId}/company/${companyId}/product/${productId}/status
		`,
		authorize: (productId: string) =>
			`${apiUrl}/user/authorize?productId=${productId}`,
		authorizeToken: `${apiUrl}/user/authorize/token`,
		otpGenerate: `${apiUrl}/Otp/generate`,
		password: {
			forgot: `${apiUrl}/user/password/forgot`,
			change: `${apiUrl}/user/password/change`,
			reset: `${apiUrl}/user/password/reset`,
		},
		edit: `${apiUrl}/user/edit`,
		refreshToken: `${apiUrl}/user/token/refresh`,
		invite: `${apiUrl}/user/Invite`,
		remove: `${apiUrl}/user/remove`,
		editAgencyUser: `${apiUrl}/user/edit-agency-user`,
		userList: `${apiUrl}/user/user-list`,
		manageUserAccess: `${apiUrl}/user/manage-user-access`,
	},
	company: {
		add: (companyJsonEncrypted: string) =>
			`${apiUrl}/company/add?companyJsonEncrypted=${companyJsonEncrypted}`,
		linkUser: `${apiUrl}/company/link-user`,
		update: (companyJsonEncrypted: string) =>
			`${apiUrl}/company/update?companyJsonEncrypted=${companyJsonEncrypted}`,
	},
});

export const Endpoints = { urls: getEndpoints('') };

/**
 * configuration utility meant to be set once for different frameworks like Website and Mobile App
 */
export const setCustomerLoginAPIsConfig = (identityAPIUrl: string) => {
	Endpoints.urls = getEndpoints(identityAPIUrl ?? '');
};
