import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config/endpoints';
import {
	WalletRequestType,
	WalletTransactionStatus,
} from '../../../config/enums';
import { IListingResponse } from '../../common/types';

export interface IApiGetRecentWalletTransactionRequest
	extends IPagingAndSorting {
	requestType?: number | undefined;
	requestDate?: Date | undefined;
	requestStatus?: number | undefined;
	searchText?: string;
}
export interface IWalletTransaction {
	requestType: WalletRequestType;
	requesterEmail: string;
	requestStatus: WalletTransactionStatus;
	transactionDate: Date;
	amount: number;
	balance: number;
	requestReferenceNo: string;
	transferReferenceNumber: string;
	requestedAmount: number;
	requestDate: Date;
	downloadUrl: string;
	rejectionReason: string;
}
interface IApiGetRecentWalletTransactionResponse {
	walletTransactions: IWalletTransaction[];
	paging: {
		totalRecords: number;
	};
}
export const apiGetRecentWalletTransaction = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IWalletTransaction>,
		IApiGetRecentWalletTransactionRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.customerPortal.walletRecentTransactions,
			method: 'POST',
			body: request,
		}),
		transformResponse: (response: IApiGetRecentWalletTransactionResponse) => {
			return {
				data: response.walletTransactions,
				totalItemsCount: response.paging.totalRecords,
			};
		},
	});
};
