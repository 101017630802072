import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetCustomerQuotasResponse {
	id: number;
	quotaNumber: string;
	agencyName: string;
	establishmentCardNumber: string;
	tradeLicenceNumber: string;
	cityId: number;
	visaPeriodId: number;
	memberCount: string;
	paymentMethodId: number;
	issueDate: string;
	effectiveDate: string;
	expiryDate: string;
	amount: number;
	issuedPoliciesCount: number;
	receiptFilePath: string;
	certificatePath: string;
	quotaStatus: string;
}
export interface IApiGetCustomerQuotasRequest {
	customerId: number;
	quotaNumber?: string;
	agencyName?: string;
	establishmentCardNumber?: string;
	tradeLicenceNumber?: string;
	periodIds?: number[];
}
export const apiGetCustomerQuotas = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetCustomerQuotasResponse>,
		IApiGetCustomerQuotasRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.customer.quotas(request.customerId),
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetCustomerQuotasResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
