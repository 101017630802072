import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { IApiGetIcpReconciliationsReportsRequest } from '../..';
import { Endpoints } from '../../../config';
import { IMarkAsSettledRequest } from '../../common/types';

type IApiMarkAsSettledIcReconciliationsRequest = IMarkAsSettledRequest<
	Partial<Omit<IApiGetIcpReconciliationsReportsRequest, 'paging'>>
>;
export const apiMarkAsSettledIcpReconciliations = (
	builder: ApiEndpointBuilder,
) => {
	return builder.mutation<void, IApiMarkAsSettledIcReconciliationsRequest>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.IcpReconciliation.markAsSettled,
			body: request,
		}),
	});
};
