import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

type ApiGetIssuedQuotaDetailsResponse = {
	id: number;
	quotaNumber: string;
	agencyName: string;
	establishmentCardNumber: string;
	tradeLicenceNumber: string;
	cityId: number;
	visaPeriodId: number;
	issuedPoliciesCount: number;
	memberCount: number;
	paymentMethodId: number;
	issueDate: string;
	effectiveDate: string;
	expiryDate: string;
	amount: number;
	receiptFilePath: string;
	certificatePath: string;
	customerId: number;
	quotaStatusId: number;
	adminCancellationstatusId: number;
};
type ApiGetIssuedQuotaDetailsRequest = {
	quotaNumber: string;
};

export const apiGetIssuedQuotaDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<
		ApiGetIssuedQuotaDetailsResponse,
		ApiGetIssuedQuotaDetailsRequest
	>({
		query: (request) => ({
			method: 'GET',
			url: Endpoints.urls.admin.quota.details(request.quotaNumber),
		}),
		transformResponse: (
			response: IBaseResponse<ApiGetIssuedQuotaDetailsResponse>,
		) => response.data,
	});
};
