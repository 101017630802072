import { AppState } from '../redux-store/store';

export const selectCompanyDetails = (state: AppState) => {
	return state.quotaPurchase.company;
};
export const selectSelectedQuotaEN = (state: AppState) => {
	return state.quotaPurchase.selectedQuotaEN;
};
export const selectSelectedQuotaAR = (state: AppState) => {
	return state.quotaPurchase.selectedQuotaAR;
};
export const selectQuotaDetails = (state: AppState) => {
	return state.quotaPurchase.quotaDetails;
};

export const selectKycDetails = (state: AppState) => {
	return state.quotaPurchase.KycDetails;
};

export const selectQuoteJourneyId = (state: AppState) => {
	return state.quotaPurchase.quoteJourneyId;
};

export const selectPaymentErrors = (state: AppState) => {
	return state.quotaPurchase.paymentErrors;
};

export const selectIdentityCompanyId = (state: AppState) => {
	return state.quotaPurchase.identityCompanyId;
};

export const selectAllQuotas = (state: AppState) => {
	return state.quotaPurchase.quotas;
};

export const selectCurrentStep = (state: AppState) => {
	return state.quotaPurchase.currentStep;
};
export const selectPaymentMethod = (state: AppState) => {
	return state.quotaPurchase.paymentMethod;
};

export const selectIsAgencyDetailExists = (state: AppState) => {
	return state.quotaPurchase.isAgencyNonEditable;
};

export const selectIsICP = (state: AppState) => {
	return state.quotaPurchase.isICP;
};
export const selectIsClearable = (state: AppState) => {
	return state.quotaPurchase.isClearable;
};
