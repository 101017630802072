import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetKycRequestsResponse {
	kycRequestId: number;
	requestDate: string;
	requestReferenceNo: string;
	channelName: string;
	agencyName: string;
	customerEmail: string;
	customerMobileNumber?: string;
	statusName: string;
}

export interface IApiGetKycRequestsRequest extends IPagingAndSorting {
	agencyCompanyName?: string;
	requestReferenceNo?: string;
	customerEmail?: string;
	customerMobileNumber?: string;
	statuses?: number[];
	requestDateFrom?: string;
	requestDateTo?: string;
	statusType?: number;
}
export const apiGetKycRequests = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetKycRequestsResponse>,
		IApiGetKycRequestsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.kyc.search,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{ report: IApiGetKycRequestsResponse[] }>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
