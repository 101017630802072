import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	IFormSchema,
	IResponseError,
	logger,
	mapDatePicker,
	mapDropDown,
	mapTextField,
	useErrorsResponseTransform,
	useReactForm,
} from '@ninja/utilities';
import { APIResponseError } from '@ninja/utilities';
import { useAppPlatformContextOptions } from '../../contexts/app';
import { usePostWalletTopupMutation } from '../../redux-store/rtkq-slice/endpoints';
import { selectShoryBankDetails } from '../../selectors/ui.selectors';
import { useUaeBank, useWalletTopupMinAmount } from '../AppInitData';

const fieldsValidations = {
	MAX_TRANSFER_REFERENCE_NUMBER: 50,
};

interface IUseWalletTopupProps {
	onTopupSuccess: () => void;
	onError: (errors: IResponseError[]) => void;
	documentReferenceId: string;
}

interface IWalletTopupForm {
	bankId?: number;
	transferReferenceNumber?: string;
	transferDate?: string;
	amount?: number;
	acceptTerms?: boolean;
	fileEncryptedDocumentId?: string;
}

const useWalletTopup = ({
	onTopupSuccess,
	onError,
	documentReferenceId,
}: IUseWalletTopupProps) => {
	const { translate } = useAppPlatformContextOptions();
	const { getAscendingSortedBanks } = useUaeBank();
	const shoryBankDetails = useSelector(selectShoryBankDetails);
	const [postWalletTopup] = usePostWalletTopupMutation();
	const [isLoading, setIsLoading] = useState(false);
	const { getErrorMessages } = useErrorsResponseTransform();
	const { walletTopUpMinAmount } = useWalletTopupMinAmount();

	const schema: IFormSchema<IWalletTopupForm> = {
		fields: [
			mapDropDown({
				field: 'bankId',
				isEditable: true,
				label: translate('wallet.top-up.your-bank'),
				options: getAscendingSortedBanks(),
				placeholder: translate('wallet.top-up.your-bank-placeholder'),
				isHidden: false,
				valueKey: 'id',
				labelKey: 'name',
				disabled: false,
				isSearchable: true,
				noOptionAvailableText: '',
			}),
			mapDatePicker({
				field: 'transferDate',
				label: translate('wallet.top-up.transfer-date'),
				placeholder: translate('wallet.top-up.transfer-date'),
				toDate: new Date(),
			}),
			mapTextField({
				field: 'transferReferenceNumber',
				label: translate('wallet.top-up.transfer-reference-number'),
				maxLength: fieldsValidations.MAX_TRANSFER_REFERENCE_NUMBER,
				placeholder: translate('wallet.top-up.transfer-reference-number'),
			}),
			mapTextField({
				field: 'amount',
				label: translate('wallet.top-up.amount-label', {
					amount: walletTopUpMinAmount,
				}),
				placeholder: translate('wallet.top-up.amount-placeholder'),
				// a number with at most 6 digits and up to two decimal places
				regex: /^\d{0,6}(\.\d{0,2})?$/,
			}),
		],
		validation: yup.object().shape({
			bankId: yup
				.number()
				.required(translate('wallet.top-up.validations.select-bank-name')),
			transferReferenceNumber: yup
				.string()
				.max(
					fieldsValidations.MAX_TRANSFER_REFERENCE_NUMBER,
					translate(
						'wallet.top-up.validations.transfer-reference-number.max-number',
						{
							count: fieldsValidations.MAX_TRANSFER_REFERENCE_NUMBER,
						},
					),
				)
				.required(
					translate('wallet.top-up.validations.transfer-reference-number'),
				),
			transferDate: yup
				.date()
				.required(translate('wallet.top-up.validations.transfer-date')),
			amount: yup
				.number()
				.min(
					walletTopUpMinAmount,
					translate('wallet.top-up.validations.amount', {
						amount: walletTopUpMinAmount,
					}),
				)
				.required(translate('wallet.top-up.validations.valid-amount')),

			acceptTerms: yup
				.boolean()
				.required(translate('wallet.top-up.validations.accept-terms'))
				.oneOf([true], translate('wallet.top-up.validations.accept-terms')),
			fileEncryptedDocumentId: yup
				.string()
				.required(translate('wallet.top-up.validations.upload-attachment')),
		}) as any,
		initialValues: {
			fileEncryptedDocumentId: undefined,
			acceptTerms: false,
		},
	};

	const form = useReactForm<IWalletTopupForm>({
		initialValues: schema.initialValues,
		validation: schema.validation,
		onFormSubmit: async (values) => {
			setIsLoading(true);
			try {
				await postWalletTopup({
					amount: values.amount ?? 0,
					comment: '',
					documentReference: documentReferenceId,
					shoryAccountId: shoryBankDetails?.id ?? 1,
					sourceBankId: values.bankId ?? 1,
					transferDate: values.transferDate ?? ' ',
					transferReferenceNumber: values.transferReferenceNumber ?? ' ',
					encryptedDocumentIds: [
						form.getValues('fileEncryptedDocumentId') ?? '',
					],
				}).unwrap();
				setIsLoading(false);
				onTopupSuccess();
			} catch (error) {
				setIsLoading(false);
				const errors = error as APIResponseError;
				onError(getErrorMessages(errors));
				logger.error(errors);
			}
		},
	});

	const onUploadFile = (_fileName: string, fileEncryptedDocumentId: string) => {
		form.setFieldValue('fileEncryptedDocumentId', fileEncryptedDocumentId);
	};

	const onDeleteFile = () => {
		form.setFieldValue('fileEncryptedDocumentId', '');
	};

	const onCheckboxValueChanged = (value: boolean) => {
		form.setFieldValue('acceptTerms', value);
	};

	return {
		shoryBankDetails,
		form,
		schema,
		onUploadFile,
		onDeleteFile,
		onCheckboxValueChanged,
		isLoading,
	};
};

export default useWalletTopup;
