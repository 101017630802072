import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const usePepTypesData = () => {
	const pepTypes = useAppSelector(
		(state) => state.ui.appInitData?.pepTypes ?? [],
	);

	const getPepTypeById = useCallback(
		(pepId: number) => {
			return pepTypes.find((e) => e.id === pepId);
		},
		[pepTypes],
	);

	return { pepTypes, getPepTypeById };
};
