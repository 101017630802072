import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetIcpReconciliationsReportsRequest
	extends IPagingAndSorting {
	policyIds?: number[];
	policyNumbers?: string[];
	requestReferenceNo?: string;
	policyNumber?: string;
	passportNumber?: string;
	memberName?: string;
	icpPaymentReferenceNumber?: string;
	settlementReferenceNumber?: string;
	insuranceCompanyIds?: number[];
	policyPeriodIds?: number[];
	policyStatusIds?: number[];
	settlementStatus?: number;
	settlementStatusIds?: number[];
	policyIssueDateFrom?: string;
	policyIssueDateTo?: string;
	settlementDateFrom?: string;
	settlementDateTo?: string;
	cancellationDateFrom?: string;
	cancellationDateTo?: string;
	policyTypeIds?: number[];
	recordIds?: string[];
	recordDateFrom?: string;
	recordDateTo?: string;
	customerInvoiceNumber?: string;
}
export interface IApiGetIcpReconciliationsReportsResponse {
	id: string;
	rowNum: number;
	policyId: number;
	dueToIcpExclVAT?: number;
	dueToIcpInclVAT?: number;
	dueToShoryExclVAT?: number;
	dueToShoryInclVAT?: number;
	icpFeesExclVAT?: number;
	icpFeesInclVAT?: number;
	icpPaymentReferenceNo?: string;
	icpSettlementDate?: string;
	icpSettlementDueDate?: string;
	icpSettlementReferenceNo?: string;
	icpSettlementStatus?: string;
	icSettlementStatus?: string;
	insuranceCompanyName?: string;
	memberName?: string;
	passportNumber?: string;
	policyAmountExcludingVAT?: number;
	policyAmountIncludingVAT?: number;
	policyIssueDate?: string;
	policyNumber?: string;
	policyPeriod?: string;
	policyStatus?: number;
	policyType?: string;
	requestReferenceNo?: string;
	vatAmount?: number;
	vatAmountOnIcpFees?: number;
	vatOnShoryCommission?: number;
	vatPercentage?: number;
	visaApprovedDate?: string;
	isCanceled: boolean;
	cancelationDate: string;
	vatOnDueToIcp: number;
	icSettlementDueDate: string;
	icSettlementDate: string;
	recordDate: string;
	customerInvoiceNumber: string;
	customerInvoiceUrl: string;
}
export const apiGetIcpReconciliations = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		{
			data: IListingResponse<IApiGetIcpReconciliationsReportsResponse>;
			totalUnSettledPolicies: number;
		},
		IApiGetIcpReconciliationsReportsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.IcpReconciliation.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetIcpReconciliationsReportsResponse[];
				totalUnSettledPolicies: number;
			}>,
		) => {
			return {
				data: {
					data: response.data.report,
					totalItemsCount: response.data.paging.totalRecords,
				},
				totalUnSettledPolicies: response.data.totalUnSettledPolicies,
			};
		},
	});
};
