import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const usePlansData = () => {
	const plans = useAppSelector((state) => state.ui.appInitData?.plans ?? []);

	const getPlanById = useCallback(
		(planId: number) => {
			return plans.find((e) => e.id === planId);
		},
		[plans],
	);

	return { plans, getPlanById };
};
