export enum WalletTopupRequestStatus {
	Pending = 1,
	Approved = 2,
	Rejected = 3,
}

export enum WalletTransactionStatus {
	Issued = 1,
	Approved = 2,
	Pending = 3,
	Rejected = 4,
}

export enum WalletRequestType {
	Topup = 1,
	PolicyIssuance = 2,
}
