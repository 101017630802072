import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiCapturePaymentRequest {
	journeyId: string;
	paymentId: string;
}

export const apiCapturePaymentLink = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiCapturePaymentRequest>({
		query: (request) => ({
			url: Endpoints.urls.payment.capturePaymentLink,
			method: 'POST',
			body: request,
		}),
		extraOptions: { maxRetries: 5 },
	});
};
