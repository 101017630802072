import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetCustomerCompaniesResponse extends IPagingAndSorting {
	id: number;
	companyName: string;
	establishmentCardNumber: string;
	tradeLicenseNo: string;
	cityId: number;
	emailAddress: string;
	mobileNumber: string;
	linkedUsersCount: number;
	purchasedQuotasCount: number;
	activeQuotasCount: number;
	issuedPoliciesCount: number;
}

export interface IApiGetCustomerCompaniesRequest extends IPagingAndSorting {
	customerId: number;
	agencyName?: string;
	establishmentCardNumber?: string;
	tradeLicenceNumber?: string;
}
export const apiGetCustomerCompanies = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetCustomerCompaniesResponse>,
		IApiGetCustomerCompaniesRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.customer.companies(request.customerId),
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetCustomerCompaniesResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
