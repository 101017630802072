import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiSendPolicyEmailRequest {
	policyId: number;
}

export const apiSendPolicyEmail = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiSendPolicyEmailRequest>({
		query: ({ policyId }) => ({
			url: Endpoints.urls.admin.policy.sendPolicyEmail(policyId),
			method: 'GET',
		}),
	});
};
