import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export type ApiGetQuotaDetailsPoliciesResponse = {
	id: number;
	policyNumber: string;
	policyReferenceNo: string;
	previousPolicyNumber: string;
	fullNameEn: string;
	fullNameAr: string;
	emailAddress: string;
	mobileNumber: string;
	nationality: string;
	dateOfBirth: string;
	gender: string;
	passportNo: string;
	passportIssueDate: string;
	passportExpiryDate: string;
	passportIssueCountry: string;
	visaApprovedDate: string;
	policyIssueDate: string;
	policyPeriod: string;
	policyAmountIncludingVAT: number;
	insuranceCompanyName: string;
};
export type ApiGetQuotaDetailsPoliciesRequest = {
	searchText?: string;
	quotaId?: string;
	policyDocumentType?: string;
	policiesIds?: string[];
};
export const apiGetQuotaDetailsPolicies = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<ApiGetQuotaDetailsPoliciesResponse>,
		ApiGetQuotaDetailsPoliciesRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.quota.policies,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: ApiGetQuotaDetailsPoliciesResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
