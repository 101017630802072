import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config/endpoints';
import { WalletTopupRequestStatus } from '../../../config/enums';

export interface IApiGetRecentWalletTopUpResponse {
	status: WalletTopupRequestStatus;
	sourceBankId?: number;
	amount: number;
}

export const apiGetRecentWalletTopUp = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IApiGetRecentWalletTopUpResponse[], void>({
		query: () => ({
			url: Endpoints.urls.customerPortal.walletRecentTopUp,
			method: 'POST',
		}),
	});
};
