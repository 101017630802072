import { isArray, isNil, isObject, omitBy } from 'lodash';

export const convertNullToUndefined = <T>(value: T): T => {
	if (value === null) return undefined as T;

	if (
		(!isObject(value) && !isArray(value)) ||
		(value as unknown) instanceof Date
	)
		return value;

	const entries = Object.entries(value);

	const result = entries.reduce((acc, [prop, value]): object => {
		let newValue;
		if (isArray(value)) {
			newValue = value.map((v) => convertNullToUndefined(v));
		} else if (isObject(value)) {
			newValue = convertNullToUndefined(value);
		} else {
			newValue = convertNullToUndefined(value);
		}
		return {
			...acc,
			[prop]: newValue,
		};
	}, {});

	return omitBy(result, isNil) as T;
};
