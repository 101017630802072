import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { ApiGetPaymentGatewayRequest } from './apiGetPaymentGateway';

type ApiPostMarkAsPaidRequest = {
	payoutDate: string;
	payoutReferenceNo: string;
	searchCriteria: Partial<ApiGetPaymentGatewayRequest>;
};
export const apiPostMarkAsPaid = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, ApiPostMarkAsPaidRequest>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.paymentGateway.markAsPaid,
			body: request,
		}),
	});
};
