import { getIsoDateTime, logger } from '@ninja/utilities';

export const readTokenExpiry = (token: string) => {
	try {
		const tokenBody = token.split('.')[1];

		if (token) {
			const tokenData = JSON.parse(
				Buffer.from(tokenBody, 'base64').toString('ascii'),
			) as { exp: number };

			const expiryTime = tokenData.exp;
			if (typeof expiryTime === 'number')
				return getIsoDateTime(new Date(expiryTime * 1000));
		}
	} catch (e) {
		logger.error('readTokenExpiry', e);
	}

	return undefined;
};

export const readToken = (token: string) => {
	const result: { expiryTime?: number; userId?: string } = {};

	try {
		const tokenBody = token.split('.')[1];

		if (token) {
			const tokenData = JSON.parse(
				Buffer.from(tokenBody, 'base64').toString('ascii'),
			) as { exp: number; UserId: string };

			result.expiryTime = tokenData.exp * 1000; //convert to milliseconds
			result.userId = tokenData.UserId;
		}
	} catch (e) {
		logger.error('readTokenExpiry', e);
	}

	return result;
};
