import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface ISummary {
	allTotalTopups: number;
	totalCurrentBalance: number;
	totalUtilization: number;
}
export interface IApiWalletUtilizationResponse {
	walletId: number;
	emailAddress: string;
	companyName: string;
	mobileNumber: string;
	lastTransactionType: string;
	totalTopups: number;
	currentBalance: number;
	utilization: number;
	transactionDate: string;
	customerId: number;
}

export interface IApiWalletUtilizationRequest extends IPagingAndSorting {
	partnerEmail?: string;
	partnerName?: string;
	partnerMobile?: string;
	transactionDateFrom?: string;
	transactionDateTo?: string;
	balanceFrom?: number;
	balanceTo?: number;
	utilizationFrom?: number;
	utilizationTo?: number;
	walletIds?: number[];
}

export type TApiWalletUtilizationFullResponse = {
	data: IApiWalletUtilizationResponse[];
	summary: ISummary;
	totalItemsCount: number;
};
export const apiGetWalletUtilization = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiWalletUtilizationResponse>,
		IApiWalletUtilizationRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.walletUtilization.list,
			body: request,
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiWalletUtilizationResponse[];
				summary: ISummary;
			}>,
		) => {
			return {
				data: response.data.report,
				summary: response.data.summary,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
