import { useUserInfo } from '@visit-visa/lib-customer';
import { AppConfig } from '../config/app-config';
import { useWebNavigation } from '../contexts/navigation';

const useRedirectToLogin = () => {
	const { getPathForRoute, navigationPush } = useWebNavigation();
	const { agency } = useUserInfo();

	const onRedirectToLogin = () => {
		const ssoPageUrl = getPathForRoute('SSO_Login');
		const redirectUrl = new URL(ssoPageUrl, window.location.origin);

		redirectUrl.searchParams.set('redirect_uri', window.location.href);

		const authorizeUrl = new URL(
			getPathForRoute('Authorize'),
			window.location.origin,
		);

		if (AppConfig.productId)
			authorizeUrl.searchParams.set('client_id', AppConfig.productId);

		if (agency) authorizeUrl.searchParams.set('agency_info', agency);
		authorizeUrl.searchParams.set('redirect_uri', redirectUrl.href);
		window.location.replace(authorizeUrl);
	};

	return {
		onRedirectToLogin,
	};
};

export default useRedirectToLogin;
