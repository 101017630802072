import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export interface IUserDetailsResponse {
	user: {
		id: string;
		userName?: string;
		mobileCountryCode: string;
		mobileNumber: string;
		email: string;
		userStatusId: number;
		userCategoryId: number;
		registeredOn: string;
		lastUpdated: string;
		lastSuspended?: string;
		lastLoggedIn: string;
		name?: string;
	};
	companies: {
		id: string;
		name: string;
		cityId: number;
		establishmentNumber: string;
		permissions: {
			[key: number]: string[];
		};
	}[];

	productCompanyActions: {
		[key: string]: {
			[key: string]: string[];
		}[];
	};
}

export const apiUserDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<IUserDetailsResponse, void>({
		query: () => {
			return {
				url: Endpoints.urls.user.details,
				method: 'GET',
			};
		},
		transformResponse: (response: IBaseResponse<IUserDetailsResponse>) =>
			response.data,
	});
};
