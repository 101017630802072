import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

type CancelQuotaRequest = {
	quotaId: number;
	cancellationReasonId?: number;
	receiverName: string;
	accountNumber: string;
	iban: string;
	bankId?: number;
	comment?: string;
};

type CancelQuotaResponse = {
	requestReferenceNo: string;
};

export const apiCancelQuota = (builder: ApiEndpointBuilder) => {
	return builder.mutation<CancelQuotaResponse, CancelQuotaRequest>({
		query: (body) => ({
			method: 'post',
			url: Endpoints.urls.admin.quota.cancelQuota,
			body,
		}),
	});
};
