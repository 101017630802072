import { useEffect } from 'react';
import { SystemLocale } from '@ninja/utilities';
import { useLazyGetAppInitQuery } from '../redux-store/rtkq-slice/endpoints';

export const useAppInitialization = (locale: SystemLocale) => {
	const [getVisitAppInit] = useLazyGetAppInitQuery();

	useEffect(() => {
		getVisitAppInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);
};
