import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IApiGetWalletRequestsRequest extends IPagingAndSorting {
	agencyCompanyName?: string;
	requestReferenceNo?: string;
	customerEmail?: string;
	customerMobileNumber?: string;
	walletStatuses?: number[];
	requestDateFrom?: string;
	requestDateTo?: string;
}

export interface IApiGetWalletRequestsResponse {
	walletRequestId: number;
	requestDate?: string;
	requestReferenceNo?: string;
	agencyName?: string;
	channelName?: string;
	customerEmail?: string;
	customerMobileNumber?: string;
	bankName?: string;
	transferReferenceNumber?: string;
	requestAmount?: string;
	ApprovedAmount?: string;
	status?: string;
	requesterEmail?: string;
	requesterMobileNumber?: string;
}

export const apiGetWalletRequests = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IApiGetWalletRequestsResponse>,
		IApiGetWalletRequestsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.wallet.search,
			body: { ...request, statuses: request.walletStatuses },
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IApiGetWalletRequestsResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
