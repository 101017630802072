import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { ReactForm } from '@ninja/utilities';
import { DocumentTypes } from '../../../common/enum';
import { setKycDetailsData } from '../../../redux-store/quota-purchase.slice';
import { useAppDispatch } from '../../../redux-store/store';
import { IKycForm, TQuestionsName } from './types';

const useKycHelpers = (form: ReactForm<IKycForm>) => {
	const [allMissingFiles, setAllMissingFiles] = useState<string[]>([]);
	const dispatch = useAppDispatch();

	const {
		append: appendPerson,
		remove: removePerson,
		fields: allPersonsFields,
	} = form.useFieldArray('persons');

	const {
		declaration,
		files: allFiles,
		questions: allQuestion,
	} = form.watchValues();

	const allPersons = form.watchValues('persons').map((el, i) => ({
		...el,
		id: allPersonsFields[i].id,
	}));

	const onDeletePerson = (index: number) => {
		removePerson(index);
		if (allPersons?.length === 1) {
			return appendPerson({});
		}
	};

	const onAddPerson = () => {
		appendPerson({});
	};

	const onQuestionChecked = (name: TQuestionsName, value?: boolean) => {
		form.setFieldValue(`questions.${name}`, value);
		if (name === 'stakeholders' && !allPersons?.length) {
			return appendPerson({});
		}
	};

	const onUploadFile = (
		fileId: DocumentTypes,
		name: string,
		encryptedDocumentId: string,
	) => {
		allFiles[fileId] = { name, encryptedDocumentId };
		form.setFieldValue(`files`, allFiles);
	};
	const onDeleteFile = (fileId: DocumentTypes) => {
		allFiles[fileId] = {};
		form.setFieldValue(`files`, allFiles);
	};

	const onGoBack = () => {
		const values = form.getValues();
		const { questions, files, persons } = values;

		if (!questions.firm) files[DocumentTypes.Regulatory] = {};
		if (!questions.stakeholders) persons.length = 0;

		dispatch(setKycDetailsData(cloneDeep(values)));
	};

	const onSubmitForm = () => {
		const missingFiles = [];

		if (!allFiles[DocumentTypes.Trade]?.name) {
			missingFiles.push('Trade License');
		}
		if (!allFiles[DocumentTypes.Tax]?.name) {
			missingFiles.push('TAX / VAT Certificate');
		}
		if (!allFiles[DocumentTypes.Authorized]?.name) {
			missingFiles.push('Authorized Representative Emirates ID');
		}

		if (missingFiles.length) {
			setAllMissingFiles(missingFiles);
			return;
		}

		form.submitForm();
	};

	const onCloseModal = () => {
		setAllMissingFiles([]);
	};
	const onConfirmModal = () => {
		form.submitForm().then(onCloseModal);
	};

	return {
		functions: {
			onAddPerson,
			onDeletePerson,
			onQuestionChecked,
			onUploadFile,
			onDeleteFile,
			onCloseModal,
			onConfirmModal,
			onSubmitForm,
			onGoBack,
		},
		kycData: {
			allPersons,
			allFiles,
			declaration,
			allQuestion,
			allMissingFiles,
		},
	};
};

export default useKycHelpers;
