import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiSubmitKycDataRequest {
	channelTypeId: number;
	regulatoryComplianceQuestion: {
		questionId: number;
		isFirmRegulated: boolean;
	};
	politicalExposureComplianceQuestion: {
		questionId: number;
		hasPep: boolean;
		peps: {
			name: string;
			jobTitle: string;
			pepTypeId: number;
		}[];
	};
	documentReference: string;
	encryptedDocumentIds: string[];
}
export const apiSubmitKycData = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiSubmitKycDataRequest>({
		query: (data) => ({
			url: Endpoints.urls.kyc.submit,
			method: 'POST',
			body: data,
		}),
	});
};
