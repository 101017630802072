import { useMemo } from 'react';
import { IResponseError } from '@ninja/utilities';
import { useAppSelector } from '../../../redux-store/store';
import { selectDaysCount } from '../../../selectors/ui.selectors';
import { useKycForm } from './useKycForm';
import useKycHelpers from './useKycHelpers';
import useStaticData from './useStaticData';

const useKYCDetailsData = (
	onGeneralErrors?: (errors: IResponseError[]) => void,
	onSuccess?: () => void,
) => {
	const staticData = useStaticData();
	const daysCount = useAppSelector(selectDaysCount);

	const { form } = useKycForm(onGeneralErrors, onSuccess);

	const { kycData, functions } = useKycHelpers(form);
	const { allPersons } = kycData;

	const isAddDisabled = useMemo(() => {
		const lastPerson = allPersons?.[allPersons.length - 1];
		return (
			!lastPerson?.PEPName || !lastPerson?.JobTitle || !lastPerson?.PEPType
		);
	}, [allPersons]);

	return {
		form,
		isAddDisabled,
		daysCount,
		...staticData,
		...kycData,
		...functions,
	};
};

export default useKYCDetailsData;
