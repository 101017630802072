import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiAuthorizePaymentRequest {
	journeyId: string;
	cardToken: string;
	paymentProviderId: number;
	payment: {
		amountWithoutVat: number;
		vatAmount: number;
		currency: string;
	};
}

interface IApiAuthorizePaymentResponse {
	journeyId: string;
	url3Ds: string;
	paymentId: string;
	TransactionRef: string;
	Last4Digits: number | null;
	amount: number;
	currency: string;
}

export const apiAuthorizePayment = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiAuthorizePaymentResponse,
		IApiAuthorizePaymentRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.payment.authorize,
			method: 'POST',
			body: request,
		}),
		transformResponse: (
			response: IBaseResponse<IApiAuthorizePaymentResponse>,
		) => {
			return response.data;
		},
	});
};
