import { AppState } from '../redux-store/store';

export const selectAppInitData = (state: AppState) => {
	return state.ui.appInitData;
};

export const selectQuotaDetailsConfigurableFields = (state: AppState) => {
	return {
		visaTypes: state.ui.appInitData?.visaPeriods ?? [],
		memberCount: {
			min:
				state.ui.appInitData?.systemConfigurationSettings
					?.quotaSettingsMemberMinCount ?? 50,
			max:
				state.ui.appInitData?.systemConfigurationSettings
					?.quotaSettingsMemberMaxCount ?? 10000,
		},
		department: state.ui.appInitData?.cities ?? [],
	};
};

export const selectBanks = (state: AppState) => {
	return state.ui.appInitData?.uaeBanks;
};

export const selectShoryBankDetails = (state: AppState) => {
	return state.ui.appInitData?.shoryBankDetails[0];
};
export const selectDaysCount = (state: AppState) => {
	//TODO change prop name after get it from BE
	return 15;
};

export const selectQuotaPriceLimitForCardPayment = (state: AppState) => {
	return (
		state.ui.appInitData?.systemConfigurationSettings
			.quotaPriceLimitForCardPayment ?? 0
	);
};
