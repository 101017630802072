import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetQuotaCancellationRequestLogsRequest {
	quotaCancellationId: number;
}
export interface IApiGetQuotaCancellationRequestLogsResponse {
	actionDate: string;
	requestReferenceNo: string;
	refundChannel: string;
	companyName: string;
	bankName: string;
	refundRequestDate: string;
	refundAmount: number;
	action: number;
	actionBy: string;
}

export const apiGetQuotaCancellationRequestLogs = (
	builder: ApiEndpointBuilder,
) => {
	return builder.query<
		IApiGetQuotaCancellationRequestLogsResponse[],
		IApiGetQuotaCancellationRequestLogsRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.admin['quota-cancellation'].logs(
				request.quotaCancellationId,
			),
		}),
		transformResponse: (response: {
			quotaCancellationLogs: IApiGetQuotaCancellationRequestLogsResponse[];
		}) => {
			return response.quotaCancellationLogs;
		},
	});
};
