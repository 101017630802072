import { SystemLocale } from '@ninja/utilities';
import { setCustomerLoginAPIsConfig } from '@visit-visa/api-customer-login';
import { setVisitVisaAPIsConfig } from '@visit-visa/api-visit-visa';

export const LibConfig = {
	isProduction: true as boolean,
	visitVisaAPIUrl: '' as string,
	accountsAPIUrl: '' as string,
	productId: '' as string,
	getLocale: () => 'en' as SystemLocale,
};

export const setCustomerLibConfig = (options: typeof LibConfig) => {
	Object.keys(LibConfig).forEach((key) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(LibConfig as any)[key] = (options as any)[key];
	});

	setVisitVisaAPIsConfig(options.visitVisaAPIUrl);
	setCustomerLoginAPIsConfig(options.accountsAPIUrl);
};
