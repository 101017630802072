import { IPagingAndSorting } from '@ninja/web';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';
import { IListingBaseResponse, IListingResponse } from '../../common/types';

export interface IGetPolicyListRequest extends IPagingAndSorting {
	policyNumber?: string;
	customerName?: string;
	customerEmail?: string;
	customerMobile?: string;
	requestStatusIds?: number[];
	requestDateFrom?: string;
	requestDateTo?: string;

	//this prop will not be send in the request body
	policyRequestStatusId?: number[];
}

export interface IGetPolicyListResponse {
	id: number;
	policyNumber: string;
	policyPeriodId: number;
	memberName: string;
	mobileNumber: string;
	emailAddress: string;
	requestDate: string;
	createdBy: string;
	nationalityId: number;
	dateOfBirth: string;
	genderId: number;
	passportNo: string;
	passportIssueCountryId: number;
	passportIssueDate: string;
	passportExpiryDate: string;
	paymentMethodId: number;
	paymentReferenceNo: string;
	premiumExcVAT: number;
	premiumInclVAT: number;
	vatAmount: number;
	requestStatusId: number;
	paymentLink: string;
	comment: string;
	paymentExpiryOn: string;
	policyId: number;
}

export const apiGetPolicyList = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IListingResponse<IGetPolicyListResponse>,
		IGetPolicyListRequest
	>({
		query: ({ policyRequestStatusId, ...rest }) => ({
			method: 'POST',
			url: Endpoints.urls.admin['requested-policy'].search,
			body: { ...rest, requestStatusIds: policyRequestStatusId },
		}),
		transformResponse: (
			response: IListingBaseResponse<{
				report: IGetPolicyListResponse[];
			}>,
		) => {
			return {
				data: response.data.report,
				totalItemsCount: response.data.paging.totalRecords,
			};
		},
	});
};
