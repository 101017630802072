import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

interface IApiAgencyNotExistsCreateJourneyRequest {
	agencyName?: string;
	cityId?: number;
	establishmentCardNumber?: string;
	encryptedQuoteJourneyId?: string;
	memberCount?: number;
	visaPeriodId?: number;
	quotaEffectiveDate?: string;
	baseTariffId?: number;
}

interface IAgencyNotExistsResponse {
	quotaJourneyId: string;
}
export const apiNonIcpCreateJourney = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IAgencyNotExistsResponse,
		IApiAgencyNotExistsCreateJourneyRequest
	>({
		query: (body) => ({
			method: 'post',
			url: Endpoints.urls.Quota.nonIcpCreateJourney,
			body,
		}),
	});
};
