import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	IResponseError,
	IResponseErrors,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import {
	useGetCreateJourneyMutation,
	useLazyGetIssuedQuotaDetailsQuery,
} from '../../../redux-store/rtkq-slice/endpoints';
import { useAppSelector } from '../../../redux-store/store';
import {
	selectPaymentMethod,
	selectQuoteJourneyId,
} from '../../../selectors/quotaPurchase.selectors';
import useCurrencyAndVat from '../../useCurrencyAndVat';
import useDeviceInfo from '../../useDeviceInfo';
import useFileImport, { HttpMethod } from '../../useFileExport';
import { useTransformSelectedQuota } from '../select-product';
import useProductIssuanceTransformer from './useProductIssuanceTransformer';

interface IUseQuotaIssuanceProps {
	onApiErrors: (errors: IResponseError[]) => void;
	onDocumentNotReady?: () => void;
}

const useQuotaIssuance = (props: IUseQuotaIssuanceProps) => {
	const { onApiErrors } = props;
	const { selectedProductDetails: selectedQuoteDetails } =
		useTransformSelectedQuota();
	const { getCurrencyAndVat } = useCurrencyAndVat();
	const currencyAndVat = getCurrencyAndVat();
	const paymentMethod = useAppSelector(selectPaymentMethod);
	const { exportDocument } = useFileImport();
	const { transformProductIssuance } = useProductIssuanceTransformer();
	const [getIssuedQuotaDetails, { isLoading, data: quotaIssuanceDetails }] =
		useLazyGetIssuedQuotaDetailsQuery();
	const [getNewQuotaJourney, { isLoading: isGetNewQuotaLoading }] =
		useGetCreateJourneyMutation();
	const { getErrorMessages } = useErrorsResponseTransform('quota.errors', true);
	const quotaJourneyId = useAppSelector(selectQuoteJourneyId);
	const [isAttachmentLoading, setIsAttachmentLoading] = useState<
		'receipt' | 'certificate'
	>();

	const { isSafari } = useDeviceInfo();

	const quotaIssuanceData = useMemo(() => {
		return {
			planType: selectedQuoteDetails?.planType ?? '',
			planName: selectedQuoteDetails?.planName,
			planTypeColor: selectedQuoteDetails?.planTypeColor,
			planTypeBgColor: selectedQuoteDetails?.planeTypeBgColor,
			quotaIssuanceDetails: transformProductIssuance(quotaIssuanceDetails),
			backToICPUrl: quotaIssuanceDetails?.backToIcpUrl,
			// TODO: will added it once it added to the response
			downloadCertificateUrl: quotaIssuanceDetails?.quotaCertificateUrl,
			downloadInvoiceUrl: quotaIssuanceDetails?.quotaReceiptUrl,
		};
	}, [quotaIssuanceDetails, selectedQuoteDetails, transformProductIssuance]);

	const onGenerateNewQuota = async () => {
		try {
			const { redirectUrl } = await getNewQuotaJourney(undefined).unwrap();

			const element = document.createElement('a');
			element.href = `${redirectUrl}&isNewQuota=true`;
			element.target = !isSafari ? '_blank' : '';
			element.click();
		} catch (error) {
			const errors = error as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onApiErrors(mappedGeneralErrors);
		}
	};

	const onDownloadFileHandler = async (
		buttonSource: 'receipt' | 'certificate',
	) => {
		setIsAttachmentLoading(buttonSource);

		let link: string | undefined = undefined;
		if (buttonSource === 'receipt') link = quotaIssuanceData.downloadInvoiceUrl;
		else link = quotaIssuanceData.downloadCertificateUrl;

		if (!link) {
			const res = await onGetQuotaIssuance();

			if (buttonSource === 'receipt') link = res?.quotaReceiptUrl;
			else link = res?.quotaCertificateUrl;
		}

		// If link is ready then download else show message document is not ready.
		if (link) {
			const fileRes = await exportDocument({
				url: link ?? '',
				fileName: link?.split('/')?.pop() || '',
				method: HttpMethod.GET,
				target: false,
			});

			setIsAttachmentLoading(undefined);

			if (fileRes.error) {
				props.onDocumentNotReady?.();
				setIsAttachmentLoading(undefined);
			}
		} else {
			props.onDocumentNotReady?.();
			setIsAttachmentLoading(undefined);
		}
	};

	const onGetQuotaIssuance = useCallback(async () => {
		try {
			const res = await getIssuedQuotaDetails(quotaJourneyId ?? '').unwrap();
			return res;
		} catch (e) {
			const errors = e as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onApiErrors(mappedGeneralErrors);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getIssuedQuotaDetails, quotaJourneyId]);

	useEffect(() => {
		onGetQuotaIssuance();
	}, [onGetQuotaIssuance]);

	return {
		quotaIssuanceData,
		onDownloadFileHandler,
		isLoading,
		isAttachmentLoading,
		paymentMethod,
		vatRate: currencyAndVat?.rate,
		onGenerateNewQuota,
		isGetNewQuotaLoading,
	};
};

export default useQuotaIssuance;
