import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiGetQuotaCancellationRequestDetailsRequest {
	quotaCancellationId: number;
}

type QuotaCancellationRequest = {
	id: number;
	requestReferenceNo: string;
	requesterEmail: string;
	requesterMobileNumber: string;
	bankName: string;
	accountNumber: string;
	iban: string;
	receiverName: string;
	cancellatioReason: string;
	comment: string;
	requestDate: string;
	requestStatusTypeId: number;
	requestStatusId: number;
	refundableQuantity: number;
	refundableAmount: number;
	isCancelledFromICP: boolean;
	rejectionReason: string;
	isAutomated: boolean;
};
type Quota = {
	id: number;
	quotaNumber: string;
	memberCount: number;
	consumedPolicies: number;
	issueDate: string;
	effectiveDate: string;
	expiryDate: string;
	visaPeriodId: number;
};

type CompanyDetail = {
	agencyId: string;
	fullNameEn: string;
	establishmentNumber: string;
	licenseNo: string;
	cityId: number;
	emailAddress: string;
	mobileNumber: string;
};
export interface IApiGetQuotaCancellationRequestDetailsResponse {
	quotaCancellationRequest: QuotaCancellationRequest;
	quota: Quota;
	companyDetail: CompanyDetail;
}

export const apiGetQuotaCancellationRequestDetails = (
	builder: ApiEndpointBuilder,
) => {
	return builder.query<
		IApiGetQuotaCancellationRequestDetailsResponse,
		IApiGetQuotaCancellationRequestDetailsRequest
	>({
		query: (request) => ({
			method: 'GET',
			url: Endpoints.urls.admin['quota-cancellation'].details(
				request.quotaCancellationId,
			),
		}),
		transformResponse: (
			response: IBaseResponse<IApiGetQuotaCancellationRequestDetailsResponse>,
		) => response.data,
	});
};
