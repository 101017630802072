import { IErrorMessage, PersistSliceConfig } from '@ninja/utilities';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	IApiGetAllPlansResponse,
	IApiGetCompanyDetailsResponse,
} from '@visit-visa/api-visit-visa';
import { IKycForm, STEPPER_STEPS } from '../hooks';
import { apisEndpoints } from './rtkq-slice/endpoints';
import { IQuotaDetails, ISelectedQuota, PaymentMethod } from './types';

interface IInitialState {
	company?: IApiGetCompanyDetailsResponse;
	identityCompanyId?: string;
	selectedQuotaEN?: ISelectedQuota;
	selectedQuotaAR?: ISelectedQuota;
	quotaDetails?: IQuotaDetails;
	KycDetails?: IKycForm;
	quoteJourneyId?: string;
	quotas?: IApiGetAllPlansResponse[];
	paymentErrors?: IErrorMessage[];
	currentStep: STEPPER_STEPS;
	paymentMethod?: PaymentMethod;
	isAgencyNonEditable: boolean;
	isICP: boolean;
	isClearable?: boolean;
}

const initialState: IInitialState = {
	quotaDetails: undefined,
	selectedQuotaEN: undefined,
	selectedQuotaAR: undefined,
	KycDetails: undefined,
	quoteJourneyId: undefined,
	quotas: undefined,
	currentStep: 1,
	paymentMethod: undefined,
	isAgencyNonEditable: false,
	isICP: false,
};

const quotaPurchaseSlice = createSlice({
	name: 'quotaPurchase',
	initialState,
	reducers: {
		setQuotaDetails: (state, action: PayloadAction<IQuotaDetails>) => {
			state.quotaDetails = action.payload;
		},
		setCompanyData: (
			state,
			action: PayloadAction<IApiGetCompanyDetailsResponse>,
		) => {
			state.company = action.payload;
		},
		setSelectedQuotaEN: (
			state,
			action: PayloadAction<ISelectedQuota | undefined>,
		) => {
			state.selectedQuotaEN = action.payload;
		},
		setSelectedQuotaAR: (
			state,
			action: PayloadAction<ISelectedQuota | undefined>,
		) => {
			state.selectedQuotaAR = action.payload;
		},
		setKycDetailsData: (state, { payload }) => {
			state.KycDetails = payload;
		},
		setJourneyPaymentErrors: (
			state,
			{ payload }: PayloadAction<IErrorMessage[] | undefined>,
		) => {
			state.paymentErrors = payload;
		},
		setIsClearable: (state, action: PayloadAction<boolean>) => {
			state.isClearable = action.payload;
		},
		initiateJourney: (
			_state,
			action: PayloadAction<{
				quoteJourneyId: string;
				company: IApiGetCompanyDetailsResponse;
				identityCompanyId: string;
				isAgencyNonEditable?: boolean;
				isICP: boolean;
				currentStep?: number;
			}>,
		) => {
			return {
				...initialState,
				quoteJourneyId: action.payload.quoteJourneyId,
				company: action.payload.company,
				identityCompanyId: action.payload.identityCompanyId,
				isAgencyNonEditable: action.payload.isAgencyNonEditable ?? false,
				currentStep: action.payload.currentStep ?? 1,
				isICP: action.payload.isICP,
				isClearable: false,
			};
		},
		setCurrentStep(state, { payload }) {
			state.currentStep = payload;
		},
		setPaymentMethod(state, { payload }: PayloadAction<PaymentMethod>) {
			state.paymentMethod = payload;
		},
		resetQuotaDetails: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			apisEndpoints.getAllQuotas.matchFulfilled,
			(state, { payload }) => {
				state.quotas = payload;
			},
		);
	},
});

export const {
	setQuotaDetails,
	setSelectedQuotaEN,
	setKycDetailsData,
	setJourneyPaymentErrors,
	initiateJourney,
	setCurrentStep,
	setPaymentMethod,
	resetQuotaDetails,
	setSelectedQuotaAR,
	setCompanyData,
	setIsClearable,
} = quotaPurchaseSlice.actions;

export const quotaPurchase_PersistConfig: PersistSliceConfig<
	typeof initialState
> = {
	key: quotaPurchaseSlice.name,
	storageType: 'Session',
	baseReducer: quotaPurchaseSlice.reducer,
};
