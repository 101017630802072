import { useCurrencyList, useVatConfigurations } from './AppInitData';

enum CURRENCY_LIST {
	AED = 1,
}

type ICurrencyAndVat =
	| {
			currencyName?: string;
			id: number;
			countryId: number;
			currencyId: number;
			rate: number;
			additionalData: unknown;
	  }
	| undefined;

const useCurrencyAndVat = () => {
	const { vatConfigurations } = useVatConfigurations();
	const { currencyList } = useCurrencyList();

	const getCurrencyAndVat = () => {
		const combineCurrencyAndVat = vatConfigurations?.map((v) => {
			const currencyName = currencyList?.find(
				(c) => v.currencyId === c.id,
			)?.name;

			return { ...v, currencyName };
		});

		const currencyAndVat: ICurrencyAndVat = combineCurrencyAndVat?.find(
			(el) => el.id === CURRENCY_LIST.AED,
		);

		return currencyAndVat;
	};
	return { getCurrencyAndVat };
};

export default useCurrencyAndVat;
