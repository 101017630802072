import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiUpdateRegulatoryInformationRequest {
	kycRequestId: number;
	adminRegulartoryInformation: {
		establishmentTypeId?: number;
		businessActivities?: string[];
		taxTrnNumber?: string;
		mohreCardNo?: string;
		gdrfaCardNo?: string;
		yearsOfIncorporation?: number;
		licenseIssueDate?: string;
		licenseExpiryDate?: string;
		regulatoryLicenseNumber?: string;
		regulatoryLicenseIssueDate?: string;
	};
	agencyContactDetail: {
		representativeName?: string;
		designation?: string;
		mobileNumber?: string;
		email?: string;
		building?: string;
		floorOffice?: string;
		poBox?: string;
		vatAdress?: string;
	};
}
export const apiUpdateRegulatoryInformation = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiUpdateRegulatoryInformationRequest>({
		query: ({
			adminRegulartoryInformation,
			agencyContactDetail,
			kycRequestId,
		}) => ({
			method: 'POST',
			url: Endpoints.urls.admin.kyc.updateRegulatory(kycRequestId),
			body: {
				adminRegulartoryInformation,
				agencyContactDetail,
			},
		}),
	});
};
