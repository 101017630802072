import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

export interface IApiGetApiErrorDetailsResponse {
	apiLogLinkingId: string;
	details: string;
}

export interface IApiGetApiErrorDetailsRequest {
	apiLogLinkingId: string;
	logType: number;
}

export const apiGetApiErrorDetails = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		IApiGetApiErrorDetailsResponse,
		IApiGetApiErrorDetailsRequest
	>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.report.apiError.details,
			body: request,
		}),
		transformResponse: (response: { record: IApiGetApiErrorDetailsResponse }) =>
			response.record,
	});
};
