import { useEffect, useState } from 'react';
import { APIResponseError, useErrorsResponseTransform } from '@ninja/utilities';
import { useAppPlatformContextOptions } from '../../../contexts/app';
import {
	initiateJourney,
	resetQuotaDetails,
} from '../../../redux-store/quota-purchase.slice';
import {
	useGetCompanyDetailsMutation,
	useGetCreateJourneyMutation,
} from '../../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch } from '../../../redux-store/store';
import useUserInfo from '../../useUserInfo';

interface NonIcpQuotaPurchaseProps {
	navigateToLanding: () => void;
}

const useInitNonICPQuotaPurchase = ({
	navigateToLanding,
}: NonIcpQuotaPurchaseProps) => {
	const appDispatch = useAppDispatch();
	const { userLoggedIn, userCurrentCompany } = useUserInfo();
	const [onGetCompanyDetails] = useGetCompanyDetailsMutation();
	const [getNewQuotaJourney] = useGetCreateJourneyMutation();
	const { translate } = useAppPlatformContextOptions();
	const { getErrorMessages } = useErrorsResponseTransform(
		'shared.error-messages',
		true,
	);
	const [hasError, setError] = useState<{ title: string; message: string }>();

	useEffect(() => {
		if (userLoggedIn && userCurrentCompany) {
			const onGenerateNewQuota = async () => {
				try {
					const { redirectUrl } = await getNewQuotaJourney({
						companyId: userCurrentCompany.id,
					}).unwrap();
					const quoteJourneyId = new URL(redirectUrl).searchParams.get(
						'quoteJourneyId',
					);

					if (quoteJourneyId) {
						const company = await onGetCompanyDetails(
							quoteJourneyId ?? '',
						).unwrap();

						appDispatch(
							initiateJourney({
								quoteJourneyId,
								company,
								identityCompanyId: userCurrentCompany?.id ?? '',
								isAgencyNonEditable: true,
								isICP: false,
							}),
						);

						navigateToLanding();
					}
				} catch (error) {
					const errors = getErrorMessages(error as APIResponseError);
					errors.forEach((error) => {
						if (error.code === 'CustomerNotLinkedToAgency') {
							setError({
								title: translate('shared.error-messages.oops'),
								message: translate(
									'shared.error-messages.user-linked-with-other-company-description',
								),
							});
						} else
							setError({
								title: translate('shared.error-messages.oops'),
								message: translate('shared.error-messages.no-access-msg'),
							});
					});
				}
			};

			onGenerateNewQuota();
		} else {
			appDispatch(resetQuotaDetails());
			navigateToLanding();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		hasError,
	};
};

export default useInitNonICPQuotaPurchase;
