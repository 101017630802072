import { IBaseResponse } from '@ninja/utilities';
import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config';

export type ApiAuthorizePaymentRequest = {
	journeyId: string;
	cardToken: string;
	paymentProviderId: number;
	payment: {
		amountWithoutVat: number;
		vatAmount: number;
		currency: string;
	};
};

type ApiAuthorizePaymentResponse = {
	journeyId: string;
	url3Ds: string;
	paymentId: string;
	TransactionRef: string;
	Last4Digits: number | null;
	amount: number;
	currency: string;
};

export const apiAuthorizePaymentLink = (builder: ApiEndpointBuilder) => {
	return builder.mutation<
		ApiAuthorizePaymentResponse,
		ApiAuthorizePaymentRequest
	>({
		query: (request) => ({
			url: Endpoints.urls.payment.authorizePaymentLink,
			method: 'POST',
			body: request,
		}),
		transformResponse: (
			response: IBaseResponse<ApiAuthorizePaymentResponse>,
		) => {
			return response.data;
		},
	});
};
