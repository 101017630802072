import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useCityData = () => {
	const cities = useAppSelector((state) => state.ui.appInitData?.cities ?? []);

	const getCityById = useCallback(
		(cityId: number) => {
			return cities.find((e) => e.id === cityId);
		},
		[cities],
	);

	return { cities, getCityById };
};
