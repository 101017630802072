import { IResponseErrors, logger } from '@ninja/utilities';
import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	fetchBaseQuery,
	retry,
} from '@reduxjs/toolkit/query/react';
import { LibConfig } from '../../config';
import { AppState } from '../store';
import { refreshAccessToken } from './refresh-token';

const customFetchBaseQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, WebApi, apiExtraOptions) => {
	const extraOptions = (apiExtraOptions as
		| undefined
		| { maxRetries: number }) ?? {
		maxRetries: 0,
	};

	if (!extraOptions.maxRetries) {
		extraOptions.maxRetries = 0;
	}

	const rawBaseQuery = fetchBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			const state = getState() as AppState;
			const customLang = LibConfig.getLocale();

			if (state.persistedSettings.accessToken) {
				headers.set(
					'Authorization',
					`Bearer ${state.persistedSettings.accessToken}`,
				);
			}

			const isAccountsAPI =
				typeof args !== 'string'
					? args.url.includes(LibConfig.accountsAPIUrl)
					: false;

			if (!isAccountsAPI) {
				if (LibConfig.productId) headers.set('productId', LibConfig.productId);

				const companyId = state.quotaPurchase.identityCompanyId;

				if (companyId && !headers.get('companyId'))
					headers.set('companyId', companyId);
			}

			if (customLang) headers.set('custom-Lang', customLang);

			return headers;
		},
	});

	return rawBaseQuery(args, WebApi, extraOptions);
};

const fetchQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = retry(
	async (args, api, extraOptions) => {
		let result = await customFetchBaseQuery(args, api, extraOptions);

		if (
			result.error?.status === 422 ||
			result.error?.status === 400 ||
			result.error?.status === 500
		) {
			logger.info(
				`[api.config][fetchBaseQueryWithRefreshToken]No retry for response ${result.error?.status}`,
			);
			retry.fail(result.error);
		}

		if (
			typeof window !== typeof undefined &&
			result.error &&
			result.error.status === 401
		) {
			logger.info('[api.config][fetchBaseQueryWithRefreshToken] Unauthorized');
			const { accessToken: lastAccessToken, refreshToken: lastRefreshToken } = (
				api.getState() as AppState
			).persistedSettings;

			if (lastAccessToken && lastRefreshToken) {
				const isRefreshed = await refreshAccessToken({
					currentAccessToken: lastAccessToken,
					currentRefreshToken: lastRefreshToken,
					dispatch: api.dispatch,
				});

				if (isRefreshed)
					result = await customFetchBaseQuery(args, api, extraOptions);
			}
		}

		return result;
	},
	{
		maxRetries: 0,
	},
);

export default fetchQuery as BaseQueryFn<
	string | FetchArgs,
	unknown,
	IResponseErrors
>;
