import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../config/endpoints';

export interface IApiGetIssuedQuotaDetailsResponse {
	backToIcpUrl: string;
	effectiveDate: string;
	expiryDate: string;
	issueDate: string;
	memberCount: number;
	paidAmount: number;
	quotaReceiptUrl: string;
	quotaNumber: string;
	quotaStatus: number;
	visaPeriodId: number;
	insuranceCompanyId: number;
	quotaCertificateUrl: string;
	memberPriceInclVAT: string;
}

export const apiGetIssuedQuotaDetails = (builder: ApiEndpointBuilder) => {
	return builder.query<IApiGetIssuedQuotaDetailsResponse, string>({
		query: (quotaJourneyId: string) =>
			Endpoints.urls.Quota.getIssuedQuotaDetails(quotaJourneyId),
	});
};
