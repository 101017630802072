import { useCallback } from 'react';
import { useAppSelector } from '../../redux-store/store';

export const useDocumentTypes = () => {
	const documentTypes = useAppSelector(
		(state) => state.ui.appInitData?.documentTypes ?? [],
	);

	const getDocById = useCallback(
		(docId: number) => {
			return documentTypes.find((e) => e.id === docId);
		},
		[documentTypes],
	);

	return { documentTypes, getDocById };
};
