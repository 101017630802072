import { ApiEndpointBuilder } from '@visit-visa/api-shared';
import { Endpoints } from '../../../config';

interface IApiRequestNewAttachmentsRequest {
	kycRequestId: number;
	actionComment: string;
	requestedDocuments: number[];
}

export const apiRequestNewAttachments = (builder: ApiEndpointBuilder) => {
	return builder.mutation<void, IApiRequestNewAttachmentsRequest>({
		query: (request) => ({
			method: 'POST',
			url: Endpoints.urls.admin.kyc.requestNewAttachment(request.kycRequestId),
			body: {
				actionComment: request.actionComment,
				requestedDocuments: request.requestedDocuments,
			},
		}),
	});
};
