export type { IApiGetAllPlansResponse } from '@visit-visa/api-visit-visa';

export interface IUser {
	name: string;
	email: string;
	phone: string;
}

export enum BENEFIT_TYPE {
	KEY_BENEFITS = 1,
	OTHER_BENEFITS = 2,
}

export interface IQuotaDetails {
	memberCount: number;
	visaType: number;
	quotaEffectiveDate: string;
}

export interface IBenefit {
	benefitId: number;
	summaryDescription?: string;
	summaryToolTipText?: string;
	summarySequence: number;
	detailedViewSequence: number;
	detailedViewDescription?: string;
	benefitClassId: number;
	benefitType?: BENEFIT_TYPE;
	languageType?: number;
}

export interface IBenefitWithExtraData extends IBenefit {
	summaryLabel?: string;
	detailedViewLabel?: string;
	benefitClassSequence?: number;
}

export interface ISelectedQuota {
	planId: number;
	baseTarrifId: number;
	perMemberVatExclusiveAmount: number;
	perMemberVatAmount: number;
	totalVatExclusiveAmount: number;
	totalVatAmount: number;
	keyBenefits: IBenefitWithExtraData[];
	otherBenefits: IBenefitWithExtraData[];
	exclusions: string[];
	arExclusions?: string[];
	planName: string;
	planType: string;
	planTypeColor: string;
	planeTypeBgColor: string;
	groupedBasedOnBenefitClassId: IBenefitWithExtraData[][];
	benefits: IBenefit[];
}

export interface IJWTToken {
	Companies: string;
	Email: string;
	EmailVerified: boolean;
	IsSuperAdmin: string;
	LastLoggedIn: string;
	MobileCountryCode: number;
	MobileNumber: string;
	MobileVerified: boolean;
	Name: string;
	ProductCompanyActions: string;
	Products: string;
	UserId: string;
	exp: number;
	iat: number;
	nbf: number;
}

export interface ICompany {
	EmiratesId: number;
	Id: string;
	TradeLicense: string;
}

export type PaymentMethod =
	| 'Shory_Wallet'
	| 'Credit'
	| 'Debit'
	| 'Debit_OR_Credit'
	| undefined;
