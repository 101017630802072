import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Button } from '@ninja/web';
import NoAccess from '../../public/visit-visa-insurance/images/no-access.svg';
import { useWebNavigation } from '../contexts/navigation';

interface IPageErrorProps {
	message?: string;
	showHomeButton?: boolean;
	image?: string;
	title?: string;
}
const PageError = (props: IPageErrorProps) => {
	const { message, showHomeButton = true, image, title } = props;
	const { t } = useTranslation();
	const { navigationPush } = useWebNavigation();

	return (
		<div className="flex flex-col items-center justify-center mt-16">
			<Image
				src={image ? image : NoAccess}
				alt="Blocked Account"
				className="object-contain"
			/>
			<div className="flex flex-col items-center p-8 bg-gray-50 mt-2.5 w-1/2 mb-4">
				<p className="font-bold">
					{title ? title : t('shared.error-messages.oops')}
				</p>
				<p className="text-center text-caption1">
					{message ?? t('shared.error-messages.no-access-msg')}
				</p>
			</div>
			{showHomeButton && (
				<Button onClick={() => navigationPush('Dashboard')}>
					{t('shared.error-messages.go-to-dashboard') ?? ''}
				</Button>
			)}
		</div>
	);
};

export default PageError;
