import {
	IResponseError,
	IResponseErrors,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import {
	ChannelTypes,
	DocumentTypes,
	KycQuestions,
} from '../../../common/enum';
import { usePostSubmitKycDataMutation } from '../../../redux-store/rtkq-slice/endpoints';
import { IKycForm } from './types';

const useSubmitKycData = () => {
	const currentChannel = ChannelTypes.Icp;
	const [submitKycData] = usePostSubmitKycDataMutation();
	const { getErrorMessages } = useErrorsResponseTransform('kyc.errors', true);

	const submitKycDataHandler = async (
		values: IKycForm,
		onGeneralErrors?: (errors: IResponseError[]) => void,
	) => {
		const { persons, questions, refId, files } = values;

		if (!questions.stakeholders) persons.length = 0;
		if (!questions.firm) files[DocumentTypes.Regulatory] = {};

		const peps =
			persons?.map((el) => ({
				name: el.PEPName ?? '',
				jobTitle: el.JobTitle ?? '',
				pepTypeId: el.PEPType ?? 0,
			})) ?? [];

		const encryptedDocumentIds = Object.keys(files ?? {}).reduce(
			(acc: string[], item) => {
				const key = item as unknown as keyof typeof files;
				const fileID = files[key]?.encryptedDocumentId;
				if (!fileID) return acc;
				return [...acc, fileID];
			},
			[],
		);

		try {
			await submitKycData({
				channelTypeId: currentChannel,
				regulatoryComplianceQuestion: {
					questionId: KycQuestions.Firm,
					isFirmRegulated: !!questions.firm,
				},
				politicalExposureComplianceQuestion: {
					questionId: KycQuestions.Stakeholders,
					hasPep: !!questions.stakeholders,
					peps,
				},
				documentReference: refId,
				encryptedDocumentIds,
			}).unwrap();
		} catch (e) {
			const errors = e as IResponseErrors;
			const mappedGeneralErrors = getErrorMessages(errors).filter(
				(e) => !e.field,
			);

			onGeneralErrors?.(mappedGeneralErrors);
			throw e;
		}
	};

	return { submitKycDataHandler };
};

export default useSubmitKycData;
