import { useEffect, useRef, useState } from 'react';
import {
	IRTKQuerySerializedError,
	IResponseError,
	IResponseErrors,
	IResponseErrorsArray,
	logger,
	useErrorsResponseTransform,
} from '@ninja/utilities';
import { ApiAuthorizePaymentRequest } from '@visit-visa/api-visit-visa';
import { setPaymentMethod } from '../../redux-store/quota-purchase.slice';
import {
	useLazyGetPaymentLinkDetailsQuery,
	usePostAuthorizePaymentLinkMutation,
	usePostCapturePaymentLinkMutation,
} from '../../redux-store/rtkq-slice/endpoints';
import { useAppDispatch } from '../../redux-store/store';
import { usePaymentPublicKey } from '../AppInitData/usePaymentPublicKey';
import { useRequestedPolicyStatuses } from '../AppInitData/useRequestedPolicyStatuses';
import useCurrencyAndVat from '../useCurrencyAndVat';

export type usePaymentLinkProps = {
	encryptedRequestedPolicyId: string;
	onApiErrors: (errors: string | undefined) => void;
	onPaymentSuccess: () => void;
	onPaymentExpired: () => void;
	onPaymentFailed: (errors: IResponseError[]) => void;
	onPaymentProcessing: (isPaymentProcessing: boolean) => void;
};

export const usePaymentLink = (props: usePaymentLinkProps) => {
	const { getErrorMessages } = useErrorsResponseTransform();
	const [
		getPaymentLinkDetails,
		{
			data: paymentLinkDetails = {
				baseTariffAmount: 0,
				VatConfigurationId: 0,
				paymentExpiresOn: '',
				requestedPolicyStatusId: 0,
			},
		},
	] = useLazyGetPaymentLinkDetailsQuery();
	const {
		onApiErrors,
		encryptedRequestedPolicyId,
		onPaymentSuccess,
		onPaymentExpired,
		onPaymentFailed,
		onPaymentProcessing,
	} = props;
	const appDispatch = useAppDispatch();
	const { paymentPublicKey } = usePaymentPublicKey();
	const [postAuthorizePayment] = usePostAuthorizePaymentLinkMutation();
	const [postCapturePayment] = usePostCapturePaymentLinkMutation();
	const paymentIdRef = useRef('');
	const { getCurrencyAndVat } = useCurrencyAndVat();
	const currencyAndVat = getCurrencyAndVat();
	const AED = 'AED';
	const vatAmount =
		paymentLinkDetails?.baseTariffAmount * ((currencyAndVat?.rate ?? 0) / 100);
	const { getIsPolicyIssuedOrExpired } = useRequestedPolicyStatuses();
	const [isPageLoading, setIsPageLoading] = useState(true);

	useEffect(() => {
		const getPaymentLinkDetailsInfo = async () => {
			setIsPageLoading(true);
			try {
				const { requestedPolicyStatusId } = await getPaymentLinkDetails(
					encryptedRequestedPolicyId,
				).unwrap();
				if (getIsPolicyIssuedOrExpired(requestedPolicyStatusId)) {
					onPaymentExpired();
				}
				setIsPageLoading(false);
			} catch (error) {
				const errors = error as IResponseErrors;
				setIsPageLoading(false);
				onApiErrors(errors.status);
			}
		};

		getPaymentLinkDetailsInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getIsPolicyIssuedOrExpired, encryptedRequestedPolicyId]);

	const authorizePayment = async (cardToken: string) => {
		onPaymentProcessing(true);
		const authorizePaymentData: ApiAuthorizePaymentRequest = {
			journeyId: encryptedRequestedPolicyId,
			cardToken: cardToken,
			paymentProviderId: 1,
			payment: {
				amountWithoutVat: paymentLinkDetails?.baseTariffAmount,
				vatAmount: vatAmount,
				currency: currencyAndVat?.currencyName ?? AED,
			},
		};
		try {
			const { paymentId, url3Ds } = await postAuthorizePayment(
				authorizePaymentData,
			).unwrap();
			paymentIdRef.current = paymentId;
			return url3Ds;
		} catch (error) {
			const errors = getErrorMessages(
				error as
					| IResponseErrors
					| IResponseErrorsArray
					| IRTKQuerySerializedError,
			);
			onPaymentFailed(errors);
			onPaymentProcessing(false);
		}
	};

	const capturePayment = async (cardType: string | undefined) => {
		const capturePaymentRequest = {
			journeyId: encryptedRequestedPolicyId,
			paymentId: paymentIdRef.current,
		};

		logger.log('[usePaymentOptions][capturePayment]', capturePaymentRequest);

		try {
			onPaymentProcessing(true);
			await postCapturePayment(capturePaymentRequest).unwrap();
			switch (cardType?.toLowerCase()) {
				case 'credit':
					appDispatch(setPaymentMethod('Credit'));
					break;
				case 'debit':
					appDispatch(setPaymentMethod('Debit'));
					break;
				default:
					appDispatch(setPaymentMethod('Debit_OR_Credit'));
			}
			onPaymentProcessing(false);

			onPaymentSuccess();
		} catch (error) {
			const errors = getErrorMessages(
				error as
					| IResponseErrors
					| IResponseErrorsArray
					| IRTKQuerySerializedError,
			);
			onPaymentFailed(errors);
			onPaymentProcessing(false);
		}
	};

	return {
		paymentLinkDetails,
		paymentPublicKey,
		capturePayment,
		authorizePayment,
		amountWithoutVat: paymentLinkDetails?.baseTariffAmount,
		vatAmount,
		currency: currencyAndVat?.currencyName,
		currencyAndVat,
		isPageLoading,
	};
};
